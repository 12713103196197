import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NoData } from '@shared_medialab/ui_components';

import { StyledNotFound } from './styled';

const NotFound: FC = () => {
  // translations
  const { t } = useTranslation('not_found');

  return (
    <StyledNotFound>
      <NoData
        transparent
        size="large"
        image="Expired"
        text={t('not_found')}
        subText={t('not_found_subText')}
        textProps={{ level: 4, color: 'var(--primary-6)' }}
        subTextProps={{ level: 11, color: 'var(--black)' }}
      />
    </StyledNotFound>
  );
};

export default NotFound;
