import { initialState } from '.';
import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_FILTER_ITEM: {
      const { module, name, data } = action.data;

      return { ...state, [module]: { ...state[module], [name]: data } };
    }

    case ActionTypes.RESET_FILTERS: {
      return {
        ...state,
        [action.data]: { ...initialState[action.data] }
      };
    }

    default:
      return state;
  }
};

export default reducer;
