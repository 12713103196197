import styled from 'styled-components';

export const StyledExport = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  gap: 10px;
  background: var(--primary-bg-3);
  border: 1px solid;
  border-color: var(--primary-bg-5);
  border-radius: 6px;
  transition: all 0.2s;
  color: var(--primary-2);
  svg {
    path {
      transition: all 0.2s;
    }
  }
  &:not(:disabled):hover {
    background-color: var(--primary-1);
    border-color: var(--primary-1);
    color: var(--white);
    svg {
      path {
        fill: var(--white);
      }
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;
