import {
  useCallback,
  createContext,
  useContext,
  FC,
  useReducer,
  useEffect
} from 'react';
import { ContentPackages_contentPackages_data_results as ContentPackagesType } from 'gql/contentPackages/types/ContentPackages';
import { useUIDispatchContext } from 'providers/UI';
import { SidebarKeys } from 'providers/UI/types';

import reducer from './reducers';
import {
  ActionTypes,
  Dispatch,
  DispatchContext,
  IChoosePackageProps,
  PackageSports,
  State
} from './types';

const initialState: State = {
  selectedPackages: [],
  selectedPackagesSports: {},
  sportIds: []
};

const ChoosePackageStateContext = createContext<State>(initialState);
const ChoosePackageDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const ChoosePackageProvider: FC<IChoosePackageProps> = ({ children }) => {
  // context
  const { toggleSidebar } = useUIDispatchContext();

  // state
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  useEffect(() => {
    toggleSidebar(
      SidebarKeys.selectedPackages,
      !!state.selectedPackages.length
    );
  }, [state.selectedPackages, toggleSidebar]);

  return (
    <ChoosePackageDispatchContext.Provider value={dispatch}>
      <ChoosePackageStateContext.Provider value={state}>
        {children}
      </ChoosePackageStateContext.Provider>
    </ChoosePackageDispatchContext.Provider>
  );
};

const useChoosePackageStateContext = (): State => {
  const context = useContext(ChoosePackageStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useChoosePackageStateContext must be used within a ChoosePackageStateContext'
    );
  }

  return context;
};

const useChoosePackageDispatchContext = (): DispatchContext => {
  const dispatch = useContext(ChoosePackageDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useChoosePackageDispatchContext must be used within a useChoosePackageDispatchContext'
    );
  }

  const choosePackages = useCallback(
    (data: ContentPackagesType) => {
      dispatch({ type: ActionTypes.CHOOSE_PACKAGES, data });
    },
    [dispatch]
  );

  const choosePackagesSports = useCallback(
    (data: PackageSports, sportIds: string[]) => {
      dispatch({
        type: ActionTypes.CHOOSE_PACKAGES_SPORTS,
        data: { values: data, sportIds }
      });
    },
    [dispatch]
  );

  return { choosePackages, choosePackagesSports };
};

export default ChoosePackageProvider;
export { useChoosePackageStateContext, useChoosePackageDispatchContext };
