import { FC, memo, useMemo } from 'react';
import { Button, Input } from '@shared_medialab/ui_components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectEventsPresetFiltersError,
  selectEventsPresetFiltersNameInput,
  selectIsPresetChanged,
  selectSelectedPresetName,
  selectShowSavePreset
} from 'store/slice/filters/events/selector';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setEventsFilterPresetNameInput } from 'store/slice/filters';

import { ErrorMessage, StyledSavePreset } from './styled';
import { ISavePresetProps } from './types';
import { RouteParams } from '../Content/types';
import useEventsFilters from 'hooks/useEventsFilter';

const SavePreset: FC<ISavePresetProps> = ({ changeToPresets }) => {
  // navigation
  const { type } = useParams() as RouteParams;
  // hooks
  const { loading, presetFilters, onSaveFilters } = useEventsFilters();

  // redux
  const dispatch = useAppDispatch();
  const selectedPresetName = useAppSelector(
    selectSelectedPresetName(type),
    shallowEqual
  );

  const showSavePreset = useAppSelector(selectShowSavePreset(type));
  const isPresetChanged = useAppSelector(
    selectIsPresetChanged(type, presetFilters[selectedPresetName]),
    shallowEqual
  );

  const name = useAppSelector(selectEventsPresetFiltersNameInput(type));
  const error = useAppSelector(selectEventsPresetFiltersError(type));

  const isSaveButtonDisabled = useMemo(() => {
    if (!selectedPresetName) {
      return !showSavePreset;
    }

    return !isPresetChanged || !showSavePreset;
  }, [isPresetChanged, selectedPresetName, showSavePreset]);

  return (
    <>
      <StyledSavePreset>
        <Input
          label="Save filters as preset"
          disabled={!showSavePreset}
          cornerRadius="smooth"
          value={name}
          onChange={e => {
            dispatch(
              setEventsFilterPresetNameInput({ type, value: e.target.value })
            );
          }}
          className={error.value ? 'preset-input-error' : ''}
          placeholder="Name the preset*"
        />
        <Button
          color={selectedPresetName ? 'primaryNew' : 'confirmNew'}
          disabled={isSaveButtonDisabled}
          cornerRadius="smooth"
          flexibility="default"
          loading={loading}
          onClick={() =>
            onSaveFilters(() => {
              changeToPresets();
            })
          }
        >
          {selectedPresetName ? 'Save changes' : 'Save'}
        </Button>
      </StyledSavePreset>
      <ErrorMessage>{error.message}</ErrorMessage>
    </>
  );
};

export default memo(SavePreset);
