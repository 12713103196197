import { utils } from '@shared_medialab/ui_components';
import styled from 'styled-components';

// TODO: use from ui
export const PopupWrapper = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(8, 11, 27, 0.85);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPopup = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  width: 370px;
`;

export const StyledHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    color: rgba(0, 0, 0, 0.85);
    font-size: ${utils.rem(16)};
    font-weight: 400;
  }
  button {
    cursor: pointer;
  }
`;

export const StyledContent = styled.div`
  padding: 20px;
  border-top: 1px solid var(--primary-bg-2);
  border-bottom: 1px solid var(--primary-bg-2);
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledFooter = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
`;
