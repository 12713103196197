import { FC, memo } from 'react';
import { Col, Loading, Row } from '@shared_medialab/ui_components';
import { BuyerSessionsReports_buyerSessionsReports_data_results as OverviewType } from 'gql/reports/types/BuyerSessionsReports';

import { IPartnersInfoProps } from './types';
import { StyledPartnersInfo } from './styled';
import PartnersInfoItem from './PartnersInfoItem';

const PartnersInfo: FC<IPartnersInfoProps> = ({ type, data }) => {
  if (!data) {
    return <Loading />;
  }

  return (
    <StyledPartnersInfo>
      <Row gutter={[8, 8]} wrap={true} justify="space-between" align="center">
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text:
                type === 'partners-info'
                  ? 'Total Operators Trial (Wallet/Invoicing)'
                  : 'Unique views'
            }}
            value={+(data as OverviewType).total_unique_views?.toFixed(2) || 0}
          />
        </Col>
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text: 'Total sessions'
            }}
            value={+(data as OverviewType).total_sessions?.toFixed(0) || 0}
          />
        </Col>
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text: 'Total views duration'
            }}
            value={
              (data as OverviewType).total_views_duration?.formattedValue || 0
            }
          />
        </Col>
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text:
                type === 'partners-info'
                  ? 'Total Operators Live (Wallet/Invoicing)'
                  : 'Average view duration'
            }}
            value={
              (data as OverviewType).average_view_duration?.formattedValue || 0
            }
          />
        </Col>
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text:
                type === 'partners-info'
                  ? 'Total Operators Suspended (Wallet/Invoicing)'
                  : 'Total traffic consumed'
            }}
            value={
              (data as OverviewType).total_views_bandwidth_consumed
                ?.formattedValue || 0
            }
          />
        </Col>
        <Col span={2}>
          <PartnersInfoItem
            type={type}
            title={{
              text:
                type === 'partners-info'
                  ? 'Total Operators (Wallet/Invoicing)'
                  : 'Average traffic consumed'
            }}
            value={
              (data as OverviewType).average_view_bandwidth_consumed
                ?.formattedValue || 0
            }
          />
        </Col>
      </Row>
    </StyledPartnersInfo>
  );
};

export default memo(PartnersInfo);
