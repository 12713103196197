import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GET_BOOKED_EVENTS,
  GET_HISTORY_EVENTS,
  GET_BUYER_UPCOMING_EVENTS
} from 'gql/events/queries';
import { BookedMatches } from 'gql/events/types/BookedMatches';
import { MatchTypesEnum } from 'constants/match';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectEventsLoading,
  selectEventsPagination,
  selectFormattedEventsFilters
} from 'store/slice/filters/events/selector';
import { shallowEqual } from 'react-redux';
import { EVENTS_PAGE_SIZE } from 'constants/events';
import {
  setEventsTotal,
  setEventsLoading,
  setIsPlayDisabled
} from 'store/slice/filters';
import { HistoryMatchesBuyer } from 'gql/events/types/HistoryMatchesBuyer';
import { useProjectStateContext } from 'providers/Projects';

import { RouteParams } from './components/Content/types';
import MatchSkeleton from './components/MatchSkeleton';

const Events: FC = () => {
  // navigation
  const { type } = useParams() as RouteParams;
  // redux
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectEventsLoading(type));
  const { page } = useAppSelector(selectEventsPagination(type));
  const { selectedProject } = useProjectStateContext();

  const upcomingFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.list),
    shallowEqual
  );

  const bookedFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.booked),
    shallowEqual
  );

  const historyFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.history),
    shallowEqual
  );

  //graphql
  useQuery(GET_BUYER_UPCOMING_EVENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        pagination: {
          page,
          limit: EVENTS_PAGE_SIZE
        },
        ...upcomingFilters
      },
      organizationId: selectedProject?.id,
      partnerId: selectedProject?.partnerId
    },
    skip: type !== MatchTypesEnum.list,
    onCompleted(data) {
      dispatch(setIsPlayDisabled({ type, value: false }));
      const total = data.buyerListMatches?.data.total || 0;
      dispatch(setEventsTotal({ type, value: total }));

      if (!total) {
        dispatch(setEventsLoading({ type, value: false }));
      }
    }
  });

  useQuery<BookedMatches>(GET_BOOKED_EVENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        organizationId: selectedProject?.id,
        pagination: {
          page,
          limit: EVENTS_PAGE_SIZE
        },
        ...bookedFilters
      },
      partnerId: selectedProject?.partnerId
    },
    skip: type !== MatchTypesEnum.booked,
    onCompleted(data) {
      const total = data.bookedMatches?.data.total || 0;
      dispatch(setIsPlayDisabled({ type, value: false }));
      dispatch(setEventsTotal({ type, value: total }));

      if (!total) {
        dispatch(setEventsLoading({ type, value: false }));
      }
    }
  });

  useQuery<HistoryMatchesBuyer>(GET_HISTORY_EVENTS, {
    fetchPolicy: 'cache-and-network',
    skip: type !== MatchTypesEnum.history,
    variables: {
      query: {
        pagination: {
          page,
          limit: EVENTS_PAGE_SIZE
        },
        ...historyFilters
      },
      organizationId: selectedProject?.id
    },
    onCompleted(data) {
      const total = data.historyMatchesBuyer?.data.total || 0;
      dispatch(setEventsTotal({ type, value: total }));

      if (!total) {
        dispatch(setEventsLoading({ type, value: false }));
      }
    }
  });

  if (loading) {
    return <MatchSkeleton />;
  }

  return <Outlet />;
};

export default Events;
