import { StyledTooltip } from '../styled';

export const getPath = (
  x: number,
  y: number,
  width: number,
  height: number
) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  } 
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
    Z`;
};

export const colorCount = (
  count: number,
  name: string | number,
  constColors: Record<string, string>
) => {
  if (count) {
    return constColors[name as string];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTooltip = ({ active, payload, type, typeItem }: any) => {
  const text = type === 'line' ? 'Unique Views -' : '-';

  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <p>{`${
          payload[0].payload[typeItem] || payload[0].payload.streamName || ''
        } ${text}  ${payload[0].value}`}</p>
      </StyledTooltip>
    );
  }

  return null;
};

export const hexToRGB = (hex: string | undefined, alpha: string) => {
  const r = hex && parseInt(hex.slice(1, 3), 16);
  const g = hex && parseInt(hex.slice(3, 5), 16);
  const b = hex && parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const popup = (
  data: { name: string; value: number }[],
  name: string
) => {
  const counts = data.filter((elem: { name: string; value: number }) => {
    return elem.name === name;
  });

  return counts.length === 0 ? 0 : counts[0].value;
};
