import { gql } from '@apollo/client';

export const GET_USER_DATA = gql`
  query UserMe($groupName: String!, $organizationId: String) {
    me(groupName: $groupName, organizationId: $organizationId) {
      data {
        id
        firstName
        lastName
        phone
        email
        settings
        status
        role {
          id
          name
          permissions {
            id
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_USER_ROLE_BY_ORGANIZATION = gql`
  query UserRole($query: GetUserRolesInput!) {
    userRole(query: $query) {
      data {
        id
        name
        permissions {
          id
          name
          value
        }
      }
    }
  }
`;
