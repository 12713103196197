import dayjs from 'dayjs';
import {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
  useEffect
  // useEffect
} from 'react';

import { getStoredFilter, storeFilter } from 'utils/filters';
import reducer from './reducers';
import {
  State,
  Dispatch,
  DispatchContext,
  ActionTypes,
  FilterModules,
  IFiltersProviderProps,
  FilterData
} from './types';

export const initialState: State = {
  [FilterModules.events_list]: {
    name: '',
    // auto_booking: [],
    dates: {
      startDate: new Date(),
      endDate: dayjs().add(1, 'months').toDate()
    },
    sportIds: [],
    regionIds: [],
    leagueIds: [],
    status: []
    // booking_type: ''
  }
};

const setLocalStorage = storeFilter();

// states
const FiltersStateContext = createContext<State | undefined>(undefined);

// dispatches
const FiltersDispatchContext = createContext<Dispatch | undefined>(undefined);

const FiltersProvider: FC<IFiltersProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...getStoredFilter(),
    ...initialState
  });

  useEffect(() => {
    setLocalStorage(state);
  }, [state]);

  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
};

const useFiltersStateContext = (): State => {
  const context = useContext(FiltersStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'FiltersStateContext must be used within a FiltersStateContext'
    );
  }

  return context;
};

const useFiltersDispatchContext = (): DispatchContext => {
  const dispatch = useContext(FiltersDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'FiltersDispatchContext must be used within a FiltersDispatchContext'
    );
  }

  const setFilterItem = useCallback(
    (data: FilterData) => {
      dispatch({ type: ActionTypes.SET_FILTER_ITEM, data });
    },
    [dispatch]
  );

  const resetFilters = useCallback(
    (name: FilterModules) => {
      dispatch({ type: ActionTypes.RESET_FILTERS, data: name });
    },
    [dispatch]
  );

  return {
    setFilterItem,
    resetFilters
  };
};

export default FiltersProvider;
export { useFiltersDispatchContext, useFiltersStateContext };
