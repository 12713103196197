import { useQuery } from '@apollo/client';
import { GET_ALL_REGIONS_BY_ATTACHED_LEAGUES } from 'gql/regions/queries';
import { AllRegionsByAttachedLeagues } from 'gql/regions/types/AllRegionsByAttachedLeagues';
import { useState, useMemo } from 'react';

import usePreviousNonNullish from './usePreviousNonNullish';

type Params = {
  sportIds?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRegions = ({ sportIds }: Params) => {
  // refs
  // const timer = useRef<NodeJS.Timeout | null>(null);
  // states
  const [regionSearch, setRegionSearch] = useState('');
  // graphql
  const { data: regionsData } = useQuery<AllRegionsByAttachedLeagues>(
    GET_ALL_REGIONS_BY_ATTACHED_LEAGUES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          name: regionSearch
        }
      },
      context: {
        debounceKey: 'regions',
        debounceTimeout: 700
      }
    }
  );

  const data = usePreviousNonNullish(regionsData);

  const regions = useMemo(
    () => data?.allRegionsByAttachedLeagues?.data || [],
    [data]
  );

  return {
    regions,
    regionSearch,
    onRegionSearch: setRegionSearch
  };
};

export default useRegions;
