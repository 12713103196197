import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CHOOSE_PACKAGES: {
      const isIncluding = state.selectedPackages.find(
        item => item.id === action.data.id
      );

      if (isIncluding) {
        return {
          ...state,
          selectedPackages: state.selectedPackages.filter(
            item => item.id !== action.data.id
          )
        };
      }

      return {
        ...state,
        selectedPackages: [...state.selectedPackages, action.data]
      };
    }

    case ActionTypes.CHOOSE_PACKAGES_SPORTS: {
      return {
        ...state,
        selectedPackagesSports: action.data.values,
        sportIds: action.data.sportIds
      };
    }

    default:
      return state;
  }
};

export default reducer;
