import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { PackagesSidebar } from 'pages/AutoBooking/components';

const AsideRight: FC = () => {
  // location
  const { pathname } = useLocation();

  switch (pathname) {
    case '/streaming/events/autobooking':
      return <PackagesSidebar />;
    default:
      return null;
  }
};

export default AsideRight;
