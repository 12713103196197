import { rgba } from 'polished';
import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

import { ICountProps, ITitleProps } from './types';

export const StyledPartnersInfoItem = styled.button`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  ${({ theme }) => css`
    border: 1px solid ${rgba(theme.colors.gray[1], 0.1)};
    border-radius: ${theme.sizes.xxs};
    background-color: ${rgba(theme.colors.white, 0.1)};
  `};
`;
export const StyledTitle = styled.div<ITitleProps>`
  ${({ theme }) => css`
    font-size: ${theme.sizes.sm};
    font-weight: lighter;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  `}
`;

export const StyledCount = styled.div<ICountProps>`
  ${({ theme }) => css`
    color: ${rgba(theme.colors.gray[1], 0.6)};
    padding-top: ${theme.sizes.xs};
    font-weight: lighter;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  `}
  &:before {
    content: '';
    width: 7px;
    height: 7px;
    margin-right: 5px;
    background-color: red;
    border-radius: 50%;
  }
`;
