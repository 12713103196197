import { Card, Row, Col } from '@shared_medialab/ui_components';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserFormStateContext } from 'providers/UserForm';
import { useProjectStateContext } from 'providers/Projects';
import { useModalStateContext } from 'providers/UI';

import { StyledEditCardContent } from './styled';
import Status from '../Status';
import RolesSelect from '../RolesSelect';

const ProjectCard: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { control } = useUserFormStateContext();
  const { selectedProject } = useProjectStateContext();
  const { userDetails } = useModalStateContext();

  const id = userDetails.params?.userId;

  return (
    <div className="pb--8">
      <Card
        type="primary"
        header={{
          title: selectedProject?.name || ''
        }}
      >
        <Row gutter={[1, 1]} wrap>
          <Col span={12}>
            <StyledEditCardContent
              gutter={[5, 5]}
              className="p--8 flex-display flex-align-items-center"
            >
              <Col span={id ? 6 : 12}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RolesSelect onChange={onChange} value={value || ''} />
                  )}
                  name="project.role"
                  rules={{
                    required: {
                      value: true,
                      message: t('messages:field_required')
                    }
                  }}
                  control={control}
                />
              </Col>
              {!!id && (
                <Col span={6}>
                  <Status />
                </Col>
              )}
            </StyledEditCardContent>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProjectCard;
