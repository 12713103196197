import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as permissionConstants from 'constants/permissions';
import { usePermission, useStatusState } from 'hooks';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';
import { MatchTypesEnum } from 'constants/match';
import { Loading, PageContainer } from '@shared_medialab/ui_components';
import { useProjectStateContext } from 'providers/Projects';

import { RouteParams } from './components/Content/types';
import Private from 'components/shared/Private';
import Events from '.';
import AutoBooking from 'pages/AutoBooking';
import useEventsHistoryCache from 'hooks/useEventsHistoryCache';
import useEventsBookedCache from 'hooks/useEventsBookedCache';
import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';
import RightActions from './components/RightActions';
import { DEFAULT_PATH, ROUTES } from './constants';

const EventsWrapper: FC = () => {
  // navigation
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { type } = useParams() as RouteParams;

  //redux
  const { checkUserPermission } = usePermission();

  // context
  const { selectedProject } = useProjectStateContext();

  const isTabsEnabled = useStatusState({
    product: [
      ProductStatuses.active,
      ProductStatuses.pending,
      ProductStatuses.integration,
      ProductStatuses.trial,
      ProductStatuses.inactive
    ]
  });

  const onlyList = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  const reportAndHistory = useStatusState({
    product: [ProductStatuses.inactive],
    bme: [
      BmeStatuses.live,
      BmeStatuses.trial,
      BmeStatuses.terminated,
      BmeStatuses.lost,
      BmeStatuses.in_active
    ],
    project: [ProjectStatuses.terminated]
  });

  const expired = useStatusState({
    product: [ProductStatuses.expired]
  });

  const nothingIsAvailable =
    useStatusState({
      product: [ProductStatuses.trial, ProductStatuses.expired],
      bme: [BmeStatuses.live],
      project: [ProjectStatuses.live]
    }) && expired;

  const listTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.list,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.list,
        action() {
          navigate(ROUTES.list);
        },
        name: MatchTypesEnum.list,
        disabled: !isTabsEnabled || reportAndHistory
      };
    }
  }, [checkUserPermission, isTabsEnabled, navigate, reportAndHistory]);

  const bookedTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.booked,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: '/streaming/events/booked',
        action() {
          navigate('/streaming/events/booked');
        },
        name: MatchTypesEnum.booked,
        disabled: !isTabsEnabled || onlyList || reportAndHistory
      };
    }
  }, [
    checkUserPermission,
    isTabsEnabled,
    navigate,
    onlyList,
    reportAndHistory
  ]);

  const historyTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.history,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.history,
        name: 'history',
        action: () => {
          navigate(ROUTES.history);
        },
        disabled: !isTabsEnabled || onlyList
      };
    }
  }, [checkUserPermission, isTabsEnabled, navigate, onlyList]);

  const autobookingTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.autoBooking,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.autobooking,
        action() {
          navigate(ROUTES.autobooking);
        },
        name: 'Autobooking',
        disabled: !isTabsEnabled || onlyList || reportAndHistory
      };
    }
  }, [
    checkUserPermission,
    isTabsEnabled,
    navigate,
    onlyList,
    reportAndHistory
  ]);

  const tabs = useMemo(() => {
    const values = [];

    if (listTab) {
      values.push(listTab);
    }

    if (bookedTab) {
      values.push(bookedTab);
    }

    if (historyTab) {
      values.push(historyTab);
    }

    if (autobookingTab) {
      values.push(autobookingTab);
    }

    return values;
  }, [listTab, bookedTab, historyTab, autobookingTab]);

  useEffect(() => {
    if (pathname === DEFAULT_PATH && tabs[0]?.id) {
      navigate(tabs[0].id);
    }
  }, [navigate, pathname, tabs]);

  useEffect(() => {
    if (reportAndHistory) {
      navigate(permissionConstants.BUYER_ROUTES_PATHS.history);
    }

    if (onlyList) {
      navigate(permissionConstants.BUYER_ROUTES_PATHS.list);
    }

    if (nothingIsAvailable) {
      navigate('/streaming/notFound');
    }
  }, [
    navigate,
    nothingIsAvailable,
    onlyList,
    pathname,
    reportAndHistory,
    selectedProject?.expiredAt,
    tabs
  ]);

  // graphql
  const { onScroll: upcoming, isFetching: isFetchingUpcoming } =
    useEventsUpcomingCache();

  const { onScroll: booked, isFetching: isFetchingBooked } =
    useEventsBookedCache();

  const { onScroll: history, isFetching: isFetchingHistory } =
    useEventsHistoryCache();

  const onScroll = useCallback(() => {
    switch (pathname) {
      case ROUTES.list:
        return upcoming;
      case ROUTES.booked:
        return booked;
      case ROUTES.history:
        return history;
    }
  }, [booked, history, pathname, upcoming]);

  if (!tabs.length || !type) {
    return null;
  }

  return (
    <PageContainer
      tabs={tabs}
      activeTab={location.pathname}
      bodyStyle={{ paddingRight: '10px' }}
      rightAction={<RightActions />}
      onScroll={onScroll()}
    >
      <Private
        routeId={
          permissionConstants.BUYER_ROUTES_IDS[
            pathname === ROUTES.autobooking ? 'autoBooking' : type
          ]
        }
        routeKey={permissionConstants.VIEW}
      >
        {pathname === ROUTES.autobooking ? (
          <AutoBooking />
        ) : (
          <>
            <Events />
            {(isFetchingUpcoming || isFetchingBooked || isFetchingHistory) && (
              <div className="absolute-position text-center absolute-center">
                <Loading />
              </div>
            )}
          </>
        )}
      </Private>
    </PageContainer>
  );
};

export default EventsWrapper;
