import { useMutation } from '@apollo/client';
import { IconNew, Tooltip } from '@shared_medialab/ui_components';
import { RESEND_EMAIL } from 'gql/users/mutations';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ResendEmailButton: FC<{
  userId: unknown;
}> = ({ userId }) => {
  // gql
  const [resendEmail] = useMutation(RESEND_EMAIL);
  // states
  const [disableButton, setDisableButton] = useState(false);
  //translation
  const { t } = useTranslation('users');

  useEffect(() => {
    const disabledUntil = localStorage.getItem(`disabledUntil_${userId}`);

    if (disabledUntil) {
      const disabledUntilTime = new Date(disabledUntil).getTime();
      const now = new Date().getTime();

      if (now < disabledUntilTime) {
        setDisableButton(true);

        setTimeout(() => {
          setDisableButton(false);
          localStorage.removeItem(`disabledUntil_${userId}`);
        }, disabledUntilTime - now);
      }
    }
  }, [userId]);

  return (
    <Tooltip
      title={disableButton ? t('email_sent') : t('email_resend')}
      styles={{ position: 'bottomLeft' }}
    >
      <IconNew
        className={`${
          disableButton ? 'events-pointer-none' : 'pointer-cursor'
        }`}
        color="var(--primaryNew)"
        type={`${disableButton ? 'email_-read' : 'resend_email'}`}
        size={20}
        onClick={() => {
          resendEmail({
            variables: {
              input: {
                userId
              }
            }
          });

          if (userId) {
            setDisableButton(true);

            const disabledUntil = new Date(new Date().getTime() + 300000);

            localStorage.setItem(
              `disabledUntil_${userId}`,
              disabledUntil.toString()
            );

            setTimeout(() => {
              setDisableButton(false);
              localStorage.removeItem(`disabledUntil_${userId}`);
            }, 300000);
          }
        }}
      />
    </Tooltip>
  );
};

export default ResendEmailButton;
