import styled, { css } from 'styled-components';

import { IContentProps, IRotateProps } from './type';

const SUBMENU_ITEM_HEIGHT = 38;

export const StyledContent = styled.div<IContentProps>`
  display: block;
  background-color: var(--Background__5);
  width: 100%;
  transform-origin: top left;
  transition: all 0.3s ease;
  max-height: ${SUBMENU_ITEM_HEIGHT * 4}px;
  overflow-y: auto;
  ${({ isOpened, itemsCount }) => css`
    transform: ${isOpened ? 'scaleY(1)' : 'scaleY(0)'};
    opacity: ${isOpened ? 1 : 0};
    height: ${isOpened ? itemsCount * SUBMENU_ITEM_HEIGHT : 0}px;
  `}
`;

export const StyledHeader = styled.div`
  width: 100%;
  outline: none;
  display: flex;
  justify-content: space-between;
  background-color: var(--Background__7);
  align-items: center;
  &:hover {
    color: var(--vivid-cerulean);
    .icon {
      color: var(--vivid-cerulean);
    }
  }
  ${({ theme }) => {
    const { base } = theme.spaces;

    return css`
      padding: ${base} ${base};
    `;
  }}
`;
export const StyledButtonSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--Background__7);
  color: var(--text-color);
  border: none;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  ${({ theme }) => css`
    font-size: ${theme.sizes.base};
  `}
`;
export const StyledButton = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  background: var(--Background__8);
  color: var(--text-color);
  border: none;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  .icon {
    color: red;
  }
  ${({ theme }) => css`
    padding: ${theme.spaces.base} ${theme.spaces.xl};
    font-size: ${theme.sizes.base};
    justify-content: flex-start;
    transition: all 0.3s ease;
  `}
  &:hover {
    ${({ theme }) => css`
      color: ${theme.colors.blue[9]};
    `}
  }
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--Background__9);
  }
`;

export const StyledIconRotate = styled.div<IRotateProps>`
  padding: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: center center;
`;
