import { gql } from '@apollo/client';

import { AUTO_BOOKING_LIST_FRAGMENT } from './fragments';

export const UPDATE_AUTO_BOOKING_STATUS = gql`
  mutation AutoBookingFrozen($input: AutoBookingIsFrozen!, $id: ID) {
    autoBookingFrozen(input: $input, id: $id) {
      data {
        ...AutoBookingListFragment
      }
    }
  }
  ${AUTO_BOOKING_LIST_FRAGMENT}
`;
export const DELETE_AUTO_BOOKING_STATUS = gql`
  mutation DeleteAutoBookingRule($id: ID!) {
    deleteAutobooking(id: $id) {
      data {
        id
        name
      }
    }
  }
`;

export const CREATE_AUTO_BOOKING = gql`
  mutation CreateAutobooking($input: AutoBookingInput!) {
    createAutobooking(input: $input) {
      message
    }
  }
`;

export const UPDATE_AUTO_BOOKING = gql`
  mutation UpdateAutoBooking($input: AutoBookingInput!, $id: ID) {
    updateAutobooking(input: $input, id: $id) {
      data {
        id
      }
    }
  }
`;
