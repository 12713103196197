import { FC } from 'react';

import MatchesGroupTitle from '../MatchesGroupTitle';
import MatchItem from '../MatchItem';
import { IDateSectionProps } from './types';

const DateSection: FC<IDateSectionProps> = ({
  date,
  group,
  isSectionSelected,
  onClickBulkAction
}) => {
  return (
    <>
      <MatchesGroupTitle
        count={group.length}
        date={`${date}`}
        checked={isSectionSelected}
        action={() => onClickBulkAction(date)}
      />
      {group.map(item => (
        <MatchItem key={`match-item-${item.id}`} id={item.id} />
      ))}
    </>
  );
};

export default DateSection;
