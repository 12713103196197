import { useLazyQuery, useMutation } from '@apollo/client';
import client from 'apolloClient';
import { GET_USER_PROJECTS } from 'gql/organizations/queries';
import {
  useCallback,
  createContext,
  useContext,
  FC,
  useReducer,
  useEffect,
  useState
} from 'react';
import config from 'config';
import { toast } from 'react-toastify';
import { DELETE_CART_BY_ORGANIZATION } from 'gql/cart/mutations';
import { UserProjects } from 'gql/organizations/types/UserProjects';
import { USER_PROJECT_FRAGMENT } from 'gql/organizations/fragments';

import reducer from './reducers';
import {
  ActionTypes,
  Dispatch,
  DispatchContext,
  IProjectProps,
  ProjectType,
  State
} from './types';
import { parseJson } from 'utils/object';

const initialState: State = {
  selectedProject: null
};

const ProjectStateContext = createContext<State | undefined>(undefined);
const ProjectDispatchContext = createContext<Dispatch | undefined>(undefined);

const ProjectProvider: FC<IProjectProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const [token, setToken] = useState('');

  const queryToken =
    new URLSearchParams(window.location.search).get('token') || '';

  const queryProject =
    new URLSearchParams(window.location.search).get('project') || '';

  const storageToken = localStorage.getItem('token') || '';

  const dataGlobal = client.readQuery<UserProjects>({
    query: GET_USER_PROJECTS
  });

  const data = dataGlobal?.userProjects?.data.buyer?.filter(
    el => el.id == state.selectedProject?.id
  )[0];

  useEffect(() => {
    if (data && data?.status !== state.selectedProject?.status) {
      localStorage.removeItem('projectId');
      localStorage.setItem('selectedProject', JSON.stringify(data));

      dispatch({
        type: ActionTypes.SELECT_PROJECT,
        data: data as ProjectType
      });
    }
  }, [data, state]);

  useEffect(() => {
    const actualToken = queryToken || storageToken;

    if (actualToken) {
      localStorage.setItem('token', actualToken);

      setToken(actualToken);
    }
  }, [queryToken, storageToken]);

  useEffect(() => {
    if (queryProject) {
      localStorage.setItem('projectId', queryProject);
    }
  }, [queryProject]);

  const [deleteCartByOrganizationId] = useMutation(DELETE_CART_BY_ORGANIZATION);

  const getOrganization = useCallback(
    (project: any[]) => {
      const storedProject = localStorage.getItem('selectedProject');
      const parsedProject = storedProject ? JSON.parse(storedProject) : null;

      const selectedProject =
        parsedProject !== null ? parsedProject : state.selectedProject;

      if (
        project.length === 1 &&
        localStorage.getItem('projectId') !== project[0].id
      ) {
        localStorage.setItem('projectId', project[0].id);
      }

      const storageProjectId = localStorage.getItem('projectId');

      return storageProjectId
        ? project?.find(
            (item: { id: { toString: () => string } }) =>
              item.id.toString() === storageProjectId.toString()
          )
        : selectedProject ?? (project[0] as ProjectType);
    },
    [state.selectedProject]
  );

  const [getUserProjects] = useLazyQuery<UserProjects>(GET_USER_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    async onCompleted(res) {
      const admin = res.userProjects?.data.admin;
      const buyer = res.userProjects?.data.buyer;

      const project = buyer ?? [];

      const selectedOrganization = getOrganization(project);

      if (!buyer?.length && admin?.length) {
        try {
          const response = await fetch(
            `${config.gateway_url}/users/url?role=admin`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }
          );

          if (!response.ok) {
            const error = await response.text();
            throw new Error(
              parseJson(error)?.message ||
                'Something went wrong, lease contact support'
            );
          }

          const result = await response.json();

          if (!result?.data?.url) {
            throw new Error('Something went wrong, lease contact support');
          }

          const token = localStorage.getItem('token');

          deleteCartByOrganizationId({
            fetchPolicy: 'network-only',
            variables: {
              organizationId: selectedOrganization?.id ?? 'all'
            },
            onCompleted() {
              window.location.href = `${result.data.url}?token=${token}`;
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          toast.error(err.message);
        }
      }

      dispatch({
        type: ActionTypes.SELECT_PROJECT,
        data: selectedOrganization
      });
    }
  });

  useEffect(() => {
    if (token) {
      getUserProjects({
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
    }
  }, [getUserProjects, token]);

  return (
    <ProjectDispatchContext.Provider value={dispatch}>
      <ProjectStateContext.Provider value={state}>
        {children}
      </ProjectStateContext.Provider>
    </ProjectDispatchContext.Provider>
  );
};

const useProjectStateContext = (): State => {
  const context = useContext(ProjectStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useProjectStateContext must be used within a ProjectStateContext'
    );
  }

  return context;
};

const useProjectDispatchContext = (): DispatchContext => {
  const dispatch = useContext(ProjectDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useProjectDispatchContext must be used within a useProjectDispatchContext'
    );
  }

  const selectProject = useCallback(
    (id: string) => {
      const data = client.readFragment({
        id: `UserProject:${id}`,
        fragment: USER_PROJECT_FRAGMENT,
        fragmentName: 'UserProjectFragment'
      });

      if (data) {
        localStorage.removeItem('projectId');
        localStorage.setItem('selectedProject', JSON.stringify(data));

        dispatch({
          type: ActionTypes.SELECT_PROJECT,
          data: data as ProjectType
        });
      }
    },
    [dispatch]
  );

  return { selectProject };
};

export default ProjectProvider;
export { useProjectStateContext, useProjectDispatchContext };
