import { FC, memo } from 'react';

import { IReportingListItemProps } from './types';
import { StyledPartnersInfoItem, StyledCount, StyledTitle } from './styled';

const PartnersInfoItem: FC<IReportingListItemProps> = ({
  title,
  value,
  type
}) => {
  return (
    <StyledPartnersInfoItem>
      <StyledCount>{value}</StyledCount>
      <StyledTitle type={type}>{title.text}</StyledTitle>
    </StyledPartnersInfoItem>
  );
};

export default memo(PartnersInfoItem);
