import { ValueOf } from 'utils/types';

export const MATCH_STATUS_IDS = {
  not_live: 1,
  live: 2
} as const;

export enum MatchTypesEnum {
  list = 'list',
  booked = 'booked',
  history = 'history'
}

export const MATCH_STATUSES_VALUES = {
  not_started: 1,
  started: 2,
  finished: 3,
  canceled: 4
} as const;

export type MatchStatusValuesType = ValueOf<typeof MATCH_STATUSES_VALUES>;
