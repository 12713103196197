import { FC, useCallback } from 'react';
import {
  Button,
  Col,
  Popup,
  Row,
  SuccessToast
} from '@shared_medialab/ui_components';
import {
  PopupContent,
  PopupFooter
} from '@shared_medialab/ui_components/build/base/Popup/styled';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_BUYER_USERS, GET_BUYER_USER_BY_ID } from 'gql/users/queries';
import client from 'apolloClient';
import { initialFormValues, useUserFormStateContext } from 'providers/UserForm';
import { FieldsValue } from 'providers/UserForm/type';
import { ModalKeys } from 'providers/UI/types';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useProjectStateContext } from 'providers/Projects';
import { CREATE_OR_INVITE_BUYER_USER } from 'gql/permissions/mutations';
import { UPDATE_USER } from 'gql/users/mutations';
import { BuyerUserById } from 'gql/users/types/BuyerUserById';

import {
  ProjectsRoles,
  PersonalDetails
} from 'pages/UserManagement/components';
import { ConfirmModalTypes } from 'components/shared/ConfirmModal/types';

const CreateUser: FC = () => {
  // context
  const { reset, handleSubmit } = useUserFormStateContext();
  const { toggleModal } = useUIDispatchContext();
  const { userDetails } = useModalStateContext();
  const { selectedProject } = useProjectStateContext();
  // translations
  const { t } = useTranslation(['users', 'messages']);

  const userId = userDetails?.params?.userId || false;
  // graphql
  const { data: userData } = useQuery<BuyerUserById>(GET_BUYER_USER_BY_ID, {
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      query: {
        organizationId: selectedProject?.id,
        userId: userId
      }
    },
    onCompleted(data) {
      const user = data.buyerUserById?.data;

      if (user) {
        reset({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          project: {
            role: user.role?.id,
            status: user.status
          }
        });
      }
    }
  });

  const userStatus = userData?.buyerUserById?.data;

  const [createOrInviteUser, { loading: createOrInviteLoading }] = useMutation(
    CREATE_OR_INVITE_BUYER_USER,
    {
      onCompleted() {
        toast.success(
          <SuccessToast text={t('messages:successfully_invited')} />
        );
        client.refetchQueries({ include: [GET_BUYER_USERS] });
        onClose();
      },
      onError(error) {
        const mailError =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (
            error?.graphQLErrors?.[0]?.extensions?.exception as Record<
              string,
              any
            >
          )?.details;

        if (mailError) {
          toggleModal(ModalKeys.confirm, true, {
            title: t('the_same_email'),
            titleColor: '#FF7875',
            message: t('email_message'),
            cancelText: 'Cancel',
            confirmText: 'Users table',
            type: ConfirmModalTypes.primaryNew,
            onConfirm() {
              toggleModal(ModalKeys.userDetails, false);
            }
          });
        } else {
          toast.error(error.message);
        }
      }
    }
  );

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted() {
      toast.success(<SuccessToast text={t('messages:successfully_updated')} />);
      client.refetchQueries({ include: [GET_BUYER_USERS] });
      onClose();
    },
    onError(error) {
      toast.error(error.message);
    }
  });

  const onClose = useCallback(() => {
    reset({ ...initialFormValues });
    toggleModal(ModalKeys.userDetails, false);
  }, [reset, toggleModal]);

  const onSubmit = useCallback(
    (values: FieldsValue) => {
      if (userId) {
        return updateUser({
          variables: {
            id: userId,
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              project: {
                role: values.project?.role,
                status: values.project?.status,
                projectId: selectedProject?.id,
                roleName: userData?.buyerUserById?.data?.role?.name || '',
                name: selectedProject?.name
              }
            }
          }
        });
      }

      createOrInviteUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            project: {
              role: values.project?.role,
              projectId: selectedProject?.id,
              name: selectedProject?.name || ''
            }
          }
        }
      });
    },
    [
      createOrInviteUser,
      selectedProject?.id,
      selectedProject?.name,
      updateUser,
      userData?.buyerUserById?.data?.role?.name,
      userId
    ]
  );

  const renderTitle = () => {
    if (userId) {
      return t('users:edit');
    } else {
      return t('users:create_user');
    }
  };

  return (
    <Popup
      isVisible={userDetails?.visible}
      onClose={onClose}
      width={50}
      title={renderTitle()}
      type="CRUD"
    >
      <PopupContent darkBg type="CRUD">
        <div style={{ position: 'relative' }}>
          <Row gutter={[5, 0]}>
            <Col span={5}>
              <PersonalDetails userStatus={userStatus?.status as string} />
            </Col>
            <Col span={7}>
              <ProjectsRoles />
            </Col>
          </Row>
        </div>
      </PopupContent>
      <PopupFooter style={{ padding: 2 }}>
        <Button
          color="confirmNew"
          flexibility="content-size"
          cornerRadius="smooth"
          onClick={handleSubmit(onSubmit)}
          loading={createOrInviteLoading || updateLoading}
        >
          {userId ? t('users:save') : t('users:invite')}
        </Button>
      </PopupFooter>
    </Popup>
  );
};

export default CreateUser;
