import { Tooltip } from '@shared_medialab/ui_components';
import { FC } from 'react';

import { StyledInfoLabel } from './styled';
import { IInfoLabelProps } from './types';

const InfoLabel: FC<IInfoLabelProps> = ({ title, tooltip, width }) => {
  return (
    <StyledInfoLabel tooltip={!!tooltip}>
      <p>{title}</p>
      {tooltip && (
        <Tooltip title={tooltip} width={width}>
          <div className="input-tooltip">
            <span>
              <svg
                width="2"
                height="8"
                viewBox="0 0 2 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.04593 0H0.954066C0.42715 0 0 0.42715 0 0.954066C0 1.34419 0.237516 1.69501 0.599735 1.83989L0.628609 1.85144C0.86702 1.94681 1.13298 1.94681 1.37139 1.85144L1.40027 1.83989C1.76248 1.69501 2 1.34419 2 0.954066C2 0.42715 1.57285 0 1.04593 0Z" />
                <path d="M2 7V4.2C2 3.64772 1.55228 3.2 1 3.2C0.447715 3.2 0 3.64771 0 4.2V7C0 7.55228 0.447715 8 1 8C1.55228 8 2 7.55229 2 7Z" />
              </svg>
            </span>
          </div>
        </Tooltip>
      )}
    </StyledInfoLabel>
  );
};

export default InfoLabel;
