export const money = (
  amount: number | string,
  options?: Omit<Intl.NumberFormatOptions, 'currency' | 'style'>
): string => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options
  }).format(+amount);
};
