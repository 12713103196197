import { MatchTypesEnum } from 'constants/match';
import dayjs from 'dayjs';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { RootState } from 'store';
import { useLazyQuery } from '@apollo/client';
import { useProjectStateContext } from 'providers/Projects';
import { useEffect } from 'react';

import { EventsFiltersState } from './types';
import { UserEnum } from 'pages/UserManagement/constants';
import { eventsInitialFilters } from '.';

export const selectEventsFilters =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].filters;

export const selectFormattedEventsFilters =
  (type: MatchTypesEnum) => (state: RootState) => {
    const { filters } = state.filters.events[type];
    const eventFilters = {
      name: filters.name,
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      sportIds: filters.sportIds,
      regionIds: filters.regionIds,
      leagueIds: filters.leagueIds,
      status: filters.status
    };

    return type === MatchTypesEnum.history
      ? {
          ...eventFilters,
          bookingType: filters.bookingType
        }
      : { ...eventFilters };
  };

export const selectEventsFiltersForPreset =
  (type: MatchTypesEnum) => (state: RootState) => {
    const { sportIds, regionIds, leagueIds, status, dates } =
      state.filters.events[type].filters;

    return {
      sportIds,
      regionIds,
      leagueIds,
      status,
      dates
    };
  };

export const selectShowSavePreset =
  (type: MatchTypesEnum) => (state: RootState) => {
    const { sportIds, regionIds, leagueIds, status, dates } =
      state.filters.events[type].filters;

    let initialFilters;

    switch (type) {
      case MatchTypesEnum.history:
        initialFilters = {
          ...eventsInitialFilters,
          dates: {
            startDate: dayjs().add(-1, 'week').startOf('day').format(),
            endDate: dayjs().endOf('day').format()
          }
        };
        break;
      default:
        initialFilters = eventsInitialFilters;
        break;
    }

    const isDatechanged =
      dates.startDate.slice(0, 10) !==
        initialFilters.dates.startDate.slice(0, 10) ||
      dates.endDate.slice(0, 10) !== initialFilters.dates.endDate.slice(0, 10);

    return (
      !!sportIds.length ||
      !!regionIds.length ||
      !!leagueIds.length ||
      !!status.length ||
      isDatechanged
    );
  };

export const selectIsPresetChanged =
  (
    type: MatchTypesEnum,
    filters?: EventsFiltersState[MatchTypesEnum]['filters']
  ) =>
  (state: RootState) => {
    if (!filters) return false;

    const {
      filters: { sportIds, regionIds, leagueIds, status },
      selectedPreset,
      name
    } = state.filters.events[type];

    return (
      JSON.stringify({ ...filters, name: selectedPreset }) !==
      JSON.stringify({ sportIds, regionIds, leagueIds, status, name })
    );
  };

export const selectHasFilters =
  (type: MatchTypesEnum) => (state: RootState) => {
    const { name, dates, sportIds, regionIds, leagueIds, status } =
      state.filters.events[type].filters;

    const isStartDateChanged = !dayjs(dates.startDate).isSame(
      type === MatchTypesEnum.history ? dayjs().add(-1, 'week') : dayjs(),
      'days'
    );

    const isEndDateChanged = !dayjs(dates.endDate).isSame(
      type === MatchTypesEnum.history
        ? dayjs().add(1, 'minute')
        : dayjs().add(1, 'months'),
      'days'
    );

    return (
      !!name ||
      isStartDateChanged ||
      isEndDateChanged ||
      !!sportIds.length ||
      !!regionIds.length ||
      !!leagueIds.length ||
      !!status.length
    );
  };

export const selectSelectedPresetName =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].selectedPreset;

export const selectSelectedPreset =
  (type: MatchTypesEnum) => (state: RootState) => {
    const selectedPreset = selectSelectedPresetName(type)(state);
    // context
    const { selectedProject } = useProjectStateContext();

    const [getUserData, { data }] = useLazyQuery<UserMe>(GET_USER_DATA);

    useEffect(() => {
      const organizationId =
        localStorage.getItem('projectId') || selectedProject?.id;

      getUserData({
        fetchPolicy: 'cache-only',
        variables: {
          ...(organizationId ? { organizationId } : {}),
          groupName: UserEnum.buyer
        }
      });
    }, [getUserData, selectedProject?.id]);

    const filters = data?.me?.data.settings.filters || {};

    return filters[selectedPreset];
  };

export const selectEventsPresetFiltersNameInput =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].name;

export const selectEventsPresetFiltersError =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].error;

export const selectEventsPagination =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type]?.pagination;

export const selectEventsLoading =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].loading;

export const selectEventsItemPlayButton =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].isPlayDisabled;

export const selectEventsItemStreamUrlError =
  (type: MatchTypesEnum) => (state: RootState) =>
    state.filters.events[type].streamUrlError;
