import React, { FC, useEffect, useState } from 'react';
import { useModalStateContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import UserFormProvider from 'providers/UserForm';
import { UserMe } from 'gql/auth/types/UserMe';
import { GET_USER_DATA } from 'gql/auth/queries';
import { useProjectStateContext } from 'providers/Projects';
import { useLazyQuery } from '@apollo/client';

import { ConfirmModal } from 'components/shared';
import { UserDetails } from 'pages/UserManagement/components';
import BookingCountriesPopup from 'pages/Events/components/BookingCountriesPopup';
import ShoppingCartModal from 'components/shared/ShoppingCartModal';
import SettingsPopup from 'components/Modal/SettingsPopup';
import { UserEnum } from 'pages/UserManagement/constants';
import UnBookModal from './UnBookModal';

const ModalLayout: FC = () => {
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  // context
  const { selectedProject } = useProjectStateContext();

  const [getUserData] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      },
      onCompleted(data) {
        setIsSettingsModalVisible(!data?.me?.data.settings.timezone);
      }
    });
  }, [getUserData, selectedProject?.id]);

  // location
  const {
    [ModalKeys.confirm]: { visible: confirm },
    [ModalKeys.shoppingCart]: { visible: shoppingCart },
    [ModalKeys.userDetails]: { visible: userDetails },
    [ModalKeys.bookingCountries]: { visible: bookingCountries },
    [ModalKeys.unBook]: { visible: unBook }
  } = useModalStateContext();

  return (
    <>
      <SettingsPopup
        isVisible={isSettingsModalVisible}
        showCloseButton={false}
        onClose={() => setIsSettingsModalVisible(false)}
      />
      {confirm && <ConfirmModal key="ConfirmModal" />}
      {shoppingCart && <ShoppingCartModal key="ShoppingCartModal" />}
      {userDetails && (
        <UserFormProvider>
          <UserDetails key="UserDetilsModal" />
        </UserFormProvider>
      )}
      {bookingCountries && <BookingCountriesPopup key="BookingCountries" />}
      {unBook && <UnBookModal key="unBook" />}
    </>
  );
};

export default ModalLayout;
