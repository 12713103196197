import { gql } from '@apollo/client';

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationsInfoFragment on Organization {
    id
    name
    status
    createdAt
  }
`;

export const USER_PROJECT_FRAGMENT = gql`
  fragment UserProjectFragment on UserProject {
    id
    name
    partnerId
    expiredAt
    role
    status
    createdAt
  }
`;
