import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  DateRangePickerNew,
  Select
} from '@shared_medialab/ui_components';
import Aside from '@shared_medialab/ui_components/build/ui/Aside';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { shallowEqual } from 'react-redux';
import { useSidebarStateContext, useUIDispatchContext } from 'providers/UI';
import { SidebarKeys } from 'providers/UI/types';
import {
  selectIsReportsResetEnabled,
  selectReportsFilters
} from 'store/slice/filters/reports/selectors';
import {
  initReportsFilters,
  resetReportsFilters,
  setFilterItem,
  setReportsDateItems
} from 'store/slice/filters/reports';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AllCountries } from 'gql/countries/types/AllCountries';
import { GET_ALL_COUNTRIES } from 'gql/countries/queries';
import { useQuery } from '@apollo/client';
import { useRegions } from 'hooks';
import { GET_BUYER_PLATFORM_REPORTS } from 'gql/reports/queries';
import { useProjectStateContext } from 'providers/Projects';
import { BuyerOsReports } from 'gql/reports/types/BuyerOsReports';

import SportSelect from 'components/shared/SportSelect';
import LeaguesSelect from 'components/shared/LeaguesSelect';
import { getStaticRangesArray } from 'utils/dates';

const ReportsFiltersSidebar: FC = () => {
  // states
  const [search, setSearch] = useState('');
  // translations
  const { t } = useTranslation('sidebar');
  // context
  const { reportsFilters } = useSidebarStateContext();
  const { toggleSidebar } = useUIDispatchContext();
  // redux
  const dispatch = useAppDispatch();
  const { selectedProject } = useProjectStateContext();

  const filters = useAppSelector(selectReportsFilters(), shallowEqual);
  const { regions, regionSearch, onRegionSearch } = useRegions({
    sportIds: filters.sport
  });

  const isResetActive = useAppSelector(selectIsReportsResetEnabled());

  // graphql
  const variables = useMemo(
    () => ({
      name: search
    }),
    [search]
  );

  const { data } = useQuery<AllCountries>(GET_ALL_COUNTRIES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: {
      query: variables
    },
    context: {
      debounceKey: 'countries',
      debounceTimeout: 400
    }
  });

  const { data: platformOsData } = useQuery<BuyerOsReports>(
    GET_BUYER_PLATFORM_REPORTS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 20
          }
        },
        partnerId: selectedProject?.partnerId
      }
    }
  );

  const countries = data?.allCountries?.data || [];
  const platforms = platformOsData?.buyerOsReports?.data.results || [];

  useEffect(() => {
    dispatch(initReportsFilters());
  }, [dispatch]);

  return (
    <Aside
      isOpen={reportsFilters.visible}
      onChange={value => {
        toggleSidebar(SidebarKeys.reportsFilters, value);
      }}
    >
      <Card
        header={{ title: 'Filters' }}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        bodyStyle={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <DateRangePickerNew
            title={t('dates')}
            dateRange={[
              dayjs(filters.dates.startDate).local().toDate(),
              dayjs(filters.dates.endDate).toDate()
            ]}
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'dates',
                  value: {
                    startDate: dayjs(value.startDate)
                      .local()
                      .startOf('day')
                      .format('YYYY-MM-DD HH:mm:ss'),
                    endDate: dayjs(value.endDate)
                      .endOf('day')
                      .format('YYYY-MM-DD HH:mm:ss')
                  }
                })
              );
              dispatch(
                setReportsDateItems({
                  value: {
                    startDate: '',
                    endDate: ''
                  }
                })
              );
            }}
            staticRanges={[
              {
                label: t('dates:today'),
                value: getStaticRangesArray('day')
              },
              {
                label: t('dates:this_week'),
                value: getStaticRangesArray('week')
              },
              {
                label: t('dates:this_month'),
                value: getStaticRangesArray('month')
              },
              {
                label: t('dates:this_year'),
                value: getStaticRangesArray('year')
              }
            ]}
          />
          <Select
            options={countries.map(item => ({
              value: item.name,
              label: item.name
            }))}
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'countryNames',
                  value
                })
              );
            }}
            onClose={() => {
              if (!filters.countryNames.length) setSearch('');
            }}
            searchValue={search}
            onSearch={e => setSearch(e.target.value)}
            placeholder={t('common:select_location')}
            value={filters.countryNames}
            cornerRadius="smooth"
            mode="multiple"
            label={t('buyers:location')}
            className="full-width mv--40"
          />
          <SportSelect
            placeholder={t('events:select_sports')}
            label={t('events:sports')}
            value={filters.sport}
            regionIds={filters.region}
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'sport',
                  value
                })
              );
            }}
          />
          <Select
            showSelectAll={false}
            placeholder={t('events:select_regions')}
            label={t('events:regions')}
            cornerRadius="smooth"
            options={regions.map(
              (item: { name: string; creationId: string }) => ({
                label: item.name,
                value: item.creationId
              })
            )}
            onClose={() => {
              if (!filters.region.length) onRegionSearch('');
            }}
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'region',
                  value
                })
              );
            }}
            onSearch={e => onRegionSearch(e.target.value)}
            searchValue={regionSearch}
            className="mv--40"
            value={filters.region}
            mode="multiple"
          />
          <LeaguesSelect
            placeholder={t('events:select_leagues')}
            label={t('events:leagues')}
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'league',
                  value
                })
              );
            }}
            value={filters.league}
          />
          <Select
            showSelectAll={false}
            placeholder={t('events:select_platform')}
            label={t('events:platform')}
            cornerRadius="smooth"
            options={platforms.map(item => ({
              label: item.id,
              value: item.id
            }))}
            onClose={() => {
              if (!filters.platform.length) onRegionSearch('');
            }}
            hideSearch
            onChange={value => {
              dispatch(
                setFilterItem({
                  name: 'platform',
                  value
                })
              );
            }}
            onSearch={e => onRegionSearch(e.target.value)}
            value={filters.platform}
            className="mv--40"
            mode="multiple"
          />
        </div>
        <Button
          cornerRadius="smooth"
          color="contrastNew"
          disabled={!isResetActive}
          onClick={() => {
            dispatch(resetReportsFilters());
          }}
        >
          Reset
        </Button>
      </Card>
    </Aside>
  );
};

export default ReportsFiltersSidebar;
