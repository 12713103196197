import { gql } from '@apollo/client';

export const GET_ALL_REGIONS = gql`
  query AllRegions($query: AllRegionsInput!) {
    allRegions(query: $query) {
      data {
        id
        name
        creationId
      }
    }
  }
`;

export const GET_ALL_REGIONS_BY_ATTACHED_LEAGUES = gql`
  query AllRegionsByAttachedLeagues($query: AllRegionsByAttachedLeaguesInput!) {
    allRegionsByAttachedLeagues(query: $query) {
      data {
        id
        creationId
        name
      }
    }
  }
`;
