import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { EventsFiltersSidebar } from 'pages/Events/components';
import { UsersFiltersSidebar } from 'pages/UserManagement/components';
import { ReportsFiltersSidebar } from 'pages/Reports/components';

const AsideLeft: FC = () => {
  // location
  const { pathname } = useLocation();

  switch (pathname) {
    case '/streaming/events/list':
    case '/streaming/events/booked':
    case '/streaming/events/history':
      return <EventsFiltersSidebar />;
    case '/streaming/users':
      return <UsersFiltersSidebar />;
    case '/streaming/reporting':
      return <ReportsFiltersSidebar />;
    default:
      return null;
  }
};

export default AsideLeft;
