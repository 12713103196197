import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Popover,
  //TODO: useDarkOrLight,
  Typography
} from '@shared_medialab/ui_components';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import client from 'apolloClient';
import { useProjectStateContext } from 'providers/Projects';
import { DELETE_CART_BY_ORGANIZATION } from 'gql/cart/mutations';
import config from 'config';
//TODO: import { IconMoonTypes } from '@shared_medialab/ui_components/build/base/Icon/MoonIcon/types';

import SettingsPopup from 'components/Modal/SettingsPopup';
import {
  Marquee,
  MenuContent,
  StyledButton,
  StyledPopover,
  TooltipEllipses
} from './styled';
import useEllipsis from 'hooks/useEllipsesTooltip';
import { UserEnum } from 'pages/UserManagement/constants';
import MenuItem from './components/MenuItem';
import './index.css';

const { Text } = Typography;

const UserInfo: FC = () => {
  // context
  const { selectedProject } = useProjectStateContext();
  const { toggleModal } = useUIDispatchContext();
  const { settings } = useModalStateContext();
  //TODO: const { theme, onChangeTheme } = useDarkOrLight();

  // states
  const [isOpen, setOpen] = useState(false);

  // translations
  const { t } = useTranslation(['header', 'common']);

  // gql
  const [getUserData, { data }] = useLazyQuery<UserMe>(GET_USER_DATA);

  const [deleteCartByOrganizationId] = useMutation(DELETE_CART_BY_ORGANIZATION);

  const organizationId = useMemo(
    () => localStorage.getItem('projectId') || selectedProject?.id,
    [selectedProject?.id]
  );

  useEffect(() => {
    getUserData({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, organizationId, selectedProject?.id]);

  const user = data?.me?.data;

  const logOut = () => {
    const forceLogout = () => {
      localStorage.clear();
      client.clearStore();

      if (config.sso_url) {
        window.location.href = config.sso_url;
      }
    };

    toggleModal(ModalKeys.confirm, true, {
      title: 'Log out',
      message: 'Are you sure?',
      onConfirm() {
        if (organizationId) {
          deleteCartByOrganizationId({
            fetchPolicy: 'network-only',
            variables: {
              organizationId
            },
            onCompleted() {
              forceLogout();
            }
          });
        } else {
          forceLogout();
        }
      }
    });
  };

  const { hover, hasEllipses, handleMouseEnter, handleMouseLeave } =
    useEllipsis();

  return (
    <>
      <Popover
        selfSizing
        position="bottom-end"
        destination="wrapper"
        className="mt--8"
        opened={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        content={
          <StyledPopover>
            <div className="flex-display flex-direction-column p--16 info-container">
              <Text singleLine color="var(--text-color)">
                {user?.firstName} {user?.lastName}
              </Text>
              <Text
                color="var(--text-color)"
                level={13}
                className="mt--12"
                bold
              >
                {user?.role?.name}
              </Text>
              <Text
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                color="var(--text-color)"
                level={13}
                className="mt--12 text-opacity"
              >
                {user?.email}
                {hover && hasEllipses && (
                  <TooltipEllipses>
                    <Marquee>{user?.email}</Marquee>
                  </TooltipEllipses>
                )}
              </Text>
            </div>
            <MenuContent>
              <MenuItem
                onClick={() => toggleModal(ModalKeys.settings, true)}
                iconType="time"
                text={t('header:time_and_country')}
              />
              {/*TODO:*/}
              {/*<MenuItem*/}
              {/*  onClick={onChangeTheme}*/}
              {/*  iconType={`c-${theme}-icon` as IconMoonTypes}*/}
              {/*  text={t(`common:${theme}`)}*/}
              {/*/>*/}
              <MenuItem
                onClick={logOut}
                iconType="logout"
                text={t('log_out')}
              />
            </MenuContent>
          </StyledPopover>
        }
      >
        <StyledButton active={isOpen}>
          <Icon type="user" size={18} />
        </StyledButton>
      </Popover>
      <SettingsPopup
        isVisible={settings.visible}
        onClose={() => toggleModal(ModalKeys.settings, false)}
      />
    </>
  );
};

export default memo(UserInfo);
