import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.data
      };

    default:
      return state;
  }
};

export default reducer;
