import { gql } from '@apollo/client';

export const GET_SPORTS_BY_ATTACHED_LEAGUE = gql`
  query SportsByAttachedLeagues($query: SportsByAttachedLeaguesQuery) {
    sportsByAttachedLeagues(query: $query) {
      data {
        id
        name
        creationId
        leaguesCount
      }
    }
  }
`;
