import { usePermission } from 'hooks';
import { FC } from 'react';

import { IPrivateProps } from './types';

const Private: FC<IPrivateProps> = ({
  routeId,
  routeKey,
  ignore,
  children
}) => {
  // context
  const { checkUserPermission } = usePermission();

  if (ignore) {
    return <>{children}</>;
  }

  const hasPermission = checkUserPermission(routeId, routeKey);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};

export default Private;
