import { GraphQLFormattedError } from 'graphql';

interface ErrorResponse {
  message: string;
  data?: Record<string, any>;
}

export function getErrorResponse(
  errors: readonly GraphQLFormattedError[]
): ErrorResponse[] {
  return errors.map((error: any) => {
    const message = error?.extensions?.response?.body?.message;
    const data = (error.extensions && error.extensions.data) || {};

    return {
      message,
      data
    };
  });
}
