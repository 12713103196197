import { gql } from '@apollo/client';

export const GET_LEAGUES = gql`
  query LeaguesForProvider($query: GetLeaguesInput!) {
    notAttachedLeagues(query: $query) {
      data {
        total
        results {
          id
          name
          creationId
          sport {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_BUYER_ATTACHED_LEAGUES = gql`
  query BuyerAttachedLeagues($query: GetAttachedLeaguesInput) {
    buyerAttachedLeagues(query: $query) {
      data {
        results {
          id
          creationId
          name
          regionName
          sportName
        }
        total
      }
    }
  }
`;
