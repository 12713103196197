import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NoData } from '@shared_medialab/ui_components';

import { StyledDisabledRole } from 'pages/DisabledRole/styled';

const InActive: FC = () => {
  // translations
  const { t } = useTranslation('not_found');

  return (
    <StyledDisabledRole>
      <NoData
        className="gap--32"
        transparent
        size="large"
        image="Disabled"
        text={t('account:inactive_title')}
        subText={t('account:inactive_text')}
        textProps={{ level: 9, color: 'var(--primary-6)' }}
        subTextProps={{ level: 11, color: 'var(--black)' }}
      />
    </StyledDisabledRole>
  );
};

export default InActive;
