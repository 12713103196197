import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@shared_medialab/ui_components';
import { setEventsFilterStatusItem } from 'store/slice/filters';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';

import { RouteParams } from '../../Content/types';

const EventsCheckbox: FC<{ status: number; className?: string }> = ({
  status,
  className = ''
}) => {
  const { type } = useParams() as RouteParams;

  // translations
  const { t } = useTranslation('events');

  // redux
  const dispatch = useAppDispatch();
  const eventsFilters = useAppSelector(selectEventsFilters(type));

  return (
    <Checkbox
      className={className}
      label={t(`statuses.${status}`)}
      onChange={() => {
        dispatch(
          setEventsFilterStatusItem({
            name: 'status',
            value: status.toString(),
            type
          })
        );
      }}
      checked={eventsFilters.status.includes(status.toString())}
    />
  );
};

export default EventsCheckbox;
