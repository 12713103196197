import { ReactNode } from 'react';
import { Cart_cart_data_matches_availableCountries as CountryPricesType } from 'gql/cart/types/Cart';
import { MatchTypesEnum } from 'constants/match';

export const ActionTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_CART_LOADING: 'SET_CART_LOADING',
  ON_SELECT: 'ON_SELECT',
  ON_SELECT_BULK: 'ON_SELECT_BULK',
  UN_SELECT: 'UN_SELECT',
  UN_SELECT_ITEMS: 'UN_SELECT_ITEMS',
  UN_SELECT_ALL: 'UN_SELECT_ALL',
  SET_FILTERS: 'SET_FILTERS',
  SET_CONTINUE_PAGING: 'SET_CONTINUE_PAGING',
  INIT_MATCHES: 'INIT_MATCHES',
  SELECT_MATCH_COUNTRIES: 'SELECT_MATCH_COUNTRIES',
  APPLY_MATCH_COUNTRIES_TO_ALL: 'APPLY_MATCH_COUNTRIES_TO_ALL',
  SELECT_ALL_COUNTRIES: 'SELECT_ALL_COUNTRIES'
} as const;

export interface IEventsProps {
  children: ReactNode;
}

type ListStatuses = 'not_started' | 'live' | 'canceled';
export type BookedStatuses = ListStatuses | 'manually_booked' | 'auto_booked';

export type ListFiltersData = {
  dates?: string;
  leagues?: unknown[];
  providers?: unknown[];
  regions?: unknown[];
  search?: string;
  sports?: unknown[];
  status: ListStatuses[];
};

export type BookedFiltersData = {
  dates?: string;
  leagues?: unknown[];
  providers?: unknown[];
  regions?: unknown[];
  search?: string;
  sports?: unknown[];
  status: BookedStatuses[];
};

export type ListEventsFilters = {
  type: MatchTypesEnum.list;
  data: ListFiltersData;
};

export type BookedEventsFilters = {
  type: MatchTypesEnum.booked;
  data: BookedFiltersData;
};

export type EventTypes = MatchTypesEnum;

export type CartLoadingStateType = boolean;

export type SelectedStateType = Record<EventTypes, string[]>;

export type FiltersStateType = Record<
  EventTypes,
  ListFiltersData | BookedFiltersData
>;

export type EventsPagingType = {
  loading: boolean;
  page: number;
  continuePaging: boolean;
};

export type EventsFiltersType = {
  type: EventTypes;
  page: number;
};

export type SelectedCountriesStateType = Record<string, CountryPricesType[]>;

export type CartMatches = {
  id: string;
  countries: CountryPricesType[];
};

export type State = {
  cartLoading: CartLoadingStateType;
  selected: SelectedStateType;
  filters: FiltersStateType;
  paging: EventsPagingType;
  cartCountries: SelectedCountriesStateType;
};

type SetCartLoading = {
  type: typeof ActionTypes.SET_CART_LOADING;
  data: boolean;
};

type OnSelect = {
  type: typeof ActionTypes.ON_SELECT;
  data: {
    id: string;
    type: EventTypes;
  };
};

type UnSelect = {
  type: typeof ActionTypes.UN_SELECT;
  data: {
    id: string;
    type: EventTypes;
  };
};
type UnSelectItems = {
  type: typeof ActionTypes.UN_SELECT_ITEMS;
  data: {
    ids: string[];
    type: EventTypes;
  };
};
type UnSelectAll = {
  type: typeof ActionTypes.UN_SELECT_ALL;
  data: EventTypes;
};

type BulkSelect = {
  type: typeof ActionTypes.ON_SELECT_BULK;
  data: {
    IDs: string[];
    type: EventTypes;
  };
};

type SetLoading = {
  type: typeof ActionTypes.SET_LOADING;
  data: boolean;
};

type SetFilters = {
  type: typeof ActionTypes.SET_FILTERS;
  data: BookedEventsFilters | ListEventsFilters;
};

type SetContinuePaging = {
  type: typeof ActionTypes.SET_CONTINUE_PAGING;
  data: boolean;
};

type InitMatches = {
  type: typeof ActionTypes.INIT_MATCHES;
  data: {
    matches: CartMatches[];
    type: EventTypes;
  };
};

type SelectMatchCountries = {
  type: typeof ActionTypes.SELECT_MATCH_COUNTRIES;
  data: {
    matchId: string;
    data: CountryPricesType;
  };
};

type ApplyMatchCountriesToAll = {
  type: typeof ActionTypes.APPLY_MATCH_COUNTRIES_TO_ALL;
  data: string;
};

type SelectAllCountries = {
  type: typeof ActionTypes.SELECT_ALL_COUNTRIES;
  data: {
    matchId: string;
    data: CountryPricesType[];
  };
};

export type Action =
  | SetCartLoading
  | OnSelect
  | UnSelect
  | UnSelectItems
  | UnSelectAll
  | BulkSelect
  | SetLoading
  | SetFilters
  | SetContinuePaging
  | InitMatches
  | SelectMatchCountries
  | ApplyMatchCountriesToAll
  | SelectAllCountries;

export type DispatchContext = {
  setLoading: (loading: boolean) => void;
  setCartLoading: (loading: boolean) => void;
  onSelectItem: (id: string, type: EventTypes) => void;
  unselectItem: (id: string, type: EventTypes) => void;
  unselectItems: (ids: string[], type: EventTypes) => void;
  unselectAll: (type: EventTypes) => void;
  initMatches: (matches: CartMatches[], type: EventTypes) => void;
  onBulkSelect: (IDs: string[], type: EventTypes) => void;
  setFilters: (data: BookedEventsFilters | ListEventsFilters) => void;
  setContinuePaging: (data: boolean) => void;
  setCartItemCountries: (matchId: string, data: CountryPricesType) => void;
  applyCartItemCountriesToAll: (matchId: string) => void;
  selectMultipleCountries: (matchId: string, data: CountryPricesType[]) => void;
};

export type Dispatch = (action: Action) => void;
