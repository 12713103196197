interface PermissionGroup {
  [key: string]: number;
}
class PermissionGroup {
  constructor(data: string[] = []) {
    this[VIEW] = 2;
    data.forEach((key, index) => {
      this[key] = Math.pow(2, index + 2);
    });
  }
}

export const VIEW = 'VIEW';
export const INVITE = 'INVITE';
export const EXPORT = 'EXPORT';
export const SET_STREAMER = 'SET_STREAMER';
export const TAKE_TO_UPCOMING = 'TAKE_TO_UPCOMING';
export const EDIT_STREAMER = 'EDIT_STREAMER';
export const EDIT_COUNTRY_PRICES = 'EDIT_COUNTRY_PRICES';
export const START_STOP_STREAM = 'START_STOP_STREAM';
export const START_END_CANCEL = 'START_END_CANCEL';
export const GENERATE_URL = 'GENERATE_URL';
export const CREATE_STREAM = 'CREATE_STREAM';
export const BACK_TO_UPCOMING = 'BACK_TO_UPCOMING';
export const MANUAL_BOOK = 'MANUAL_BOOK';
export const QUICK_BUY = 'QUICK_BUY';
export const DEMO_PREVIEW = 'DEMO_PREVIEW';
export const UNBOOK = 'UNBOOK';
export const VIEW_VIDEO = 'VIEW_VIDEO';
export const CREATE = 'CREATE';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';
export const FREEZE = 'FREEZE';
export const ATTACH = 'ATTACH';
export const DETACH = 'DETACH';
export const SET_BLOCKED_COUNTRIES = 'SET_BLOCKED_COUNTRIES';
export const SET_COUNTRY_PRICES = 'SET_COUNTRY_PRICES';
export const EDIT_BLOCKED_COUNTRIES = 'EDIT_BLOCKED_COUNTRIES';
export const ADD_NOTIFICATION_EMAILS = 'ADD_NOTIFICATION_EMAILS';
export const EDIT_PRICING_MODELS = 'EDIT_PRICING_MODELS';
export const BOOKING_DETAILS = 'BOOKING_DETAILS';

export const BUYER_ROUTES_IDS = {
  buyer_users: 2,
  list: 5,
  history: 7,
  booked: 8,
  autoBooking: 17,
  reports: 19,
  invoice_buyers: 20,
  buyers_details_view: 21,
  content_group: 22,
  buyers_reports: 23
};

const BUYER_MATCH_LIST = new PermissionGroup([
  MANUAL_BOOK,
  QUICK_BUY,
  DEMO_PREVIEW
]);

const BUYER_MATCH_BOOKED = new PermissionGroup([
  UNBOOK,
  VIEW_VIDEO,
  BOOKING_DETAILS
]);

const BUYER_MATCH_HISTORY = new PermissionGroup([BOOKING_DETAILS]);

const BUYER_AUTO_BOOKING = new PermissionGroup([CREATE, EDIT, DELETE, FREEZE]);

const BUYER_USERS = new PermissionGroup([INVITE, EDIT]);

const REPORTS = new PermissionGroup([EXPORT]);

export const PERMISSION_ROUTES = {
  [BUYER_ROUTES_IDS.list]: BUYER_MATCH_LIST,
  [BUYER_ROUTES_IDS.booked]: BUYER_MATCH_BOOKED,
  [BUYER_ROUTES_IDS.history]: BUYER_MATCH_HISTORY,
  [BUYER_ROUTES_IDS.autoBooking]: BUYER_AUTO_BOOKING,
  [BUYER_ROUTES_IDS.reports]: REPORTS,
  [BUYER_ROUTES_IDS.buyer_users]: BUYER_USERS
};

export const BUYER_ROUTES_PATHS = {
  list: '/streaming/events/list',
  booked: '/streaming/events/booked',
  history: '/streaming/events/history',
  autoBooking: '/streaming/events/autobooking',
  reports: '/streaming/reporting',
  buyerUsers: '/streaming/users'
};
export const PERMISSION_ROUTE_PATHS = {
  [BUYER_ROUTES_PATHS.list]: BUYER_ROUTES_IDS.list,
  [BUYER_ROUTES_PATHS.booked]: BUYER_ROUTES_IDS.booked,
  [BUYER_ROUTES_PATHS.history]: BUYER_ROUTES_IDS.history,
  [BUYER_ROUTES_PATHS.autoBooking]: BUYER_ROUTES_IDS.autoBooking,
  [BUYER_ROUTES_PATHS.reports]: BUYER_ROUTES_IDS.reports,
  [BUYER_ROUTES_PATHS.buyerUsers]: BUYER_ROUTES_IDS.buyer_users
};
