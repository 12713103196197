// TODO: delete providers/Filter folder

import { State as FilterProviderState } from 'providers/FilterProvider/types';
import { initialState as filterProviderInitialState } from 'providers/FilterProvider';

import { parseJson } from './object';

export const storeFilter = () => {
  let timer: NodeJS.Timeout | null = null;

  return (filters: FilterProviderState) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      localStorage.setItem('filters', JSON.stringify(filters));
    }, 500);
  };
};

export const getStoredFilter = () => {
  const stored = localStorage.getItem('filters');

  if (!stored) return { ...filterProviderInitialState };

  return parseJson(stored) as unknown as FilterProviderState;
};
