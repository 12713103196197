import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { useProjectStateContext } from 'providers/Projects';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { UPDATE_USER_SETTINGS } from 'gql/users/mutations';
import { toast } from 'react-toastify';
import client from 'apolloClient';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { shallowEqual } from 'react-redux';
import {
  resetEventsFilters,
  setEventsFilterPresetError,
  setEventsFilterPresetName
} from 'store/slice/filters';
import {
  selectEventsFiltersForPreset,
  selectEventsPresetFiltersNameInput,
  selectSelectedPresetName
} from 'store/slice/filters/events/selector';
import { useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';

import { RouteParams } from 'pages/Events/components/Content/types';
import { UserEnum } from 'pages/UserManagement/constants';

const useEventsFilters = () => {
  // navigation
  const { type } = useParams() as RouteParams;
  // context
  const { selectedProject } = useProjectStateContext();
  const { toggleModal } = useUIDispatchContext();
  // redux
  const dispatch = useAppDispatch();
  const currentFilters = useAppSelector(
    selectEventsFiltersForPreset(type),
    shallowEqual
  );

  const selectedPresetName = useAppSelector(
    selectSelectedPresetName(type),
    shallowEqual
  );

  const selectedPreset = useAppSelector(selectSelectedPresetName(type));
  const name = useAppSelector(selectEventsPresetFiltersNameInput(type));

  // graphql
  const [getUserData, { data }] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const [update, { loading }] = useMutation(UPDATE_USER_SETTINGS, {
    onError(error) {
      toast.error(error.message);
    },
    onCompleted() {
      toast.success('Successfully saved');
      client.refetchQueries({
        include: [GET_USER_DATA]
      });
    }
  });

  const filters = useMemo(() => {
    const settings = data?.me?.data.settings;

    return { ...(settings?.filters || {}) };
  }, [data?.me?.data.settings]);

  const filtersByProject = useMemo(() => {
    if (!selectedProject) return {};

    return filters[selectedProject?.id || ''] || {};
  }, [filters, selectedProject]);

  const filterByType = useMemo(
    () => filtersByProject[type] || {},
    [filtersByProject, type]
  );

  const filterByTypeNames = useMemo(
    () => Object.keys(filterByType),
    [filterByType]
  );

  const onSaveFilters = useCallback(
    (cb?: () => void) => {
      if (!selectedProject) {
        return;
      }

      if (!selectedPresetName) {
        if (!name) {
          return dispatch(
            setEventsFilterPresetError({
              type,
              value: true,
              message: ''
            })
          );
        }

        if (filterByType[name]) {
          return dispatch(
            setEventsFilterPresetError({
              type,
              value: true,
              message: 'A rule with this name already exists'
            })
          );
        }
      }

      dispatch(
        setEventsFilterPresetError({
          type,
          value: false,
          message: ''
        })
      );

      const settings = data?.me?.data.settings;
      const oldFilters = filterByType[selectedPreset] || {};
      const newFilters = { ...filterByType };

      if (newFilters[selectedPreset]) {
        delete newFilters[selectedPreset];
      }

      newFilters[name] = {
        ...oldFilters,
        ...currentFilters
      };

      update({
        variables: {
          input: {
            settings: JSON.stringify({
              ...settings,
              filters: {
                ...filters,
                [selectedProject.id || '']: {
                  ...filtersByProject,
                  [type]: newFilters
                }
              }
            })
          }
        }
      }).then(res => {
        if (res.data) {
          dispatch(setEventsFilterPresetName({ value: name, type }));
          cb?.();
        }
      });
    },
    [
      data?.me?.data.settings,
      selectedPresetName,
      filtersByProject,
      selectedProject,
      currentFilters,
      selectedPreset,
      filterByType,
      filters,
      type,
      name,
      update,
      dispatch
    ]
  );

  const onDeleteFilters = useCallback(
    (name: string, cb?: () => void) => {
      if (!selectedProject) {
        return;
      }

      const settings = data?.me?.data.settings;

      toggleModal(ModalKeys.confirm, true, {
        title: '',
        message: 'Are you sure you want to delete this preset?',
        onConfirm: () => {
          const newFilters = { ...filterByType };
          delete newFilters[name];

          update({
            variables: {
              input: {
                settings: JSON.stringify({
                  ...settings,
                  filters: {
                    ...filters,
                    [selectedProject.id || '']: {
                      ...filtersByProject,
                      [type]: newFilters
                    }
                  }
                })
              }
            }
          }).then(res => {
            if (res.data && name === selectedPresetName) {
              dispatch(resetEventsFilters({ type }));
              cb?.();
            }
          });
        }
      });
    },
    [
      data?.me?.data.settings,
      selectedPresetName,
      filtersByProject,
      selectedProject,
      filterByType,
      filters,
      type,
      update,
      dispatch,
      toggleModal
    ]
  );

  const onDeleteAllFilters = useCallback(
    (cb?: () => void) => {
      if (!selectedProject) {
        return;
      }

      const settings = data?.me?.data.settings;

      toggleModal(ModalKeys.confirm, true, {
        title: '',
        message: 'Are you sure you want to delete all presets?',
        onConfirm: () => {
          update({
            variables: {
              input: {
                settings: JSON.stringify({
                  ...settings,
                  filters: {
                    ...filters,
                    [selectedProject.id || '']: {
                      ...filtersByProject,
                      [type]: {}
                    }
                  }
                })
              }
            }
          }).then(res => {
            if (res.data) {
              dispatch(resetEventsFilters({ type }));
              cb?.();
            }
          });
        }
      });
    },
    [
      data?.me?.data.settings,
      filtersByProject,
      selectedProject,
      filters,
      type,
      update,
      dispatch,
      toggleModal
    ]
  );

  return {
    presetFilters: filterByType,
    presetFiltersNames: filterByTypeNames,
    loading,
    onDeleteAllFilters,
    onDeleteFilters,
    onSaveFilters
  };
};

export default useEventsFilters;
