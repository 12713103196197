import React, { FC } from 'react';
import { Checkbox, Typography } from '@shared_medialab/ui_components';

import { MatchTypesEnum } from '../../../../../constants/match';
import { IItemProps } from '../types';

const { Text } = Typography;

const Item: FC<IItemProps> = ({
  data,
  type,
  checked,
  setCheckboxes,
  checkboxes,
  isEventLive
}) => {
  const handleCheckboxChange = (id: string) => {
    const updatedCheckboxes = checkboxes.map(checkbox =>
      checkbox.id === id
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox
    );

    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div className="popup-item flex-display flex-justify-space-between flex-align-items-center pv--12 ph--16 mt--12">
      {type === MatchTypesEnum.booked && !isEventLive ? (
        <Checkbox
          checked={checked}
          label={data.country}
          className="flex-2"
          onChange={() => handleCheckboxChange(data?.id)}
        />
      ) : (
        <div className="flex-2 flex-display flex-justify-start">
          <Text level={13}>{data.country}</Text>
        </div>
      )}
      <div className="flex-display flex-justify-center flex-1">
        <Text level={13}>{data.bookingType}</Text>
      </div>
      <div className="flex-1 flex-display flex-justify-end">
        <Text level={13} className="currency">
          {data.price} EUR
        </Text>
      </div>
    </div>
  );
};

export default Item;
