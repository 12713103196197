import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  Loading,
  Popup,
  Typography
} from '@shared_medialab/ui_components';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useProjectStateContext } from 'providers/Projects';
import { GET_BOOKED_EVENTS, GET_BOOKING_COUNTRIES } from 'gql/events/queries';
import { GetMatchBookingCountries } from 'gql/events/GetMatchBookingCountries';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MATCH_STATUS_IDS, MatchTypesEnum } from 'constants/match';
import { UN_BOOK_MATCHES_BY_COUNTRY } from 'gql/events/mutations';
import client from 'apolloClient';
import * as permissionConstants from 'constants/permissions';

import Content from './components/Content';
import { StyledMessage } from 'components/shared/ConfirmModal/styled';
import { RouteParams as EventsRouterParams } from '../Content/types';
import { ICountryPrice } from './types';
import './index.css';
import Private from 'components/shared/Private';

const { Text } = Typography;

const BookingCountriesPopup: FC = () => {
  // translations
  const { t } = useTranslation('autobooking');

  const [search, setSearch] = useState('');

  // context
  const { bookingCountries } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  const { selectedProject } = useProjectStateContext();
  const { type } = useParams() as EventsRouterParams;

  // graphql
  const { data: pricesData, loading } = useQuery<GetMatchBookingCountries>(
    GET_BOOKING_COUNTRIES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          state: MatchTypesEnum[type],
          matchId: bookingCountries.params?.id,
          organizationId: selectedProject?.id
        }
      }
    }
  );

  const countryPrices = pricesData?.getMatchBookingCountries?.data || [];

  const onClose = useCallback(() => {
    toggleModal(ModalKeys.bookingCountries, false);
  }, [toggleModal]);

  const searchedItems = countryPrices.filter(option =>
    option.country?.toLowerCase().includes(search.toLowerCase())
  );

  const [checkboxes, setCheckboxes] = useState<ICountryPrice[]>(
    searchedItems as ICountryPrice[]
  );

  useEffect(() => {
    setCheckboxes(searchedItems);
  }, [countryPrices]);

  const unBookBtnEnable = useMemo(
    () => checkboxes.some(checkbox => checkbox?.isChecked),
    [checkboxes]
  );

  const [unBookByCountry, { loading: loadingUnBookByCountry }] = useMutation(
    UN_BOOK_MATCHES_BY_COUNTRY,
    {
      onCompleted() {
        toast.success('Successfully booked');
        client.refetchQueries({ include: [GET_BOOKED_EVENTS] });
      },
      onError(error) {
        toast.error(error.message);
      }
    }
  );

  const onUnBookMatchesByCountry = () => {
    unBookByCountry({
      variables: {
        input: {
          countryIds: checkboxes.filter(el => el.isChecked).map(el => el.id),
          organizationId: selectedProject?.id,
          matchId: bookingCountries.params?.id
        }
      }
    }).then(() => toggleModal(ModalKeys.bookingCountries, false));
  };

  return (
    <Popup
      isVisible={bookingCountries.visible}
      onClose={onClose}
      width={28}
      title={t('booking_countries')}
      type="CRUD"
      alignItems="center"
    >
      {loading ? (
        <Loading />
      ) : (
        <div className="booking-countries-popup flex-display flex-direction-column p--24">
          {type === MatchTypesEnum.booked &&
            bookingCountries.params?.item?.status !== MATCH_STATUS_IDS.live && (
              <div className="flex-display flex-justify-space-between">
                <Input
                  icon="search"
                  className="flex-3 mr--8 search-filed"
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                />
                <Private
                  routeId={permissionConstants.BUYER_ROUTES_IDS.booked}
                  routeKey={permissionConstants.UNBOOK}
                >
                  <Button
                    flexibility="content-size"
                    color="danger"
                    size="small"
                    className="unbook-button"
                    disabled={!unBookBtnEnable}
                    icon="c-unbook"
                    iconSize={18}
                    onClick={() => onUnBookMatchesByCountry()}
                    cornerRadius="smooth"
                  >
                    {t('unbook')}
                  </Button>
                </Private>
              </div>
            )}
          <>
            {countryPrices.length ? (
              <>
                <div className="flex-display text-center full-width pv--24 mt--12">
                  <Text level={14} className="flex-2 text-left pl--12">
                    Country
                  </Text>
                  <Text level={14} className="flex-1 text-center pl--12">
                    Booking type
                  </Text>
                  <Text level={14} className="flex-1 text-right pr--24">
                    Price
                  </Text>
                </div>
                <Content
                  data={searchedItems}
                  loading={loadingUnBookByCountry}
                  setCheckboxes={setCheckboxes}
                  checkboxes={checkboxes}
                  isEventLive={
                    bookingCountries.params?.item?.status ===
                    MATCH_STATUS_IDS.live
                  }
                />
              </>
            ) : (
              <div className="flex-display flex-justify-center p--24">
                <StyledMessage>
                  No booking countries are available for this match event.
                </StyledMessage>
              </div>
            )}
          </>
        </div>
      )}
    </Popup>
  );
};

export default BookingCountriesPopup;
