import { combineReducers } from 'redux';

import eventsReducer from './events';
import usersReducer from './users';
import reportsReducer from './reports';

export default combineReducers({
  events: eventsReducer,
  users: usersReducer,
  reports: reportsReducer
});

export * from './events';
