import { useProjectStateContext } from 'providers/Projects';
import { ProductStatuses } from 'providers/Projects/types';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Button from './components/Button';

const StatusButton: FC = () => {
  // translations
  const { t } = useTranslation('header');
  // context
  const { selectedProject } = useProjectStateContext();

  switch (selectedProject?.status) {
    case ProductStatuses.pending:
      return (
        <Button
          statusText={t('pending_text')}
          btnText={t('pending')}
          mode="warning"
        />
      );
    case ProductStatuses.integration:
      return (
        <Button
          statusText={
            selectedProject.expiredAt
              ? t('integration_text_date', {
                  date: dayjs(selectedProject.expiredAt).format('DD/MM/YY')
                })
              : t('integration_text')
          }
          btnText={t('integration')}
          mode="integration"
        />
      );

    case ProductStatuses.trial: {
      return (
        <Button
          statusText={
            selectedProject.expiredAt
              ? t('trial_text_date', {
                  date: dayjs(selectedProject.expiredAt).format('DD/MM/YY')
                })
              : t('trial_text')
          }
          btnText={t('trial')}
          mode="trial"
        />
      );
    }

    case ProductStatuses.expired: {
      return (
        <Button
          statusText={t('trial_expired_text')}
          btnText={`${t('trial')}`}
          mode="trial"
        />
      );
    }

    case ProductStatuses.inactive:
      return (
        <Button
          statusText={t('inactive_text')}
          btnText={t('inactive')}
          mode="danger"
        />
      );

    default:
      return (
        <Button
          statusText={t('active_text')}
          btnText={t('active')}
          mode="success"
        />
      );
  }
};

export default memo(StatusButton);
