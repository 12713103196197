import { gql } from '@apollo/client';

export const AUTO_BOOKING_LIST_FRAGMENT = gql`
  fragment AutoBookingListFragment on AutoBooking {
    id
    name
    isFrozen
    sportsCount
    leaguesCount
  }
`;

export const AUTO_BOOKING_MINI_FRAGMENT = gql`
  fragment AutoBookingMiniFragment on AutoBooking {
    id
    name
    sports {
      id
      name
      creationId
      regions {
        id
        name
        creationId
        leagues {
          id
          name
          creationId
        }
      }
    }
  }
`;

export const AUTO_BOOKING_FRAGMENT = gql`
  fragment AutoBookingFragment on AutoBooking {
    ...AutoBookingMiniFragment
    createdAt
    endDate
    indefinite
    isAutoBookingNewLeagues
    allowEditAutoBookingRule
    isFrozen
    timezone
    hours {
      id
      autoBookingId
      hours {
        friday {
          enabled
          hours {
            end
            start
          }
        }
        monday {
          enabled
          hours {
            end
            start
          }
        }
        saturday {
          enabled
          hours {
            start
            end
          }
        }
        sunday {
          enabled
          hours {
            start
            end
          }
        }
        thursday {
          enabled
          hours {
            start
            end
          }
        }
        tuesday {
          enabled
          hours {
            start
            end
          }
        }
        wednesday {
          hours {
            start
            end
          }
          enabled
        }
      }
    }
    countries {
      id
      country
      autoBookingId
      createdAt
      updatedAt
    }
    startDate
    type
    updatedAt
  }
  ${AUTO_BOOKING_MINI_FRAGMENT}
`;

export const PARENT_RULE_FRAGMENT = gql`
  fragment ParentRuleFragment on ParentRule {
    id
    name
    sports {
      id
      name
      creationId
      regions {
        id
        name
        creationId
        leagues {
          id
          name
          creationId
        }
      }
    }
    createdAt
    endDate
    indefinite
    isFrozen
    leaguesCount
    timezone
    hours {
      id
      autoBookingId
      hours {
        friday {
          enabled
          hours {
            end
            start
          }
        }
        monday {
          enabled
          hours {
            end
            start
          }
        }
        saturday {
          enabled
          hours {
            start
            end
          }
        }
        sunday {
          enabled
          hours {
            start
            end
          }
        }
        thursday {
          enabled
          hours {
            start
            end
          }
        }
        tuesday {
          enabled
          hours {
            start
            end
          }
        }
        wednesday {
          hours {
            start
            end
          }
          enabled
        }
      }
    }
    countries {
      id
      country
      autoBookingId
      createdAt
      updatedAt
    }
    startDate
    type
    updatedAt
  }
`;
