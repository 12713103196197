import { FC, memo, useState } from 'react';
import { toast } from 'react-toastify';
import qs from 'qs';
import { Icon } from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';

import { IExportButton } from './types';
import { StyledExport } from './styled';

const ExportButton: FC<IExportButton> = ({
  uri,
  params,
  fileName,
  label = 'Export'
}) => {
  // translations
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const handleExport = async () => {
    try {
      setLoading(true);
      const queryString = qs.stringify(params);

      const res = await fetch(`${uri}${queryString ? `?${queryString}` : ''}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to initiate the file download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // This attribute prompts the browser to download the file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the URL object
      window.URL.revokeObjectURL(url);
      toast.success(t('reports:successfully') as string);
    } catch {
      toast.error(t('reports:failed') as string);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledExport disabled={isLoading} onClick={handleExport}>
      <Icon type={isLoading ? 'spinner' : 'c-download'} />
      {label ?? ''}
    </StyledExport>
  );
};

export default memo(ExportButton);
