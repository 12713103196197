import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENT, USER_PROJECT_FRAGMENT } from './fragments';

export const GET_ORGANIZATION_INFO = gql`
  query OrganizationsInfo {
    organizationsInfo {
      data {
        ...OrganizationsInfoFragment
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const GET_USER_PROJECTS = gql`
  query UserProjects {
    userProjects {
      data {
        admin {
          ...UserProjectFragment
        }
        buyer {
          ...UserProjectFragment
          projectStatus
          bmeStatus
        }
        provider {
          ...UserProjectFragment
        }
      }
    }
  }
  ${USER_PROJECT_FRAGMENT}
`;
