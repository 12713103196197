import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEventsDispatchContext,
  useEventsSelectedStateContext
} from 'providers/Events';
import { Typography, Row, Col, NoData } from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { MATCH_STATUS_IDS, MatchTypesEnum } from 'constants/match';
import { useDayjs, usePermission } from 'hooks';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_MATCHES } from 'gql/events/queries';
import { useProjectStateContext } from 'providers/Projects';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';

import { EventsDataType, RouteParams } from './types';
import DateSection from '../DateSection';
import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';
import useEventsHistoryCache from 'hooks/useEventsHistoryCache';
import useEventsBookedCache from 'hooks/useEventsBookedCache';

import './index.css';
import * as permissionConstants from 'constants/permissions';

const { Text } = Typography;

const EventsContent: FC = () => {
  const { selectedProject } = useProjectStateContext();
  const [getAvailableMatches] = useLazyQuery(GET_AVAILABLE_MATCHES);
  // navigation
  const { type } = useParams() as RouteParams;
  // translations
  const { t } = useTranslation('events');

  const selected = useEventsSelectedStateContext();
  const { onBulkSelect } = useEventsDispatchContext();

  const { dayjs, eventFormat } = useDayjs();

  const { checkUserPermission } = usePermission();
  const hasBookPermission = useMemo(() => {
    return checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.list,
      permissionConstants.MANUAL_BOOK
    );
  }, [checkUserPermission]);

  const { results: upcoming } = useEventsUpcomingCache();
  const { results: history } = useEventsHistoryCache();
  const { results: booked } = useEventsBookedCache();

  const data = useMemo(() => {
    switch (type) {
      case MatchTypesEnum.list:
        return upcoming;
      case MatchTypesEnum.booked:
        return booked;
      case MatchTypesEnum.history:
        return history;
    }
  }, [type, upcoming, history, booked]);

  const events = useMemo(() => {
    const grouped: EventsDataType = {};

    data?.forEach(
      (item: {
        startTime: string | number | Date | Dayjs | null | undefined;
      }) => {
        const key = dayjs(item.startTime).format(`DD MMM YYYY ${eventFormat}`);

        if (grouped[key]) {
          grouped[key].push(item);
        } else {
          grouped[key] = [item];
        }
      }
    );

    return grouped;
  }, [data, dayjs, eventFormat]);

  const setSpacings = useCallback(
    (listSpacing: number, bookedSpacing: number, historySpacing: number) => {
      if (type === MatchTypesEnum.list) {
        return listSpacing;
      } else if (type === MatchTypesEnum.booked) {
        return bookedSpacing;
      }

      return historySpacing;
    },
    [type]
  );

  const onClickBulkAction = useCallback(
    (key: string) => {
      const group = events[key];
      const isAuthOfPackage = group.some(item => item.isOutOfPackage);

      if (isAuthOfPackage) {
        toast.error(`${t('cart:some_out_of_package')}`);

        return;
      }

      if (!hasBookPermission) {
        toast.error(`${t('cart:no_book_permission')}`);

        return;
      }

      let ids: string[] = [];

      switch (type) {
        case MatchTypesEnum.booked:
          ids = group
            .filter(item => item?.status !== MATCH_STATUS_IDS.live)
            .map(el => el.id);
          onBulkSelect(ids, type);
          break;
        case MatchTypesEnum.list:
          ids = group.map(item => item.id);
          getAvailableMatches({
            variables: {
              query: {
                matchIds: ids,
                organizationId:
                  localStorage.getItem('projectId') || selectedProject?.id
              }
            }
          })
            .then(res => {
              if (res?.data?.getAvailableMatches.data) {
                onBulkSelect(ids, type);
              } else {
                toast.error(`${t('common:package_frozen')}`);
              }
            })
            .catch(() => toast.error(`${t('common:something_went_wrong')}`));
          break;
      }
    },
    [events, type, onBulkSelect, getAvailableMatches, selectedProject?.id, t]
  );

  return (
    <div className="full-height">
      {data?.length ? (
        <>
          <Row className="ph--4 mb--12">
            {type !== MatchTypesEnum.history && (
              <Col
                span={setSpacings(0.5, 0.5, 0.45)}
                className="header-item pv--16 mr--4 pl--12 left-border"
              >
                <Text level={13}>{t('common:play')}</Text>
              </Col>
            )}
            <Col
              span={setSpacings(
                2.08,
                2.03,
                2.75 - (MatchTypesEnum.history ? 0.28 : 0)
              )}
              className={`header-item pv--16 mr--4 pl--12 ${
                type === MatchTypesEnum.history && 'left-border'
              }`}
            >
              <Text level={13}>{t('time')}</Text>
            </Col>
            <Col
              span={setSpacings(
                2.58,
                2.52,
                2.8 - (MatchTypesEnum.history ? 0.28 : 0)
              )}
              className="header-item pv--16 mr--4 pl--12"
            >
              <Text level={13}>{t('team', { count: 1 })}</Text>
            </Col>
            <Col
              span={setSpacings(
                2.55,
                2.5,
                2.8 - (MatchTypesEnum.history ? 0.28 : 0)
              )}
              className="header-item pv--16 mr--4 pl--12"
            >
              <Text level={13}>{t('team', { count: 2 })}</Text>
            </Col>
            <Col
              span={setSpacings(
                1.36,
                1.3,
                1.6 - (MatchTypesEnum.history ? 0.28 : 0)
              )}
              className="header-item pv--16 mr--4 pl--12"
            >
              <Text level={13}>{t('region')}</Text>
            </Col>
            <Col
              span={setSpacings(
                2.81,
                1.35,
                1.65 - (MatchTypesEnum.history ? 0.28 : 0)
              )}
              className={`header-item pv--16 mr--4 pl--12 ${
                type === MatchTypesEnum.list && 'right-border'
              }`}
            >
              <Text level={13}>{t('league')}</Text>
            </Col>
            {(type === MatchTypesEnum.booked ||
              type === MatchTypesEnum.history) && (
              <Col
                span={setSpacings(
                  2,
                  1.7,
                  1.8 - (MatchTypesEnum.history ? 0.11 : 0)
                )}
                className="header-item pv--16 pl--12 right-border"
              >
                <Text level={13}>{t('booking_type')}</Text>
              </Col>
            )}
          </Row>
          {Object.keys(events).map(key => {
            const group = events[key];
            const isAuthOfPackage = group.some(item => item.isOutOfPackage);
            const isSectionSelected =
              !isAuthOfPackage &&
              group.every(item => selected[type].includes(item.id));

            return (
              <DateSection
                date={key}
                group={group}
                key={`match-group-${key}`}
                isSectionSelected={isSectionSelected}
                onClickBulkAction={onClickBulkAction}
              />
            );
          })}
        </>
      ) : (
        <NoData size="full" text={t('no_data')} transparent={false} />
      )}
    </div>
  );
};

export default EventsContent;
