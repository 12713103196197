import { FC, memo, useEffect, useState } from 'react';
import {
  Aside,
  Input,
  Tabs,
  Tab,
  Button
} from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { SidebarKeys } from 'providers/UI/types';
import { useSidebarStateContext, useUIDispatchContext } from 'providers/UI';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectEventsFilters,
  selectHasFilters
} from 'store/slice/filters/events/selector';
import {
  initEventsFilters,
  resetEventsFilters,
  resetEventsName,
  setEventsFilterItem
} from 'store/slice/filters';
import { useParams } from 'react-router-dom';
import { MatchTypesEnum } from 'constants/match';

import SavePreset from '../SavePreset';
import { StyledFilters } from './styled';
import PresetsTab from '../PresetsTab';
import { RouteParams } from '../Content/types';
import useEventsFilters from 'hooks/useEventsFilter';
import FilterEventsSidebarCheckbox from '../FilterEventsSidebarCheckbox';
import DateRangePicker from './components/DateRangePicker';
import SelectSport from './components/SelectSport';
import SelectRegion from './components/SelectRegion';
import SelectLeague from './components/SelectLeague';

const FILTERS_ID = 'filters-tab';
const PRESETS_ID = 'presets-tab';

const ListFiltersSidebar: FC = () => {
  const { type } = useParams() as RouteParams;
  // translations
  const { t } = useTranslation('events');
  // hooks
  const { presetFiltersNames } = useEventsFilters();
  // context
  const { toggleSidebar } = useUIDispatchContext();
  const {
    [SidebarKeys['filters']]: { visible: isOpen }
  } = useSidebarStateContext();

  // redux
  const dispatch = useAppDispatch();
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const hasFilters = useAppSelector(selectHasFilters(type));
  // states
  const [selectedTab, setSelectedTab] = useState(FILTERS_ID);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs(eventsFilters.dates.startDate).local().toDate(),
    dayjs(eventsFilters.dates.endDate).toDate()
  ]);

  useEffect(() => {
    dispatch(initEventsFilters({ type: MatchTypesEnum[type] }));
    dispatch(resetEventsName({ type: MatchTypesEnum[type] }));
  }, [dispatch, type]);

  useEffect(() => {
    if (!presetFiltersNames.length) {
      setSelectedTab(FILTERS_ID);
    }
  }, [presetFiltersNames.length]);

  useEffect(() => {
    setDateRange([
      dayjs(eventsFilters.dates.startDate).local().toDate(),
      dayjs(eventsFilters.dates.endDate).toDate()
    ]);
  }, [eventsFilters.dates]);

  return (
    <Aside
      isOpen={isOpen}
      onChange={val => {
        toggleSidebar(SidebarKeys.filters, val);
      }}
    >
      <StyledFilters>
        <Tabs
          color="primary"
          selectedTabId={selectedTab}
          onChange={e => setSelectedTab(`${e}`)}
        >
          <Tab id={FILTERS_ID} text="Filter">
            <div>
              <div className="flex-display flex-direction-column gap--40">
                <Input
                  label={t('search')}
                  placeholder={t('type_team_name')}
                  labelAppearance="title"
                  cornerRadius="smooth"
                  value={eventsFilters.name}
                  onChange={e => {
                    const value = e.target.value;

                    // Only dispatch if the value does not start with a space
                    if (value === '' || value[0] !== ' ') {
                      dispatch(
                        setEventsFilterItem({
                          name: 'name',
                          value: value.trimStart(),
                          type
                        })
                      );
                    }
                  }}
                />
                {/* <Select
            label={t('autobooking_rule')}
            placeholder={t('select_rule')}
            options={[]}
            onChange={value => {
              setFilterItem({
                module: FilterModules.eventsFilters,
                name: 'auto_booking',
                data: value
              });
            }}
            onScroll={() => null}
            onSearch={() => null}
            searchValue={''}
            cornerRadius="smooth"
            mode="multiple"
          /> */}
                <DateRangePicker
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                />
                <SelectSport />
                <SelectRegion />
                <SelectLeague />
                <FilterEventsSidebarCheckbox />
              </div>
              <div className="mt--16">
                <SavePreset
                  changeToPresets={() => setSelectedTab(PRESETS_ID)}
                />
                <Button
                  cornerRadius="smooth"
                  color="contrastNew"
                  disabled={!hasFilters}
                  onClick={() => {
                    dispatch(resetEventsFilters({ type }));
                  }}
                >
                  {t('common:reset')}
                </Button>
              </div>
            </div>
          </Tab>
          {!!presetFiltersNames.length && (
            <Tab id={PRESETS_ID} text={t('common:list_preset')}>
              <PresetsTab
                changeToFilters={() => setSelectedTab(FILTERS_ID)}
                id={PRESETS_ID}
              />
            </Tab>
          )}
        </Tabs>
      </StyledFilters>
    </Aside>
  );
};

export default memo(ListFiltersSidebar);
