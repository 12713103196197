import { InMemoryCache, ApolloClient, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import config from 'config';
import { v4 as uuid } from 'uuid';
import DebounceLink from 'apollo-link-debounce';
import { setContext } from '@apollo/client/link/context';

import { parseJson } from 'utils/object';

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        settings: {
          read(settings) {
            return parseJson(settings);
          }
        }
      }
    }
  }
});

const graphql_uri = config.graphql_url + '/graphql';

const authMiddleware = setContext((req, prev) => {
  let sessionId = localStorage.getItem('sessionId');

  if (!sessionId) {
    sessionId = uuid();
    localStorage.setItem('sessionId', sessionId);
  }

  const token = localStorage.getItem('token');

  const headers = { session_id: sessionId, ...(prev?.headers || {}) };

  return {
    headers: token
      ? {
          authorization: `Bearer ${token}`,
          ...headers
        }
      : { ...headers }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('GRAPHQL_ERROR__', graphQLErrors, '__GRAPHQL_ERROR');
  console.log('NETWORK_ERROR__', networkError, '__NETWORK_ERROR');
});

const link = from([
  errorLink,
  authMiddleware,
  new DebounceLink(0),
  new HttpLink({ uri: graphql_uri })
]);

const client = new ApolloClient({
  cache,
  link
});

export default client;
