import styled from '@shared_medialab/ui_components';

export const StyledCopy = styled.div`
  opacity: 0;
`;

export const StyledEmailsContainer = styled.div`
  z-index: 999;
  &:hover {
    padding-right: 0;
    ${StyledCopy} {
      opacity: 1;
    }
  }
`;
