import { gql } from '@apollo/client';

import { BUYER_USER_FRAGMENT } from './fragments';

export const GET_BUYER_USERS = gql`
  query BuyerUsers($organizationId: ID!, $query: GetUsersInput!) {
    buyerUsers(id: $organizationId, query: $query) {
      data {
        results {
          ...BuyerUserFragment
        }
        total
      }
    }
  }
  ${BUYER_USER_FRAGMENT}
`;

export const GET_BUYER_USER_BY_ID = gql`
  query BuyerUserById($query: GetOrganizationUserById) {
    buyerUserById(query: $query) {
      data {
        ...BuyerUserFragment
      }
    }
  }
  ${BUYER_USER_FRAGMENT}
`;
