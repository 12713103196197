import styled from '@shared_medialab/ui_components';

import { IStyledButton } from './types';

export const StyledButton = styled.button<IStyledButton>`
  outline: none;
  border-radius: 4px;
  background: var(--Orange__6);
  transition: all 0.3s linear;
  &:hover {
    background: var(--amber-glow);
  }
`;
