import { useLazyQuery, useMutation } from '@apollo/client';
import { Row, Col, Screen } from '@shared_medialab/ui_components';
import client from 'apolloClient';
import {
  CREATE_AUTO_BOOKING,
  UPDATE_AUTO_BOOKING
} from 'gql/autobooking/mutations';
import {
  GET_AUTO_BOOKING_RULES_ORGANIZATION,
  GET_AUTO_BOOKING_RULE_MINI
} from 'gql/autobooking/queries';
import {
  useAutoBookingButtonStateContext,
  useAutoBookingStateContext
} from 'providers/AutoBooking';
import { FieldsValue } from 'providers/AutoBooking/types';
import { useProjectStateContext } from 'providers/Projects';
import { useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectedType } from '@shared_medialab/ui_components/build/base/Collapse/types';
import { useWatch } from 'react-hook-form';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';

import { Content, Settings, Sports } from './components';
import { UserEnum } from 'pages/UserManagement/constants';

const AutoBookingDetails: FC = () => {
  // translations
  const { t } = useTranslation(['common']);
  // navigation
  const { id } = useParams();
  const navigate = useNavigate();

  // context
  const { toggleModal } = useUIDispatchContext();
  const { selectedProject } = useProjectStateContext();
  const { handleSubmit, getValues, setValue, control } =
    useAutoBookingStateContext();

  const isSubmitDisabled = useAutoBookingButtonStateContext();
  const sports = useWatch({
    control,
    name: 'sport'
  });

  const selectedSportsCheck = useWatch({
    control,
    name: 'selectedSportsCheck'
  });

  const selectedSports = useWatch({
    control,
    name: 'selectedSports'
  });

  const selectedSportsCount = useWatch({
    control,
    name: 'selectedSportsCount'
  });

  const selectedRegionsOnSport = useWatch({
    control,
    name: 'selectedRegionsOnSport'
  });

  const completelyRegions = useWatch({
    control,
    name: 'completelyRegions'
  });
  //TODO: bug related to Time and allowEditAutoBookingRule

  // const end = useWatch({
  //   control,
  //   name: 'endDate'
  // });

  // const allowEditAutoBookingRule = useWatch({
  //   control,
  //   name: 'allowEditAutoBookingRule'
  // });

  const parentRule = useWatch({
    control,
    name: 'parentRule'
  });

  const isAll = useWatch({
    control,
    name: 'isAll'
  });

  // graphql
  const [getUserData, { data: settingsData }] =
    useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  // graphql
  const [createAutoBooking, { loading }] = useMutation(CREATE_AUTO_BOOKING, {
    onCompleted() {
      client.refetchQueries({ include: [GET_AUTO_BOOKING_RULES_ORGANIZATION] });
      toggleModal(ModalKeys.autoBooking, false);
      navigate('/streaming/events/autobooking');
    },
    onError() {
      toast.error('Something went wrong');
    }
  });

  const [updateAutoBooking, { loading: updateLoading }] = useMutation(
    UPDATE_AUTO_BOOKING,
    {
      onCompleted() {
        navigate('/streaming/events/autobooking');
      },
      onError() {
        toast.error('Something went wrong');
      }
    }
  );

  const onSubmit = (fields: FieldsValue) => {
    fields.days && delete fields.days.all;

    const days =
      fields.days &&
      Object.entries(fields.days).reduce((acc, item) => {
        const [key, val] = item;

        return {
          ...acc,
          [key]: {
            enabled: val,
            hours: [{ start: fields.startTime, end: fields.endTime }]
          }
        };
      }, {});

    let data = {};

    if (parentRule.selectedSports) {
      const parentInitial = Object.values(parentRule.selectedSports)
        .map(elem => elem.leagueIds)
        .flat();

      const parentSelected = Object.values(selectedSports)
        .map(elem => elem.leagueIds)
        .flat();

      const whichLeaguesUnchecked = parentInitial.filter(
        elem => !parentSelected.includes(elem)
      );

      const whichCountriesUnchecked = parentRule.countries.filter(
        elem => !fields.countries.includes(elem)
      );

      data = {
        name: fields.name,
        countries: fields.countries,
        hours: JSON.stringify(days),
        endDate: fields.endDate,
        startDate: fields.startDate,
        indefinite: fields.indefinite,
        leagues: Object.values(selectedSports),
        timezone: settingsData?.me?.data.settings.timezone,
        packages: [],
        organizationId: selectedProject?.id,
        isAutoBookingNewLeagues: fields.isAutoBookingNewLeagues,
        isPackage: false,
        unCheckedLeagues: whichLeaguesUnchecked,
        unCheckedCountries: whichCountriesUnchecked
      };
    } else {
      data = {
        name: fields.name,
        countries: fields.countries,
        hours: JSON.stringify(days),
        endDate: fields.endDate,
        startDate: fields.startDate,
        indefinite: fields.indefinite,
        leagues: Object.values(selectedSports),
        timezone: settingsData?.me?.data.settings.timezone,
        packages: [],
        organizationId: selectedProject?.id,
        isAutoBookingNewLeagues: fields.isAutoBookingNewLeagues,
        isPackage: false
      };
    }

    if (id) {
      updateAutoBooking({
        variables: {
          input: data,
          id
        }
      }).then(() => {
        client.refetchQueries({
          include: [GET_AUTO_BOOKING_RULE_MINI]
        });
      });
    } else {
      createAutoBooking({
        variables: {
          input: data
        }
      });
    }
  };

  const onRegionChange = useCallback(
    (val: SelectedType) => {
      setValue('selectedSportsCheck', val);
      const removeVal = Object.values(selectedSportsCheck).flatMap(elem =>
        elem.filter(subarray => !Object.values(val).flat().includes(subarray))
      );

      const alreadySelected = selectedSports[sports]
        ? selectedSports[sports].leagueIds
        : [];

      const addVal = parentRule
        ? Object.values(val).flatMap(elem =>
            elem.filter(
              subarray =>
                !Object.values(selectedSportsCheck).flat().includes(subarray)
            )
          )
        : [];

      const isFirst =
        addVal.length > 0
          ? addVal.length + alreadySelected.length
          : alreadySelected.length;

      setValue('selectedSportsCount', {
        ...getValues().selectedSportsCount,
        [sports]: [isFirst - removeVal.length]
      });
      setValue('selectedSports', {
        ...getValues().selectedSports,
        [sports]: {
          sportId: sports,
          leagueIds: addVal.length
            ? [...Object.values(addVal).flat(), ...alreadySelected]
            : alreadySelected.filter(element => !removeVal.includes(element)),
          allSelectedRegions: [...completelyRegions],
          isAll: isAll
        }
      });
    },
    [getValues, setValue, sports, completelyRegions, isAll]
  );

  const onAllChange = useCallback(
    (val: boolean) => {
      if (val) {
        setValue('isAll', val);
      }

      setValue('isAll', val);
    },
    [setValue]
  );

  const onSportChange = useCallback(
    (val: string) => {
      setValue('sport', val);

      if (selectedSports && selectedSports[val] && selectedSports[val].isAll) {
        onAllChange(selectedSports[val].isAll || false);
      } else onAllChange(false);
    },
    [onAllChange, selectedSports, setValue]
  );

  return (
    <Screen
      header={{
        title: 'Back',
        onBack: () => navigate(`/streaming/events/autobooking`)
      }}
      footer={{
        button: {
          title: id ? t('save') : t('create'),
          color: 'confirmNew',
          flexibility: 'biggest-width',
          cornerRadius: 'smooth',
          onClick: handleSubmit(onSubmit),
          loading: loading || updateLoading,
          disabled: isSubmitDisabled
        }
      }}
    >
      <Row gutter={[8, 0]}>
        <Col span={3}>
          <Settings />
        </Col>
        <Col span={3}>
          <Sports
            onChange={val => onSportChange(val as string)}
            value={sports}
            selectedSportsCount={selectedSportsCount || 0}
            setRegionValue={setValue}
            parentRule={parentRule}
          />
        </Col>
        <Col span={6}>
          <Content
            onChange={onRegionChange}
            sportId={sports}
            initialValues={selectedSportsCheck}
            initialRegion={selectedRegionsOnSport}
            initialSubRegions={selectedSports}
            setRegionValue={setValue}
            isAll={isAll}
            setIsAll={onAllChange}
            parentRule={parentRule.sports}
          />
        </Col>
      </Row>
    </Screen>
  );
};

export default AutoBookingDetails;
