import { useQuery } from '@apollo/client';
import {
  Card,
  CollapseNew,
  Input,
  Loading,
  NoData,
  Select
} from '@shared_medialab/ui_components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPORTS_FOR_PACKAGE } from 'gql/contentPackages/queries';
import { useParams } from 'react-router-dom';
import { useRegions } from 'hooks';
import { SelectedType } from '@shared_medialab/ui_components/build/base/Collapse/types';
import { useAutoBookingStateContext } from 'providers/AutoBooking';
import { useWatch } from 'react-hook-form';
import { useProjectStateContext } from 'providers/Projects';
import { Sports } from 'gql/contentPackages/types/Sports';

import { IRegionSelectProps } from './types';

const Content: FC<IRegionSelectProps> = ({
  onChange,
  sportId,
  initialValues,
  initialSubRegions,
  initialRegion,
  setRegionValue,
  parentRule,
  isAll,
  setIsAll
}) => {
  // navigation
  const { id } = useParams();
  //context
  const { selectedProject } = useProjectStateContext();

  // translations
  const { t } = useTranslation('auto_booking');
  // states
  const [selectedRegions, setRegions] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [completelyRegions, setCompletelyRegions] = useState<string[]>([]);

  // hooks
  const { regions, regionSearch, onRegionSearch } = useRegions({
    sportIds: []
  });

  const { setValue, control } = useAutoBookingStateContext();

  const countries = useWatch({
    control,
    name: 'countries'
  });

  //graphql
  const { data, loading } = useQuery<Sports>(GET_SPORTS_FOR_PACKAGE, {
    fetchPolicy: 'no-cache',
    skip: !sportId && !selectedRegions.length,
    variables: {
      query: {
        regions: selectedRegions,
        sports: [sportId],
        leagueName: search,
        isContentGroup: true,
        bookingCountries: countries,
        organizationId: selectedProject?.id && +selectedProject?.id,
        autoBookingRuleId: id
      }
    },
    context: {
      debounceKey: 'sportsForPackage',
      debounceTimeout: 700
    }
  });

  const initialRegionsSubscription = useMemo(
    () => data?.sports?.data.results[0]?.initialRegionsSubscription || [],
    [data?.sports?.data.results]
  );

  const disabledRegionsSubscription = useMemo(() => {
    return data?.sports?.data.results[0].disabledRegionsSubscription?.filter(
      elem => !initialRegionsSubscription?.includes(elem)
    );
  }, [data?.sports?.data.results]);

  const onSearch = (text: string) => {
    setTimeout(() => {
      setSearch(text);
    }, 700);
  };

  const leaguesParentRule = parentRule?.filter(
    elem => elem.creationId === sportId
  )[0]?.regions;

  const regionName = leaguesParentRule?.map(elem => elem.name);
  const alreadyUsedLeagueIds = useMemo(() => {
    const alreadyUsed =
      data?.sports?.data.results?.[0].alreadyUsedLeagueIds || [];

    let alreadyUsedOnEdit: string[] = [];
    let alreadyUsedOnCreate: string[] = [];

    Object.values(alreadyUsed).forEach(elem => {
      if (elem.autobookingId !== id) {
        alreadyUsedOnEdit = [...alreadyUsedOnEdit, elem.leagueId];
      } else {
        alreadyUsedOnCreate = [...alreadyUsedOnCreate, elem.leagueId];
      }
    });

    return alreadyUsedOnEdit;
  }, [data?.sports?.data.results, id]);

  const sportRegions = regionName
    ? data?.sports?.data.results?.[0]?.regions.filter(elem =>
        regionName?.includes(elem.name)
      ) || []
    : data?.sports?.data.results?.[0]?.regions || [];

  const regionLeagues = leaguesParentRule
    ?.map(elem => elem.leagues)
    .map(subList => subList?.map(item => item?.id));

  const filteredData = sportRegions?.map(region => ({
    ...region,
    leagues: leaguesParentRule
      ? region.leagues?.filter(league =>
          regionLeagues?.some(ids => ids?.includes(league?.id))
        )
      : region.leagues
  }));

  const onValueChange = useCallback(
    (val: SelectedType) => {
      const clearedVal = Object.keys(val).reduce((result, key) => {
        if (val[key].length > 0) {
          result[key] = val[key] as string[];
        }

        return result;
      }, {} as SelectedType);

      const leagues = Object.values(clearedVal).map(arr => arr.length);
      const selectedCount = leagues.length
        ? leagues.reduce((acc, value) => acc + value)
        : 0;

      const filteredVal = Object.fromEntries(
        Object.entries(clearedVal).filter(([, value]) => value.length > 0)
      );

      onChange(filteredVal, selectedCount);
    },
    [onChange]
  );

  useEffect(() => {
    if (initialRegion) {
      setRegions(initialRegion);
    }
  }, [initialRegion]);

  const initialAdded = useMemo(() => {
    if (
      initialSubRegions &&
      initialSubRegions[sportId || 0]?.allSelectedRegions
    ) {
      return initialSubRegions[sportId || 0].allSelectedRegions || [];
    } else return [];
  }, [initialSubRegions, sportId]);

  useEffect(() => {
    setValue('completelyRegions', completelyRegions);
    isAll && setValue('isAll', isAll);
  }, [completelyRegions, initialRegion, setValue, isAll]);

  return (
    <Card
      header={{
        title: t('select_content')
      }}
      bodyStyle={{
        height: 'calc(100vh - 172px)',
        padding: '5px',
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      <div className="flex-display flex-direction-column gap--12 full-height">
        <div className="flex-display flex-justify-end full-width gap--4">
          <Input
            type="searchRight"
            onChange={e => onSearch(e.target.value)}
            cornerRadius="smooth"
            placeholder="Search"
            size="default"
          />
          <Select
            icon="public"
            options={regions.map(item => ({
              label: item.name,
              value: item.creationId
            }))}
            value={selectedRegions}
            onChange={value => {
              setRegions(value);
              setRegionValue &&
                setRegionValue('selectedRegionsOnLeagues', value);
            }}
            onSearch={e => onRegionSearch(e.target.value)}
            searchValue={regionSearch}
            cornerRadius="smooth"
            mode="multiple"
            disabled={!!initialRegion?.length}
          />
        </div>
        {loading ? (
          <Loading />
        ) : !data?.sports?.data.results.length ? (
          <div className="flex-display full-height flex-align-self-center">
            <NoData
              size="default"
              image="Select"
              transparent
              text={t('no_data')}
            />
          </div>
        ) : (
          <>
            {parentRule?.length ? (
              <CollapseNew
                data={filteredData}
                keys={['leagues']}
                showIcon={false}
                collapsible
                checkable
                maxHeight="calc(100vh - 270px)"
                valueKey="creationId"
                onChange={onValueChange}
                initialValues={initialValues}
                showAll={sportRegions.length > 1}
                setValue={setCompletelyRegions}
                disabledLeagues={alreadyUsedLeagueIds}
                onDisableHover={t('already_used')}
              />
            ) : (
              <CollapseNew
                data={filteredData}
                keys={['leagues']}
                showIcon={false}
                collapsible
                checkable
                maxHeight="calc(100vh - 270px)"
                valueKey="creationId"
                onChange={onValueChange}
                initialValues={initialValues}
                showAll={sportRegions.length > 1}
                setValue={setCompletelyRegions}
                isAll={isAll}
                setIsAll={setIsAll}
                disabledLeagues={alreadyUsedLeagueIds}
                initialSubscribed={
                  [...initialRegionsSubscription, ...initialAdded] || []
                }
                alreadySubscribedAnother={disabledRegionsSubscription || []}
                onDisableHover={t('already_used')}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default Content;
