export type IStyledConfirmModalProps = {
  isVisible: boolean;
};

export enum ConfirmModalTypes {
  confirm = 'confirm',
  danger = 'danger',
  warning = 'warning',
  dangerNew = 'dangerNew',
  primaryNew = 'primaryNew',
  contrastNew = 'contrastNew'
}
export interface IConfirmProps {
  reason?: boolean;
}
export interface IStyledMassageProps {
  massageColor?: boolean;
}
