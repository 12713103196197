import { gql } from '@apollo/client';

import { CONTENT_PACKAGES_FRAGMENT } from './fragments';

export const GET_CONTENT_PACKAGES = gql`
  query ContentPackages($query: GetContentGroups!) {
    contentPackages(query: $query) {
      data {
        results {
          ...ContentPackagesFragment
        }
        total
      }
    }
  }
  ${CONTENT_PACKAGES_FRAGMENT}
`;

export const GET_SPORTS_FOR_PACKAGE = gql`
  query Sports($query: SportsQuery) {
    sports(query: $query) {
      data {
        results {
          id
          name
          creationId
          alreadyUsedLeagueIds {
            autobookingId
            leagueId
          }
          initialRegionsSubscription
          disabledRegionsSubscription
          regions {
            id
            creationId
            name
            leagues {
              id
              name
              creationId
            }
          }
        }
      }
    }
  }
`;
export const CHECK_AUTOBOOKING_NAME = gql`
  query CheckAutobookingName($query: CheckAutoBookingNameInput!) {
    checkAutobookingName(query: $query) {
      data
    }
  }
`;
