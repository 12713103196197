import { FC } from 'react';
import dayjs from 'dayjs';
import { DateRangePickerNew } from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setEventsFilterItem } from 'store/slice/filters';
import { MatchTypesEnum } from 'constants/match';
import { useAppDispatch } from 'store/hooks';

import { RouteParams } from '../../../Content/types';
import { DateRangePickerProps } from './types';
import { getStaticRangesArray } from 'utils/dates';

const DateRangePicker: FC<DateRangePickerProps> = ({
  setDateRange,
  dateRange
}) => {
  const { type } = useParams() as RouteParams;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('events');

  return (
    <DateRangePickerNew
      title={t('dates')}
      key={`${dateRange[0]}-${dateRange[1]}`}
      dateRange={dateRange}
      staticRanges={[
        {
          label: t('dates:today'),
          value: getStaticRangesArray('day')
        },
        {
          label: t('dates:this_week'),
          value: getStaticRangesArray('week')
        },
        {
          label: t('dates:this_month'),
          value: getStaticRangesArray('month')
        },
        {
          label: t('dates:this_year'),
          value: getStaticRangesArray('year')
        }
      ]}
      onChange={value => {
        const newDateRange: [Date | null, Date | null] = [
          dayjs(value.startDate).local().toDate(),
          dayjs(value.endDate).toDate()
        ];

        setDateRange(newDateRange);

        dispatch(
          setEventsFilterItem({
            name: 'dates',
            value: {
              startDate: dayjs(value.startDate).startOf('day').format(),
              endDate: dayjs(value.endDate).endOf('day').format()
            },
            type
          })
        );
      }}
      minDate={
        type !== MatchTypesEnum.history
          ? dayjs().add(-1, 'hour').toDate()
          : undefined
      }
      maxDate={
        type !== MatchTypesEnum.history
          ? dayjs().add(1, 'month').toDate()
          : undefined
      }
    />
  );
};

export default DateRangePicker;
