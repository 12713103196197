import { ColumnType } from '@shared_medialab/ui_components/build/base/Table/types';
import { BuyerLocationReports_buyerLocationReports_data_results as LocationReportsType } from 'gql/reports/types/BuyerLocationReports';
import { BuyerOsReports_buyerOsReports_data_results as OsReportsType } from 'gql/reports/types/BuyerOsReports';
import { BuyerSportsReports_buyerSportsReports_data_results as SportsReportsType } from 'gql/reports/types/BuyerSportsReports';
import { BuyerViewReports_buyerViewReports_data_results as ViewReportsType } from 'gql/reports/types/BuyerViewReports';
import { BuyerSessionsReports_buyerSessionsReports_data_results as SessionsReportsType } from 'gql/reports/types/BuyerSessionsReports';
import { BuyerMatchReports_buyerMatchReports_data_results as MatchReportsType } from 'gql/reports/types/BuyerMatchReports';

export const MATCH_COLUMNS: ColumnType<MatchReportsType>[] = [
  {
    name: 'name',
    label: 'Event ID'
  },
  {
    name: 'dimension_event',
    label: 'Event name'
  },
  {
    name: 'started_at',
    label: 'Event start date'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions'
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  }
];
export const OVER_VIEW_COLUMNS: ColumnType<SessionsReportsType>[] = [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'average_view_bandwidth_consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'average_view_duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'total_sessions',
    label: 'dimension_event'
  },
  {
    name: 'total_unique_views',
    label: 'started_at'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'total_sessions',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'total_views_duration',
    label: 'total_unique_views',
    render: record => record.total_views_duration.formattedValue
  }
];
export const UNIQUE_VIEW_COLUMNS: ColumnType<ViewReportsType>[] = [
  {
    name: 'range',
    label: 'Range'
  },
  {
    name: 'total_unique_views',
    label: 'Total views'
  }
];

export const POPULARITY_SPORTS_COLUMNS: ColumnType<SportsReportsType>[] = [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'dimension_sport',
    label: 'Dimension sport'
  },
  {
    name: 'sport_id',
    label: 'Sport id'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue
  }
];
export const OS_COLUMNS: ColumnType<OsReportsType>[] = [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'dimension_device',
    label: 'Dimension device'
  },
  {
    name: 'id',
    label: 'Id'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue
  }
];
export const LOCATION_COLUMNS: ColumnType<LocationReportsType>[] = [
  {
    name: 'dimension_country',
    label: 'Dimension country'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views'
  },
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'total_sessions',
    label: 'Total sessions'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue
  }
];

export const PAGE_SIZE = 5;
export const EVENTS_PAGE_SIZE = 15;
