import * as permissionConstants from 'constants/permissions';
import { usePermissionsStateContext } from 'providers/Auth';
import { useCallback } from 'react';

import { checkPermission } from 'utils/permission';

const usePermission = () => {
  // context
  const permissions = usePermissionsStateContext();

  const checkUserPermission = useCallback(
    (routeId: number, routeKey: string) => {
      const value = permissionConstants.PERMISSION_ROUTES[routeId][routeKey];
      const permission = permissions[routeId];

      return !!permission && checkPermission(value, permission);
    },
    [permissions]
  );

  return { checkUserPermission };
};

export default usePermission;
