export const CHANNEL = {
  ADD_MATCH_TO_UPCOMING: 'addMatchToUpcoming',
  UPDATE_MATCH_IN_UPCOMING: 'updateMatchInUpcoming',
  DELETE_MATCH_FROM_UPCOMING: 'deleteMatchFromUpcoming',
  ADD_MATCH_TO_HISTORY: 'addMatchToHistory',
  UPDATE_MATCH_IN_HISTORY: 'updateMatchInHistory',
  DELETE_MATCH_FROM_HISTORY: 'deleteMatchFromHistory',
  ADD_MATCH_TO_BOOKED: 'addMatchToBooked',
  UPDATE_MATCH_IN_BOOKED: 'updateMatchToBooked',
  DELETE_MATCH_FROM_BOOKED: 'deleteMatchFromBooked',
  STREAM_AUTH_STATUS: 'streamAuthStatus',
  ADD_ORGANIZATION: 'addOrganization',
  DEACTIVATE_ORGANIZATION: 'deactivateOrganization',
  UPDATE_ORGANIZATION: 'updateOrganization',
  UPDATE_USER: 'updateUser',
  UPDATE_USER_GENERAL_STATUS: 'updateUserGeneralStatus',
  UPDATE_USER_ROLE_STATUS: 'updateUserRoleStatus',
  UPDATE_USER_PROJECTS: 'updateUserProjects',
  UPDATE_SHOPPING_CART: 'updateShoppingCart',
  CONNECTION: 'connection',
  DISCONNECT: 'disconnect',
  ERROR: 'error'
};
