import { useState, MouseEvent } from 'react';

type UseEllipsisHook = {
  hover: boolean;
  hasEllipses: boolean;
  handleMouseEnter: (e: MouseEvent<HTMLParagraphElement>) => void;
  handleMouseLeave: () => void;
};

const useEllipsis = (): UseEllipsisHook => {
  const [hover, setHover] = useState(false);
  const [hasEllipses, setHasEllipses] = useState(false);

  const checkEllipsis = (element: HTMLParagraphElement | null) => {
    if (element && element.offsetWidth < element.scrollWidth) {
      setHasEllipses(true);
    } else {
      setHasEllipses(false);
    }
  };

  const handleMouseEnter = (e: MouseEvent<HTMLParagraphElement>) => {
    const target = e.currentTarget;
    checkEllipsis(target);
    setHover(true);
  };

  const handleMouseLeave = () => setHover(false);

  return {
    //use hover and hasEllipses for condition show Tooltip
    hover,
    hasEllipses,
    //use handleMouseEnter for onMouseEnter event
    handleMouseEnter,
    //use handleMouseLeave for onMouseLeave event
    handleMouseLeave
  };
};

export default useEllipsis;
