import { Cart_cart_data_matches_availableCountries as CountryPricesType } from 'gql/cart/types/Cart';

import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        paging: {
          ...state.paging,
          loading: action.data
        }
      };

    case ActionTypes.SET_CART_LOADING:
      return { ...state, cartLoading: action.data };

    case ActionTypes.ON_SELECT: {
      const { id, type } = action.data;

      return {
        ...state,
        selected: { ...state.selected, [type]: [...state.selected[type], id] }
      };
    }

    case ActionTypes.ON_SELECT_BULK: {
      const { IDs, type } = action.data;

      return {
        ...state,
        selected: {
          ...state.selected,
          [type]: IDs
        }
      };
    }

    case ActionTypes.UN_SELECT: {
      const { id, type } = action.data;

      return {
        ...state,
        selected: {
          ...state.selected,
          [type]: state.selected[type].filter(item => item !== id)
        }
      };
    }

    case ActionTypes.UN_SELECT_ITEMS: {
      const { ids, type } = action.data;

      return {
        ...state,
        selected: {
          ...state.selected,
          [type]: state.selected[type].filter(item => !ids.includes(item))
        }
      };
    }

    case ActionTypes.UN_SELECT_ALL:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.data]: []
        }
      };

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.type]: action.data.data
        }
      };

    case ActionTypes.INIT_MATCHES: {
      const { matches, type } = action.data;

      const cartCountries: Record<string, CountryPricesType[]> = {};
      const IDs: string[] = [];

      matches.forEach(item => {
        cartCountries[item.id] = item.countries;
        IDs.push(item.id);
      });

      return {
        ...state,
        selected: {
          ...state.selected,
          [type]: IDs
        },
        cartCountries
      };
    }

    case ActionTypes.SELECT_MATCH_COUNTRIES: {
      const { matchId, data } = action.data;

      const item = state.cartCountries[matchId]?.find(
        item => item.id === data.id
      );

      if (item) {
        return {
          ...state,
          cartCountries: {
            ...state.cartCountries,
            [matchId]: state.cartCountries[matchId].filter(
              item => item.id !== data.id
            )
          }
        };
      }

      return {
        ...state,
        cartCountries: {
          ...state.cartCountries,
          [matchId]: [...(state.cartCountries[matchId] || []), data]
        }
      };
    }

    case ActionTypes.SELECT_ALL_COUNTRIES: {
      const { matchId, data } = action.data;

      return {
        ...state,
        cartCountries: {
          ...state.cartCountries,
          [matchId]: data
        }
      };
    }

    case ActionTypes.APPLY_MATCH_COUNTRIES_TO_ALL: {
      const { data } = action;

      const currentCountries = state.cartCountries[data];

      const cartCountries: Record<string, CountryPricesType[]> = {};

      state.selected.list.forEach(id => {
        cartCountries[id] = currentCountries;
      });

      return { ...state, cartCountries };
    }

    default:
      return state;
  }
};

export default reducer;
