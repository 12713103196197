/* eslint-disable @typescript-eslint/no-explicit-any */
import client from 'apolloClient';
import { Reference } from '@apollo/client';
import _ from 'lodash';
import {
  MATCHES_BOOKED_FRAGMENT,
  MATCHES_LIST_FRAGMENT
} from 'gql/events/fragments';
import { BuyerListMatches_buyerListMatches_data_results } from 'gql/events/types/BuyerListMatches';
import { HistoryMatchesBuyer_historyMatchesBuyer_data_results } from 'gql/events/types/HistoryMatchesBuyer';
import { BookedMatches_bookedMatches_data_results } from 'gql/events/types/BookedMatches';
import { MatchTypesEnum } from 'constants/match';
import { setEventsTotal } from 'store/slice/filters/events';
import { UserProjects } from 'gql/organizations/types/UserProjects';
import { GET_USER_PROJECTS } from 'gql/organizations/queries';

import { store } from '../../store';

export const addMatch = async (
  response:
    | BuyerListMatches_buyerListMatches_data_results[]
    | HistoryMatchesBuyer_historyMatchesBuyer_data_results[]
    | BookedMatches_bookedMatches_data_results[],
  fieldName: string,
  type: MatchTypesEnum
) => {
  try {
    let total = 0; // Initialize matchTotal
    client.cache.modify({
      fields: {
        async [fieldName](matches: any) {
          if (matches?.data?.results?.length) {
            total = parseInt(matches.data.total ?? 0) + response.length; // Update matchTotal here
            const newMatchItemPromises = response.map(item => {
              return client.cache.writeFragment({
                id: `Match:${item.id}`,
                fragment: MATCHES_LIST_FRAGMENT,
                fragmentName: 'MatchesListFragment',
                data: item
              });
            });

            const newMatchItems = await Promise.all(newMatchItemPromises);

            let results = _.unionBy(matches.data.results, newMatchItems, 'id');
            results = _.orderBy(results, 'startTime', 'asc');

            let newBookedMatchItems: any = [];

            if (fieldName === 'bookedMatches') {
              const newBookedMatchItemPromises = response.map(item => {
                return client.cache.writeFragment({
                  id: `BookedMatch:${item.id}`,
                  fragment: MATCHES_BOOKED_FRAGMENT,
                  fragmentName: 'MatchesBookedFragment',
                  data: item
                });
              });

              newBookedMatchItems = await Promise.all(
                newBookedMatchItemPromises
              );

              results = _.unionBy(results, newBookedMatchItems, 'id');
              results = _.orderBy(results, 'startTime', 'asc');
            }

            return {
              ...matches,
              data: {
                results,
                total
              }
            };
          }
        }
      }
    });

    if (total !== undefined) {
      store.dispatch(
        setEventsTotal({
          type,
          value: total
        })
      );
    } else {
      console.log('No matches were modified.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const updateMatch = async (
  response: {
    id: string;
    data:
      | BuyerListMatches_buyerListMatches_data_results
      | HistoryMatchesBuyer_historyMatchesBuyer_data_results
      | BookedMatches_bookedMatches_data_results;
  }[],
  fieldName: string
) => {
  try {
    let total = 0; // Initialize matchTotal
    const promises = response.map(item => {
      return client.cache.modify({
        fields: {
          async [fieldName](matches: any, { readField }) {
            if (matches?.data?.results?.length) {
              total = parseInt(matches.data.total ?? 0) - 1; // Update matchTotal here

              return {
                ...matches,
                data: {
                  ...matches.data,
                  results: matches.data.results.map((ref: Reference) => {
                    if (item.id.toString() !== readField('id', ref)) {
                      return item.data;
                    }

                    return ref;
                  }),
                  total
                }
              };
            }
          }
        }
      });
    });

    await Promise.all(promises);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deleteMatch = async (
  response: { id: string }[],
  fieldName: string,
  type: MatchTypesEnum
) => {
  try {
    let total = 0; // Initialize matchTotal

    const promises = response.map((item: { id: string }) => {
      return client.cache.modify({
        fields: {
          [fieldName](matches: any, { readField }) {
            if (matches?.data?.results?.length) {
              total = parseInt(matches.data.total ?? 0) - 1; // Update matchTotal here

              return {
                ...matches,
                data: {
                  ...matches.data,
                  results: matches.data.results.filter(
                    (ref: Reference) =>
                      item.id.toString() !== readField('id', ref)
                  ),
                  total
                }
              };
            }
          }
        }
      });
    });

    await Promise.all(promises);

    if (total !== undefined) {
      store.dispatch(
        setEventsTotal({
          type,
          value: total
        })
      );
    } else {
      console.log('No matches were modified.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const streamAuthStatus = (response: { data: any; error: any }) => {
  console.log('response', response);
};

export const updateUserProjects = (res: { buyer: any }) => {
  return client.cache.modify({
    fields: {
      userProjects(existingUserProjects = {}) {
        const { data: existingData = {} } = existingUserProjects;

        const updatedBuyer = res.buyer.map((item: any) => ({
          __typename: 'UserProject',
          ...item
        }));

        return {
          ...existingUserProjects,
          data: {
            ...existingData,
            buyer: updatedBuyer
          }
        };
      }
    }
  });
};

export const updateUser = (res: any) => {
  return client.cache.modify({
    fields: {
      me(existingUserProjects = {}) {
        const updatedData = {
          __typename: 'User',
          status: res.status
        };

        return {
          ...existingUserProjects,
          data: updatedData
        };
      }
    }
  });
};

export const updateOrganization = (res: any) => {
  const data = client.readQuery<UserProjects>({
    query: GET_USER_PROJECTS
  });

  const organization =
    data?.userProjects?.data.buyer?.filter(el => el.id === `${res.id}`) || [];

  const buyers =
    data?.userProjects?.data.buyer?.filter(el => el.id !== `${res.id}`) || [];

  if (!organization.length) return;

  const updatedOrganization = {
    ...organization[0],
    bmeStatus: res.bmeStatus,
    projectStatus: res.projectStatus,
    status: res.status
  };

  return client.cache.modify({
    fields: {
      userProjects(existingUserProjects = {}) {
        const { data: existingData = {} } = existingUserProjects;

        return {
          ...existingUserProjects,
          data: {
            ...existingData,
            buyer: [...buyers, updatedOrganization]
          }
        };
      }
    }
  });
};

export default {
  addMatch,
  updateMatch,
  deleteMatch,
  streamAuthStatus,
  updateUserProjects,
  updateUser,
  updateOrganization
};
