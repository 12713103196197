import { FC, useEffect, useState } from 'react';
import { Col, PageContainer, Row } from '@shared_medialab/ui_components';
import { useQuery } from '@apollo/client';
import {
  GET_BUYER_EVENTS_REPORTS,
  GET_BUYER_LOCATION_REPORTS,
  GET_BUYER_PLATFORM_REPORTS,
  GET_BUYER_SESSIONS_REPORTS,
  GET_BUYER_SPORTS_REPORTS,
  GET_BUYER_VIEW_REPORTS
} from 'gql/reports/queries';
import { BuyerMatchReports } from 'gql/reports/types/BuyerMatchReports';
import { BuyerSessionsReports } from 'gql/reports/types/BuyerSessionsReports';
import { BuyerViewReports } from 'gql/reports/types/BuyerViewReports';
import { BuyerSportsReports } from 'gql/reports/types/BuyerSportsReports';
import { BuyerOsReports } from 'gql/reports/types/BuyerOsReports';
import { BuyerLocationReports } from 'gql/reports/types/BuyerLocationReports';
import {
  ColumnType,
  ISimpleDataType
} from '@shared_medialab/ui_components/build/base/Table/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectReportsFilters,
  selectReportsPagination
} from 'store/slice/filters/reports/selectors';
import {
  ReportsFiltersKeys,
  UpdateNames
} from 'store/slice/filters/reports/types';
import {
  COUNTRIES_COLORS,
  DEVICES_COLORS,
  SPORTS_COLORS
} from 'constants/reports';
import { useProjectStateContext } from 'providers/Projects';
import { shallowEqual } from 'react-redux';
import { setFilterItem } from 'store/slice/filters/reports';
import { useTranslation } from 'react-i18next';

import {
  EVENTS_PAGE_SIZE,
  LOCATION_COLUMNS,
  MATCH_COLUMNS,
  OS_COLUMNS,
  OVER_VIEW_COLUMNS,
  PAGE_SIZE,
  POPULARITY_SPORTS_COLUMNS,
  UNIQUE_VIEW_COLUMNS
} from './constants';
import Charts from 'components/shared/Charts';

const Reports: FC = () => {
  //state
  const [dateItemValue, setItemValue] = useState<{
    itemName: string;
    value: { startDate: string | Date | null; endDate: string | Date | null };
  }>();

  const { t } = useTranslation();

  const { selectedProject } = useProjectStateContext();
  // redux
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(selectReportsPagination());

  const reportsFilters = useAppSelector(selectReportsFilters(), shallowEqual);

  const filter = {
    countryNames: reportsFilters.countryNames, // locations
    sportIds: reportsFilters.sport,
    regionIds: reportsFilters.region,
    leagueIds: reportsFilters.league,
    platformNames: reportsFilters.platform
  };

  const overViewQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.overView].page,
      limit: PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.overViewDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.overViewDate
    }
  };

  const dailyViewsQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.uniqView].page,
      limit: PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.dailyViewsDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.dailyViewsDate
    }
  };

  const sportsQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.sport].page,
      limit: PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.sportsDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.sportsDate
    }
  };

  const platformsQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.osPlatform].page,
      limit: PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.platformsDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.platformsDate
    }
  };

  const locationsQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.location].page,
      limit: PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.locationsDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.locationsDate
    }
  };

  const eventsQuery = {
    pagination: {
      page: pagination[ReportsFiltersKeys.events].page,
      limit: EVENTS_PAGE_SIZE
    },
    filter: {
      ...filter,
      date: !reportsFilters.eventsDate.startDate.length
        ? reportsFilters.dates
        : reportsFilters.eventsDate
    }
  };

  // graphql
  const { data: eventData, loading: eventLoading } =
    useQuery<BuyerMatchReports>(GET_BUYER_EVENTS_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: eventsQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const { data: overViewData, loading: overViewLoading } =
    useQuery<BuyerSessionsReports>(GET_BUYER_SESSIONS_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: overViewQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const { data: uniqViewData, loading: uniqVieLoading } =
    useQuery<BuyerViewReports>(GET_BUYER_VIEW_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: dailyViewsQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const { data: popularitySportData, loading: sportLoading } =
    useQuery<BuyerSportsReports>(GET_BUYER_SPORTS_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: sportsQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const { data: platformOsData, loading: platformLoading } =
    useQuery<BuyerOsReports>(GET_BUYER_PLATFORM_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: platformsQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const { data: locationData, loading: locationLoading } =
    useQuery<BuyerLocationReports>(GET_BUYER_LOCATION_REPORTS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: locationsQuery,
        partnerId: selectedProject?.partnerId
      }
    });

  const events = eventData?.buyerMatchReports?.data.results || [];
  const eventsTotal = eventData?.buyerMatchReports?.data.total || 0;

  const overView = overViewData?.buyerSessionsReports?.data.results || [];

  const uniqView = uniqViewData?.buyerViewReports?.data.results || [];
  const uniqViewTotal = uniqViewData?.buyerViewReports?.data.total || 0;

  const popularitySport =
    popularitySportData?.buyerSportsReports?.data.results || [];

  const popularitySportTotal =
    popularitySportData?.buyerSportsReports?.data.total || 0;

  const platformOs = platformOsData?.buyerOsReports?.data.results || [];
  const platformOsTotal = platformOsData?.buyerOsReports?.data.total || 0;

  const location = locationData?.buyerLocationReports?.data.results || [];
  const locationTotal = locationData?.buyerLocationReports?.data.total || 0;

  useEffect(() => {
    if (dateItemValue) {
      dispatch(
        setFilterItem({
          name: dateItemValue?.itemName as UpdateNames,
          value: {
            startDate: dateItemValue?.value.startDate as string,
            endDate: dateItemValue?.value.endDate as string
          }
        })
      );
    }
  }, [dateItemValue, dispatch, setItemValue]);

  return (
    <PageContainer bodyStyle={{ paddingRight: '10px', borderRadius: '6px' }}>
      <div className="full-height full-width">
        <Row gutter={[8, 8]} wrap>
          <Col span={12}>
            <Charts
              query={overViewQuery}
              dateItem={{
                itemName: 'overViewDate',
                value: !reportsFilters.overViewDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.overViewDate
              }}
              setItem={setItemValue}
              //Todo: remove total
              total={eventsTotal}
              loading={overViewLoading}
              title={t('common:overview')}
              type="partners-info"
              date
              exportData
              columns={
                OVER_VIEW_COLUMNS as unknown as ColumnType<ISimpleDataType>[]
              }
              data={overView as unknown as ISimpleDataType[]}
            />
          </Col>
          <Col span={6}>
            <Charts
              query={dailyViewsQuery}
              dateItem={{
                itemName: 'dailyViewsDate',
                value: !reportsFilters.dailyViewsDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.dailyViewsDate
              }}
              setItem={setItemValue}
              loading={uniqVieLoading}
              paginationType={ReportsFiltersKeys.uniqView}
              total={uniqViewTotal}
              title={t('common:daily_views')}
              icons={['c-table', 'c-pie']}
              type="line"
              date
              exportData
              columns={UNIQUE_VIEW_COLUMNS as ColumnType<ISimpleDataType>[]}
              data={uniqView as unknown as ISimpleDataType[]}
              tooltipTypeItem="range"
              dataKey={{
                name: 'range',
                value: 'total_unique_views'
              }}
            />
          </Col>
          <Col span={6}>
            <Charts
              query={sportsQuery}
              dateItem={{
                itemName: 'sportsDate',
                value: !reportsFilters.sportsDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.sportsDate
              }}
              loading={sportLoading}
              setItem={setItemValue}
              paginationType={ReportsFiltersKeys.sport}
              total={popularitySportTotal}
              title={t('events:sports')}
              icons={['c-table', 'c-pie']}
              type="chart-pie"
              date
              exportData
              columns={
                POPULARITY_SPORTS_COLUMNS as ColumnType<ISimpleDataType>[]
              }
              data={popularitySport}
              constColors={SPORTS_COLORS}
              tooltipTypeItem="dimension_sport"
              dataKey={{
                value: 'total_unique_views',
                name: 'dimension_sport'
              }}
            />
          </Col>
          <Col span={6}>
            <Charts
              query={platformsQuery}
              dateItem={{
                itemName: 'platformsDate',
                value: !reportsFilters.platformsDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.platformsDate
              }}
              loading={platformLoading}
              setItem={setItemValue}
              paginationType={ReportsFiltersKeys.osPlatform}
              total={platformOsTotal}
              title={t('common:platforms')}
              icons={['c-table', 'c-pie']}
              type="chart-pie"
              date
              exportData
              //Todo
              columns={OS_COLUMNS as ColumnType<ISimpleDataType>[]}
              data={platformOs}
              tooltipTypeItem="dimension_device"
              constColors={DEVICES_COLORS}
              dataKey={{
                value: 'total_unique_views',
                name: 'dimension_device'
              }}
            />
          </Col>
          <Col span={6}>
            <Charts
              query={locationsQuery}
              dateItem={{
                itemName: 'locationsDate',
                value: !reportsFilters.locationsDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.locationsDate
              }}
              loading={locationLoading}
              setItem={setItemValue}
              paginationType={ReportsFiltersKeys.location}
              total={locationTotal}
              columns={LOCATION_COLUMNS as ColumnType<ISimpleDataType>[]}
              data={location}
              title="Locations"
              icons={['c-table', 'c-globus', 'c-pie']}
              type="threePage"
              date
              upendPage="chart"
              exportData
              constColors={COUNTRIES_COLORS}
              dataKey={{
                name: 'dimension_country',
                value: 'total_unique_views'
              }}
            />
          </Col>
          <Col span={12}>
            <Charts
              query={eventsQuery}
              dateItem={{
                itemName: 'eventsDate',
                value: !reportsFilters.eventsDate.startDate.length
                  ? reportsFilters.dates
                  : reportsFilters.eventsDate
              }}
              eventLoading={eventLoading}
              setItem={setItemValue}
              columns={MATCH_COLUMNS as ColumnType<ISimpleDataType>[]}
              data={events}
              paginationType={ReportsFiltersKeys.events}
              total={eventsTotal}
              title={t('common:events')}
              type="table"
              date
              exportData
            />
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default Reports;
