import styled, { css } from 'styled-components';

import {
  IButtonProps,
  IContentProps,
  IStyledSelectProps,
  IRotateProps
} from './types';

export const StyledContent = styled.div<IContentProps>`
  width: 250px;
  border-top: none !important;
  overflow: hidden !important;
  background-color: var(--Background__8);
  border-radius: 0 0 4px 4px;
`;
export const StyledHeader = styled.div<IStyledSelectProps>`
  width: 250px;
  padding: 10px;
  border-radius: 4px;
  .user_email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  background-color: var(--steel-blue);
  color: var(--white);
  font-size: 12px;
  overflow: hidden;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s !important;
  svg {
    transition: all 0.3s;
    ${({ isOpened }) => isOpened && 'transform: rotate(180deg)'};
  }
  &:hover {
    color: var(--white);
    .icon {
      color: var(--white);
    }
  }
`;
export const StyledButtonSelect = styled.div<IButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s linear;
  & > input {
    &::placeholder {
      transition: 1s;
    }
  }
  .icon {
    transform: scale(1);
    transition: 0.3s;
  }
  ${({ theme, buttonType }) => css`
    padding: ${buttonType === 'menu'
      ? theme.spaces.base
      : `${theme.spaces.sm} ${theme.spaces.base}`};
    color: var(--text-color);
    font-size: ${theme.sizes.base};
    background: var(--Background__7);
  `}
  &:hover {
    color: var(--vivid-cerulean);
    .icon {
      color: var(--vivid-cerulean) !important;
      transform: scale(1.2);
    }
    & > input {
      &::placeholder {
        color: var(--vivid-cerulean);
      }
    }
  }
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--Background__8);
  }
`;
export const StyledIconRotate = styled.div<IRotateProps>`
  color: var(--white);
  padding: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: center center;
`;
