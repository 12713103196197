import { ContentPackages_contentPackages_data_results as ContentPackagesType } from 'gql/contentPackages/types/ContentPackages';
import { ReactNode } from 'react';

export const ActionTypes = {
  CHOOSE_PACKAGES: 'CHOOSE_PACKAGES',
  CHOOSE_PACKAGES_SPORTS: 'CHOOSE_PACKAGES_SPORTS'
} as const;

export interface IChoosePackageProps {
  children: ReactNode;
}

export type PackageSports = Record<string, string[]>;

export type State = {
  selectedPackages: ContentPackagesType[];
  selectedPackagesSports: PackageSports;
  sportIds: string[];
};

type choosePackages = {
  type: typeof ActionTypes.CHOOSE_PACKAGES;
  data: ContentPackagesType;
};
type ChoosePackagesSports = {
  type: typeof ActionTypes.CHOOSE_PACKAGES_SPORTS;
  data: { values: PackageSports; sportIds: string[] };
};

export type Action = choosePackages | ChoosePackagesSports;

export type DispatchContext = {
  choosePackages: (data: ContentPackagesType) => void;
  choosePackagesSports: (data: PackageSports, sportIds: string[]) => void;
};

export type Dispatch = (action: Action) => void;
