import { Select } from '@shared_medialab/ui_components';
import { setEventsFilterItem } from 'store/slice/filters';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSports } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';

import { RouteParams } from '../../../Content/types';

const SelectSport = () => {
  const { t } = useTranslation('events');
  const { type } = useParams() as RouteParams;
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const { sports, onSportsScroll, sportSearch, onSportSearch } = useSports();
  const dispatch = useAppDispatch();

  return (
    <Select
      label={t('sports')}
      placeholder={t('select_sports')}
      options={sports.map(
        (item: {
          id: string;
          creationId: string;
          name: string;
          leaguesCount: string | number | null;
        }) => ({
          label: item.name,
          value: item.creationId
        })
      )}
      value={eventsFilters.sportIds}
      onClose={() => {
        if (!eventsFilters.sportIds.length) onSportSearch('');
      }}
      onChange={value => {
        dispatch(
          setEventsFilterItem({
            name: 'sportIds',
            value,
            type
          })
        );
      }}
      onScroll={onSportsScroll}
      onSearch={e => onSportSearch(e.target.value)}
      searchValue={sportSearch}
      cornerRadius="smooth"
      mode="multiple"
    />
  );
};

export default SelectSport;
