import { Select } from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setEventsFilterItem } from 'store/slice/filters';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';
import { useRegions } from 'hooks';

import { RouteParams } from '../../../Content/types';

const SelectRegion = () => {
  const { t } = useTranslation('events');
  const { type } = useParams() as RouteParams;
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const dispatch = useAppDispatch();

  const { regions, regionSearch, onRegionSearch } = useRegions({
    sportIds: eventsFilters.sportIds
  });

  return (
    <Select
      label={t('region')}
      placeholder={t('select_regions')}
      options={regions.map(item => ({
        label: item.name,
        value: item.creationId
      }))}
      value={eventsFilters.regionIds}
      onChange={value => {
        dispatch(
          setEventsFilterItem({
            name: 'regionIds',
            value,
            type
          })
        );
      }}
      onClose={() => {
        if (!eventsFilters.regionIds.length) onRegionSearch('');
      }}
      onSearch={e => onRegionSearch(e.target.value)}
      searchValue={regionSearch}
      cornerRadius="smooth"
      mode="multiple"
    />
  );
};

export default SelectRegion;
