import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { MatchTypesEnum } from 'constants/match';

import {
  EventsFiltersState,
  OnChange,
  Reset,
  SetError,
  SetLoading,
  SetName,
  SetPagination,
  SetPresetName,
  SetStreamError,
  TogglePreset
} from './types';
import { parseJson } from 'utils/object';

export const eventsInitialFilters = {
  name: '',
  // auto_booking: [],
  dates: {
    startDate: dayjs().startOf('day').format(),
    endDate: dayjs().add(1, 'months').endOf('day').format()
  },
  sportIds: [],
  regionIds: [],
  leagueIds: [],
  status: [],
  bookingType: ''
};

const initialData = {
  selectedPreset: '',
  name: '',
  error: {
    value: false,
    message: ''
  },
  streamUrlError: {
    value: false,
    message: '',
    matchId: '',
    referer: ''
  },
  pagination: {
    page: 0,
    total: 0
  },
  loading: true,
  isPlayDisabled: true
};

const initialState: EventsFiltersState = {
  [MatchTypesEnum.list]: {
    filters: {
      ...eventsInitialFilters
    },
    ...initialData
  },
  [MatchTypesEnum.booked]: {
    filters: {
      ...eventsInitialFilters
    },
    ...initialData
  },
  [MatchTypesEnum.history]: {
    ...{
      filters: {
        ...eventsInitialFilters,
        ...{
          dates: {
            startDate: dayjs().add(-1, 'week').startOf('day').format(),
            endDate: dayjs().endOf('day').format()
          }
        }
      }
    },
    ...initialData
  }
};

export const eventsFilterSlice = createSlice({
  name: 'league-filters',
  initialState,
  reducers: {
    setEventsFilterItem: (state, action: PayloadAction<OnChange>) => {
      const { type, name, value } = action.payload;

      state[type].loading = true;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[type].filters[name] = value;
      localStorage.setItem(
        `events${type}Filters`,
        JSON.stringify(state[type].filters)
      );
    },
    setEventsFilterStatusItem: (state, action: PayloadAction<OnChange>) => {
      const { type, name, value } = action.payload;
      state[type].loading = true;

      const checked = state[type].filters[name] as string[];

      if (!checked.includes(value as string)) {
        checked.push(value as string);
        localStorage.setItem(
          `events${type}Filters`,
          JSON.stringify(state[type].filters)
        );
      } else {
        const index = checked.indexOf(value as string);
        checked.splice(index, 1);
        localStorage.setItem(
          `events${type}Filters`,
          JSON.stringify(state[type].filters)
        );
      }
    },
    toggleEventsFilterPreset: (state, action: PayloadAction<TogglePreset>) => {
      const { value, type, filters } = action.payload;

      state[type].loading = true;

      if (state[type].selectedPreset === value) {
        state[type].selectedPreset = '';
        state[type].name = '';
        state[type].filters = { ...eventsInitialFilters };
        localStorage.removeItem(`events${type}FilterPresets`);
        localStorage.removeItem(`events${type}Filters`);
      } else {
        state[type].selectedPreset = value;
        state[type].name = value;

        state[type].filters = {
          ...state[type].filters,
          ...filters
        };
        localStorage.setItem(
          `events${type}Filters`,
          JSON.stringify(state[type].filters)
        );

        localStorage.setItem(
          `events${type}FilterPresets`,
          JSON.stringify({
            name: state[type].name,
            error: state[type].error,
            selectedPreset: state[type].selectedPreset
          })
        );
      }
    },
    setEventsFilterPresetName: (
      state,
      action: PayloadAction<SetPresetName>
    ) => {
      const { value, type } = action.payload;

      state[type].selectedPreset = value;

      localStorage.setItem(
        `events${type}FilterPresets`,
        JSON.stringify({
          name: state[type].name,
          error: state[type].error,
          selectedPreset: state[type].selectedPreset
        })
      );
    },
    setEventsFilterPresetNameInput: (state, action: PayloadAction<SetName>) => {
      const { type, value } = action.payload;

      state[type].name = value;
      state[type].error = {
        value: false,
        message: ''
      };
      localStorage.setItem(
        `events${type}FilterPresets`,
        JSON.stringify({
          name: state[type].name,
          error: state[type].error,
          selectedPreset: state[type].selectedPreset
        })
      );
    },
    setEventsFilterPresetError: (state, action: PayloadAction<SetError>) => {
      const { type, value, message } = action.payload;

      state[type].error = { value, message };

      localStorage.setItem(
        `events${type}FilterPresets`,
        JSON.stringify({
          name: state[type].name,
          error: state[type].error,
          selectedPreset: state[type].selectedPreset
        })
      );
    },
    resetEventsFilters: (state, action: PayloadAction<Reset>) => {
      const { type } = action.payload;

      switch (type) {
        case MatchTypesEnum.list:
          state[type].filters = { ...eventsInitialFilters };
          break;
        case MatchTypesEnum.history:
          state[type].filters = {
            ...eventsInitialFilters,
            ...{
              dates: {
                startDate: dayjs().add(-1, 'week').startOf('day').format(),
                endDate: dayjs().endOf('day').format()
              }
            }
          };
          break;
        default:
          state[type].filters = { ...eventsInitialFilters };
          break;
      }

      state[type] = {
        ...initialData,
        filters: state[type].filters
      };
      localStorage.removeItem(`events${type}Filters`);
      localStorage.removeItem(`events${type}FilterPresets`);
    },
    setEventsPage: (state, action: PayloadAction<SetPagination>) => {
      const { type, value } = action.payload;

      state[type].pagination.page = value;
      state[type].loading = true;
    },
    setEventsTotal: (state, action: PayloadAction<SetPagination>) => {
      const { type, value } = action.payload;

      state[type].pagination.total = value;
      state[type].loading = false;
    },
    setEventsLoading: (state, action: PayloadAction<SetLoading>) => {
      const { type, value } = action.payload;

      state[type].loading = value;
    },
    setIsPlayDisabled: (state, action: PayloadAction<SetLoading>) => {
      const { type, value } = action.payload;

      state[type].isPlayDisabled = value;
    },
    setStreamUrlError: (state, action: PayloadAction<SetStreamError>) => {
      const { type, value, message, matchId, referer } = action.payload;
      state[type].streamUrlError = { value, message, matchId, referer };
    },
    initEventsFilters: (state, action: PayloadAction<Reset>) => {
      const { type } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[type] = {
        ...initialState[type],
        ...parseJson(localStorage.getItem(`events${type}FilterPresets`))
      };
      state[type].filters = {
        ...initialState[type].filters,
        ...parseJson(localStorage.getItem(`events${type}Filters`) || {})
      };
    },
    resetEventsName: (state, action: PayloadAction<Reset>) => {
      const { type } = action.payload;

      state[type].filters = {
        ...state[type].filters,
        name: ''
      };
      localStorage.setItem(
        `events${type}Filters`,
        JSON.stringify(state[type].filters)
      );
    }
  }
});

export const {
  setEventsFilterItem,
  setEventsFilterStatusItem,
  toggleEventsFilterPreset,
  setEventsFilterPresetName,
  setEventsFilterPresetNameInput,
  setEventsFilterPresetError,
  resetEventsFilters,
  setEventsPage,
  setEventsTotal,
  setEventsLoading,
  setIsPlayDisabled,
  setStreamUrlError,
  resetEventsName,
  initEventsFilters
} = eventsFilterSlice.actions;

export default eventsFilterSlice.reducer;
