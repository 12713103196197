import styled from 'styled-components';

export const StyledPersonalDetails = styled.div`
  height: 100%;
  min-height: 47.7vh;
  max-height: 47.7vh;
  position: sticky;
  top: 0;
`;

export const StyledResendEmailContainer = styled.div`
  top: 22px;
  right: 5px;
`;
