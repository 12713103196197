import styled from '@shared_medialab/ui_components';

export const StyledHeader = styled.header`
  height: 50px;
  background: linear-gradient(
    to right,
    var(--slate-blue),
    var(--indigo-blue) 100%
  );
  box-shadow: 0 0 3px 0 rgba(30, 35, 69, 0.09);
  .logo-friendship {
    svg {
      width: 116px;
    }
  }
`;
