import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Theme } from '@shared_medialab/ui_components';

const useColors = () => {
  const { colors }: Theme = useContext(ThemeContext);

  return colors;
};

export default useColors;
