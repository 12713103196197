import { createContext, useContext, FC } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { FieldsValue, IUserProps } from './type';

export const initialFormValues = {
  firstName: '',
  status: '',
  email: '',
  projects: [{ projectId: '', role: '' }],
  lastName: ''
};

const UserFormStateContext = createContext<
  UseFormReturn<FieldsValue> | undefined
>(undefined);

const UserFormProvider: FC<IUserProps> = ({ children }) => {
  // form
  const form = useForm<FieldsValue>({
    defaultValues: { ...initialFormValues }
  });

  return (
    <UserFormStateContext.Provider value={form}>
      {children}
    </UserFormStateContext.Provider>
  );
};

const useUserFormStateContext = () => {
  const context = useContext(UserFormStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useUserFormStateContext must be used within a UserFormStateContext'
    );
  }

  return context;
};

export default UserFormProvider;
export { useUserFormStateContext };
