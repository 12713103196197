import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

export const StyledSkeleton = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.light[2]};
    transition: background-color 0.3s;
    background-color: ${theme.colors.light[0]};
    cursor: pointer;
  `}
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;
