import { FC, useEffect, useState } from 'react';
import {
  Col,
  Row,
  CreateCard,
  BookingCard,
  Loading,
  NoData,
  PageContainer,
  Pagination2
} from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { GET_AUTO_BOOKING_RULES_ORGANIZATION } from 'gql/autobooking/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UPDATE_AUTO_BOOKING_STATUS } from 'gql/autobooking/mutations';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useProjectStateContext } from 'providers/Projects';
import { AutoBookingRulesOrganization } from 'gql/autobooking/types/AutoBookingRulesOrganization';
import { usePermission } from 'hooks';
import * as permissionConstants from 'constants/permissions';
import {
  PACKAGE_LABELS,
  PACKAGE_TYPE,
  PackageType
} from 'constants/autobooking';
import { UserMe } from 'gql/auth/types/UserMe';
import { GET_USER_DATA } from 'gql/auth/queries';
import { GraphQLFormattedError } from 'graphql';

import { ConfirmModalTypes } from 'components/shared/ConfirmModal/types';
import { NoDataWrapper } from './styled';
import Private from 'components/shared/Private';
import { UserEnum } from 'pages/UserManagement/constants';
import { getErrorResponse } from 'utils/validations';

const limit = 20;

const AutoBooking: FC = () => {
  // navigation
  const navigate = useNavigate();
  //state
  const [page, setPage] = useState(0);
  // context
  const { toggleModal } = useUIDispatchContext();
  const { autoBooking } = useModalStateContext();
  const { selectedProject } = useProjectStateContext();
  // translations
  const { t } = useTranslation('autobooking');
  // hooks
  const { checkUserPermission } = usePermission();
  // graphql
  const { data: autoBookingRules, loading } =
    useQuery<AutoBookingRulesOrganization>(
      GET_AUTO_BOOKING_RULES_ORGANIZATION,
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: {
          query: {
            pagination: {
              limit: limit,
              page: page
            }
          },
          id: selectedProject?.id || ''
        }
      }
    );

  const results =
    autoBookingRules?.autobookingRulesOrganization?.data.results || [];

  const total = autoBookingRules?.autobookingRulesOrganization?.data.total || 0;
  const allowEditAutoBookingRule =
    autoBookingRules?.autobookingRulesOrganization?.data
      ?.allowEditAutoBookingRule;

  const [updateRuleStatus] = useMutation(UPDATE_AUTO_BOOKING_STATUS, {
    onError(err) {
      const response = getErrorResponse(
        err.graphQLErrors as readonly GraphQLFormattedError[]
      );

      toast.error(response[0]?.message);
    }
  });

  // graphql
  const [getUserData, { data: settingsData }] =
    useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const onSubmit = (id: string, status: boolean) => {
    updateRuleStatus({
      variables: {
        id,
        input: {
          status,
          timezone: settingsData?.me?.data.settings.timezone
        }
      }
    });
  };

  const onClickBookingCard = (id: string) => {
    const isInitiallyClosed = !autoBooking.visible;
    const inAnOtherCard = autoBooking.params?.id !== id;
    const isVisible = isInitiallyClosed || inAnOtherCard;
    toggleModal(ModalKeys.autoBooking, isVisible, {
      id
    });
  };

  const hasCreatePermission = checkUserPermission(
    permissionConstants.BUYER_ROUTES_IDS.autoBooking,
    permissionConstants.CREATE
  );

  const hasFreezePermission = checkUserPermission(
    permissionConstants.BUYER_ROUTES_IDS.autoBooking,
    permissionConstants.FREEZE
  );

  return (
    <div className="full-width full-height">
      <div className="flex-display flex-justify-space-between flex-direction-column full-height p--12 pb--16">
        <Row gutter={[8, 8]} wrap>
          {results.length ? (
            <Private
              routeId={permissionConstants.BUYER_ROUTES_IDS.autoBooking}
              routeKey={permissionConstants.CREATE}
            >
              <Col xxl={3} span={4}>
                <CreateCard
                  title="Create AutoBooking Rule"
                  disable={!allowEditAutoBookingRule}
                  onClick={() => navigate(`/autobooking/details`)}
                />
              </Col>
            </Private>
          ) : (
            <NoDataWrapper>
              <NoData
                size="default"
                transparent
                text={hasCreatePermission ? t('noData_text') : ''}
                subText={t(hasCreatePermission ? 'noData_sub_text' : '')}
                showButton={hasCreatePermission}
                {...(hasCreatePermission
                  ? {
                      buttonText: 'Create',
                      buttonOnClick: () => {
                        navigate(`/autobooking/details`);
                      }
                    }
                  : {})}
              />
            </NoDataWrapper>
          )}
          {loading ? (
            <Loading />
          ) : (
            results.map((elem, index) => {
              const isPackage = elem.type ? elem.type : 1;

              return (
                <Col xxl={3} span={4} key={index}>
                  <BookingCard
                    title={elem.name}
                    onClick={() => {
                      onClickBookingCard(String(elem.id));
                    }}
                    counts={[elem.sportsCount || 0, elem.leaguesCount || 0]}
                    isPackage={PACKAGE_TYPE[isPackage as PackageType]}
                    status={
                      elem.isFrozen
                        ? 'danger'
                        : autoBooking.params?.id === String(elem.id)
                        ? 'active'
                        : 'default'
                    }
                    {...(hasFreezePermission
                      ? {
                          icon: {
                            type: 'power',
                            tooltip: elem.isFrozen ? 'Unfreeze' : 'Freeze'
                          },
                          onAction: () => {
                            if (
                              elem.frozenByParent &&
                              isPackage === PACKAGE_LABELS.auto &&
                              elem.isFrozen
                            )
                              return;
                            else {
                              toggleModal(ModalKeys.confirm, true, {
                                title: elem.isFrozen
                                  ? t('unfreeze_question')
                                  : t('freeze_question'),
                                message: elem.isFrozen
                                  ? t('unfreeze_text')
                                  : t('freeze_text'),
                                confirmText: t('yes'),
                                type: ConfirmModalTypes.primaryNew,
                                onConfirm: () => {
                                  onSubmit(String(elem.id), !elem.isFrozen);
                                }
                              });
                            }
                          }
                        }
                      : {})}
                  />
                </Col>
              );
            })
          )}
        </Row>
        <>
          <PageContainer
            bodyStyle={{
              height: '350px'
            }}
            footer={
              results.length &&
              total < 20 && (
                <div className="full-height p--12 flex-display flex-justify-end">
                  <Pagination2
                    total={total}
                    page={page}
                    setPage={val => setPage(val)}
                    size={limit}
                  />
                </div>
              )
            }
          ></PageContainer>
        </>
      </div>
    </div>
  );
};

export default AutoBooking;
