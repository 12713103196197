import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Typography } from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { MatchTypesEnum } from 'constants/match';

import Item from './Item';
import { RouteParams as EventsRouterParams } from '../../Content/types';
import { IContentProps } from '../types';

const { Text } = Typography;

const Content: FC<IContentProps> = ({
  data,
  checkboxes,
  setCheckboxes,
  isEventLive
}) => {
  const { type } = useParams() as EventsRouterParams;
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setCheckboxes(data);
  }, [data?.length]);

  useEffect(() => {
    if (checkboxes.every(el => el.isChecked)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxes]);

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map(checkbox => ({
      ...checkbox,
      isChecked: !selectAll
    }));

    // setCheckedIds(updatedCheckboxes.map(el => el.isChecked && el.id));
    setSelectAll(prev => !prev);
    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div>
      {type === MatchTypesEnum.booked &&
        !isEventLive &&
        checkboxes.length > 0 && (
          <Checkbox
            label="Choose all"
            onChange={handleSelectAll}
            checked={selectAll}
          />
        )}
      <div className="booking-countries-content mt--8">
        {checkboxes.length ? (
          checkboxes.map(elem => (
            <Item
              isEventLive={isEventLive}
              checked={elem?.isChecked}
              key={elem.id}
              data={elem}
              type={type}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
          ))
        ) : (
          <Text>There are no elements</Text>
        )}
      </div>
    </div>
  );
};

export default Content;
