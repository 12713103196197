import {
  FC,
  memo,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Select } from '@shared_medialab/ui_components';
import { useQuery } from '@apollo/client';
import { GET_BUYER_ATTACHED_LEAGUES } from 'gql/leagues/queries';
import { BuyerAttachedLeagues } from 'gql/leagues/types/BuyerAttachedLeagues';
import * as permissionConstants from 'constants/permissions';

import { ILeaguesSelectProps } from './types';
import usePreviousNonNullish from 'hooks/usePreviousNonNullish';
import Private from '../Private';

const LEAGUE_PAGE_SIZE = 20;
const SCROLL_DIFF = 50;

const LeaguesSelect: FC<ILeaguesSelectProps> = ({
  placeholder,
  label,
  value,
  sportIds,
  regionIds,
  onChange
}) => {
  // refs
  const initialValue = useRef(value);
  const isScrolling = useRef(false);
  // states
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  // graphql
  const variables = useMemo(
    () => ({
      sportIds: sportIds,
      regionIds: regionIds,
      name: search,
      selected: initialValue.current
    }),
    [regionIds, search, sportIds]
  );

  const { data: leaguesData, fetchMore: fetchMoreLeagues } =
    useQuery<BuyerAttachedLeagues>(GET_BUYER_ATTACHED_LEAGUES, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: LEAGUE_PAGE_SIZE
          },
          name: search
        }
      },
      context: {
        debounceKey: 'leagues',
        debounceTimeout: 300
      }
    });

  const data = usePreviousNonNullish(leaguesData);

  const leagues = useMemo(
    () => data?.buyerAttachedLeagues?.data.results || [],
    [data]
  );

  const leaguesTotal = useMemo(
    () => data?.buyerAttachedLeagues?.data.total || 0,
    [data]
  );

  useEffect(() => {
    if (page > 0) {
      fetchMoreLeagues({
        variables: {
          query: {
            pagination: {
              page,
              limit: LEAGUE_PAGE_SIZE
            },
            name: search
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newResults =
            fetchMoreResult.buyerAttachedLeagues?.data.results || [];

          if (!prev.buyerAttachedLeagues?.data.results) return prev;

          isScrolling.current = false;

          return {
            ...prev,
            buyerAttachedLeagues: {
              ...prev.buyerAttachedLeagues,
              data: {
                ...prev.buyerAttachedLeagues.data,
                results: [
                  ...prev.buyerAttachedLeagues.data.results,
                  ...newResults
                ]
              }
            }
          };
        }
      });
    }
  }, [fetchMoreLeagues, page, search, variables]);

  const onScroll: UIEventHandler<HTMLDivElement | HTMLLIElement> = useCallback(
    e => {
      if (
        !isScrolling.current &&
        leagues.length &&
        leaguesTotal &&
        leagues.length <= leaguesTotal
      ) {
        const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

        const currentHeight = Math.ceil(scrollTop + clientHeight);

        if (currentHeight >= scrollHeight - SCROLL_DIFF) {
          setPage(prev => prev + 1);
          isScrolling.current = true;
        }
      }
    },
    [leagues.length, leaguesTotal]
  );

  return (
    <Select
      placeholder={placeholder}
      showSelectAll={false}
      label={label}
      options={leagues.map(item => ({
        subTitle: `${item?.regionName} - ID: ${item.creationId}`,
        label: item.name,
        value: item.creationId,
        icon: String(item?.sportName)
      }))}
      onScroll={onScroll}
      onChange={e => onChange(e as string[])}
      onSearch={e => {
        setPage(0);
        setSearch(e.target.value);
      }}
      onClose={() => {
        if (!value.length) setSearch('');
      }}
      searchValue={search}
      total={leaguesTotal}
      value={value}
      mode="multiple"
      cornerRadius="smooth"
    />
  );
};

const LeaguesSelectWrapper: FC<ILeaguesSelectProps> = props => (
  <Private
    routeId={permissionConstants.BUYER_ROUTES_IDS.reports}
    routeKey={permissionConstants.VIEW}
  >
    <LeaguesSelect {...props} />
  </Private>
);

export default memo(LeaguesSelectWrapper);
