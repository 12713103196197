import { FC, useCallback, useMemo, useState } from 'react';
import { useDayjs, usePermission } from 'hooks';
import {
  Icon,
  ActionSheet,
  SuccessToast,
  SportIcon,
  utils,
  useColors,
  useInViewport,
  Typography
} from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { REBOOK_MATCHES, UNBOOK_MATCHES } from 'gql/events/mutations';
import { MATCHES_LIST_FRAGMENT } from 'gql/events/fragments';
import { MatchTypesEnum, MATCH_STATUS_IDS } from 'constants/match';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  useEventsDispatchContext,
  useSelectedCountriesStateContext
} from 'providers/Events';
import { money } from 'helpers/money';
import { MatchesBookedFragment } from 'gql/events/types/MatchesBookedFragment';
import { ModalKeys } from 'providers/UI/types';
import { useUIDispatchContext } from 'providers/UI';
import * as permissionConstants from 'constants/permissions';
import { ActionsDataProps } from '@shared_medialab/ui_components/build/base/ActionSheet/types';
import { GET_CART } from 'gql/cart/queries';
import client from 'apolloClient';

import { IMatchItemProps } from './types';
import { RouteParams } from 'pages/Events/components/Content/types';
import CountrySelectNew from './components/CountrySelect';
// import Private from 'components/shared/Private';
import { ALL } from '../../constants';
import './index.css';
import useEllipsis from 'hooks/useEllipsesTooltip';
import { TooltipEllipses } from 'components/layout/Header/components/UserInfo/styled';
// TODO: from api
const testUrl =
  'https://vstreamapi.feedconstruct.com/api/streaming/20087906?signature=eyJpZCI6OTI1NiwidGltZSI6MTY1NTk3MDUwNiwidG9rZW4iOiJabUl6TlRjNU5UWmtPRFEwT0RKbVpqbGxNMlZtT1RFM01ESTVNMlZqT1RFPSIsInZhbGlkbWludXRlcyI6NSwicGFydG5lcl9pZCI6NjY2fQ==';

const { Text } = Typography;

const MatchSidebarItem: FC<IMatchItemProps> = ({
  id,
  teams,
  date,
  country,
  league,
  removed = false,
  status,
  sport,
  isShoppingCard = false,
  countries,
  // isLive,
  initialCountries,
  shouldApplyToAll = false,
  isThereEvents,
  outOfPackage,
  setDeletedCards
}) => {
  const [, ref] = useInViewport({ enabled: true });
  // translations
  const { t } = useTranslation(['messages', 'events', 'cart', 'common']);
  // hooks
  const { dayjs, timeFormat } = useDayjs();
  const { checkUserPermission } = usePermission();
  const colors = useColors();
  // navigation
  const { type: matchType } = useParams() as RouteParams;
  // states
  const [isPreview, setPreview] = useState(false);
  // context
  const { unselectItem } = useEventsDispatchContext();
  const { toggleModal } = useUIDispatchContext();

  const selectedCountries = useSelectedCountriesStateContext();

  const hasViewVideoPermission = useMemo(() => {
    return checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.booked,
      permissionConstants.VIEW_VIDEO
    );
  }, [checkUserPermission]);

  const hasUnBookPermission = useMemo(() => {
    return checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.booked,
      permissionConstants.UNBOOK
    );
  }, [checkUserPermission]);

  const price = useCallback(() => {
    const selectedNotBookedCountries = selectedCountries[id]?.filter(
      country => !country.booked
    );

    return (
      selectedNotBookedCountries?.reduce((acc, val) => {
        if (val?.country !== ALL) {
          return acc + Number(val.price);
        }

        return acc;
      }, 0) || 0
    );
  }, [id, selectedCountries]);

  // graphql
  const [unBookMatches] = useMutation(UNBOOK_MATCHES, {
    variables: {
      input: {
        ids: [id]
      }
    },
    update(cache) {
      const matchItem: MatchesBookedFragment | null = cache.readFragment({
        id: `Match:${id}`,
        fragment: MATCHES_LIST_FRAGMENT
      });

      if (matchItem) {
        cache.writeFragment({
          id: `Match:${id}`,
          fragment: MATCHES_LIST_FRAGMENT,
          data: {
            ...matchItem,
            removed: true
          }
        });
      }
    },
    onCompleted() {
      toast.success(
        <SuccessToast title={t('messages:unbooked')} text={t('unbooked')} />
      );
    }
  });

  const [rebookMatches] = useMutation(REBOOK_MATCHES, {
    variables: {
      input: {
        ids: [id]
      }
    },
    update(cache) {
      const matchItem: MatchesBookedFragment | null = cache.readFragment({
        id: `Match:${id}`,
        fragment: MATCHES_LIST_FRAGMENT
      });

      if (matchItem) {
        cache.writeFragment({
          id: `Match:${id}`,
          fragment: MATCHES_LIST_FRAGMENT,
          data: {
            ...matchItem,
            removed: false
          }
        });
      }
    },
    onCompleted() {
      toast.success(
        <SuccessToast title={t('messages:rebooked')} text={t('rebooked')} />
      );
    }
  });

  const onActionClicked = useCallback(() => {
    if (removed) {
      return rebookMatches();
    }

    unBookMatches();
  }, [rebookMatches, removed, unBookMatches]);

  const bookAction: ActionsDataProps[] = useMemo(() => {
    if (removed) {
      return [
        {
          icon: 'error-outline',
          title: t('rebook'),
          disable: false,
          footer: true,
          danger: true,
          onClick: onActionClicked
        }
      ];
    }

    if (hasUnBookPermission && !removed) {
      return [
        {
          icon: 'error-outline',
          title: t('unbook'),
          disable: status === MATCH_STATUS_IDS.live,
          footer: true,
          danger: true,
          onClick: onActionClicked
        }
      ];
    }

    return [];
  }, [hasUnBookPermission, onActionClicked, removed, status, t]);

  const viewVideoAction: ActionsDataProps[] = useMemo(() => {
    if (hasViewVideoPermission) {
      return [
        {
          icon: 'video',
          title: t('preview'),
          disable: removed,
          onClick: () => setPreview(prev => !prev)
        }
      ];
    }

    return [];
  }, [hasViewVideoPermission, removed, t]);

  const getHeight = () => {
    if (isShoppingCard) return 200;

    if (matchType === MatchTypesEnum.list) {
      return 170;
    }

    return 208;
  };

  const { hover, hasEllipses, handleMouseEnter, handleMouseLeave } =
    useEllipsis();

  return (
    <div
      className="flex-display flex-direction-column card-container relative-position"
      ref={ref}
      style={{ height: getHeight() }}
    >
      <div className="flex-display full-width relative-position card-header flex-align-items-center pv--12 ph--12">
        <div className="absolute-position card-sport-icon">
          <SportIcon
            name={sport}
            size={isShoppingCard ? utils.rem(16) : utils.rem(24)}
            color={colors.white}
          />
        </div>
        <div className="flex-display full-width flex-justify-center flex-align-items-center pl--48">
          <Text
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="hover_tooltip text-right flex-3"
            bold
            color="var(--white)"
            singleLine
          >
            {teams[0]}
            {hover && hasEllipses && (
              <TooltipEllipses width={300}>{teams[0]}</TooltipEllipses>
            )}
          </Text>
          {teams[1] && (
            <div className="flex-1 flex-justify-center flex-align-items-center">
              <div
                className={`mh--32 ${
                  !isShoppingCard ? 'p--12' : 'p--8'
                } vs-holder flex-justify-center flex-align-items-center`}
              >
                <Text uppercase color="var(--Orange__6)">
                  vs
                </Text>
              </div>
            </div>
          )}
          <Text
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="hover_tooltip flex-3 text-left"
            bold
            color="var(--white)"
            singleLine
          >
            {teams[1]}
            {hover && hasEllipses && (
              <TooltipEllipses width={250} left={150}>
                {teams[1]}
              </TooltipEllipses>
            )}
          </Text>
        </div>
      </div>
      <div className="flex-display full-height">
        <div className="flex-display flex-direction-column flex-justify-space-between flex-3">
          <div className="flex-display flex-align-items-center pl--12 pt--24">
            <Icon
              type="world"
              color="var(--gray)"
              size={14}
              className="mr--8"
            />
            <Text className="card-text" level={13} color="var(--text-color)">
              {country} - {league}
            </Text>
          </div>
          <div className="flex-display flex-align-items-center pl--12 mt--12">
            <Icon
              type="calendar"
              size={14}
              color={`var(--gray)`}
              className="mr--8"
            />
            <Text className="card-text" level={13} color="var(--text-color)">
              {dayjs(date).format(timeFormat)} -
              {dayjs(date).format('DD MMMM YYYY')}
            </Text>
          </div>
          <div className="flex-display card-select-container pt--12">
            {countries && initialCountries && (
              <div className="pb--8 ph--12 full-width">
                <CountrySelectNew
                  matchId={id}
                  disable={outOfPackage}
                  countries={countries}
                  shouldApplyToAll={shouldApplyToAll}
                  initialCountries={initialCountries}
                  className="full-width"
                />
              </div>
            )}
          </div>
          {/*<Private*/}
          {/*  routeKey={permissionConstants.DEMO_PREVIEW}*/}
          {/*  routeId={permissionConstants.BUYER_ROUTES_IDS.list}*/}
          {/*>*/}
          {/*  {isShoppingCard && (*/}
          {/*    <LivePreview disabled={!isLive} className="flex-1">*/}
          {/*      <IconWrapper>*/}
          {/*        <Icon type="play" size={24} color={`var(--primary-1)`} />*/}
          {/*      </IconWrapper>*/}
          {/*      <span className="text">{t('events:live_demo_preview')}</span>*/}
          {/*    </LivePreview>*/}
          {/*  )}*/}
          {/*</Private>*/}
          {outOfPackage && (
            <Text
              color="var(--danger-2)"
              className={`mh--24 pb--8 ${!isShoppingCard && 'p--16'}`}
            >
              {t('cart:out_of_package')}
            </Text>
          )}
          {(hasUnBookPermission || hasViewVideoPermission) &&
            !isShoppingCard &&
            matchType === MatchTypesEnum.booked && (
              <div>
                <ActionSheet
                  position="right-start"
                  data={[
                    ...viewVideoAction,
                    {
                      icon: 'copy-link',
                      title: t('events:open_in_new_tab'),
                      disable: removed,
                      onClick: () => {
                        window.open(
                          testUrl,
                          'targetWindow',
                          'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=600'
                        );
                      }
                    },
                    ...bookAction
                  ]}
                />
              </div>
            )}
        </div>
        <div className="flex-display flex-justify-center card-price-container flex-align-items-center flex-1 full-width">
          {isShoppingCard && (
            <div className="flex-display flex-direction-column flex-align-items-center flex-justify-center">
              <Text level={11} color="var(--Orange__6)" className="bold mb--12">
                {money(price())}
              </Text>
              <Text
                color="var(--text-color)"
                level={15}
                className="mt--12 card-text"
              >
                {t('cart:total_price')}
              </Text>
            </div>
          )}
        </div>
      </div>
      {isShoppingCard && (
        <div
          className="card-close-icon absolute-position pointer-cursor"
          onClick={() => {
            unselectItem(id, MatchTypesEnum.list);
            client.refetchQueries({ include: [GET_CART] });
            setDeletedCards && setDeletedCards(prev => [...prev, id]);

            if (isThereEvents === 1) {
              toggleModal(ModalKeys.shoppingCart, false);
            }
          }}
        >
          <Icon type="clear" size={24} color={'var(--gray)'} />
        </div>
      )}
      {isPreview && (
        <iframe
          className="event-video-preview"
          title="France (Mike) - Portugal (Nesta)"
          src={testUrl}
        ></iframe>
      )}
    </div>
  );
};

export default MatchSidebarItem;
