import { gql } from '@apollo/client';

export const CREATE_BUYER_USER = gql`
  mutation CreateBuyerUser($input: CreateBuyerUsersInput!) {
    createBuyerUser(input: $input) {
      message
    }
  }
`;

export const CREATE_OR_INVITE_BUYER_USER = gql`
  mutation CreateOrInviteBuyerUser($input: CreateOrInviteBuyerUsersInput!) {
    createOrInviteBuyerUser(input: $input) {
      message
    }
  }
`;
