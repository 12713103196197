import { useLazyQuery } from '@apollo/client';
import { GET_BUYER_ATTACHED_LEAGUES } from 'gql/leagues/queries';
import { useMemo, useEffect, useState, useRef } from 'react';
import { BuyerAttachedLeagues } from 'gql/leagues/types/BuyerAttachedLeagues';

import useInfiniteScroll from './useInfiniteScroll';
import usePreviousNonNullish from './usePreviousNonNullish';

export const LEAGUE_PAGE_SIZE = 20;

type Params = {
  sportIds?: string[];
  regionIds?: string[];
};

const useLeagues = ({ sportIds, regionIds }: Params) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  // states
  const [leagueSearch, setLeagueSearch] = useState('');
  // graphql
  const [
    fetchLeagues,
    { data: leaguesData, loading: leaguesLoading, fetchMore: fetchMoreLeagues }
  ] = useLazyQuery<BuyerAttachedLeagues>(GET_BUYER_ATTACHED_LEAGUES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const data = usePreviousNonNullish(leaguesData);
  // scroll
  const {
    onScroll: onLeaguesScroll,
    stopPaging: stopLeaguesPaging,
    setPage
  } = useInfiniteScroll({
    callback: page => {
      return fetchMoreLeagues({
        variables: {
          query: {
            pagination: {
              page,
              limit: LEAGUE_PAGE_SIZE
            }
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newResults =
            fetchMoreResult.buyerAttachedLeagues?.data.results || [];

          if (!prev.buyerAttachedLeagues?.data.results) return prev;

          return {
            ...prev,
            buyerAttachedLeagues: {
              ...prev.buyerAttachedLeagues,
              data: {
                ...prev.buyerAttachedLeagues.data,
                results: [
                  ...prev.buyerAttachedLeagues.data.results,
                  ...newResults
                ]
              }
            }
          };
        }
      });
    }
  });

  const leagues = useMemo(
    () => data?.buyerAttachedLeagues?.data.results || [],
    [data]
  );

  const leaguesTotal = useMemo(
    () => data?.buyerAttachedLeagues?.data.total || 0,
    [data]
  );

  useEffect(() => {
    if (leagues.length && leaguesTotal && leagues.length >= leaguesTotal) {
      stopLeaguesPaging();
    }
  }, [leaguesLoading, leagues.length, leaguesTotal, stopLeaguesPaging]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setPage(0);
      fetchLeagues({
        variables: {
          query: {
            name: leagueSearch,
            pagination: {
              page: 0,
              limit: LEAGUE_PAGE_SIZE
            }
          }
        }
      });
    }, 700);
  }, [fetchLeagues, leagueSearch, regionIds, setPage, sportIds]);

  return {
    leagues,
    leagueSearch,
    setLeaguesPage: setPage,
    fetchLeagues,
    onLeaguesScroll,
    onLeagueSearch: setLeagueSearch,
    leaguesTotal
  };
};

export default useLeagues;
