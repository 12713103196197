import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { parseJson } from 'utils/object';
import { UsersListFiltersState, OnChange } from './types';
import { removeSpaces } from 'utils/removeSpaces';

export const defaultUsersFilters = {
  name: '',
  role: [],
  status: []
};

const initialState: UsersListFiltersState = {
  filters: {
    ...defaultUsersFilters,
    ...parseJson(localStorage.getItem('userFilters') || {})
  }
};

export const usersFilterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setUserFilterItem: (state, action: PayloadAction<OnChange>) => {
      const { name, value } = action.payload;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.filters[name] = removeSpaces(value);
      localStorage.setItem('userFilters', JSON.stringify(state.filters));
    },
    resetUsersFilter: state => {
      state.filters = { ...defaultUsersFilters };
      localStorage.removeItem('userFilters');
    },
    initUsersFilters: state => {
      state.filters = {
        ...defaultUsersFilters,
        ...parseJson(parseJson(localStorage.getItem(`userFilters`) || {}))
      };
    }
  }
});

export const { setUserFilterItem, resetUsersFilter, initUsersFilters } =
  usersFilterSlice.actions;

export default usersFilterSlice.reducer;
