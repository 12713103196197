import { Select } from '@shared_medialab/ui_components';
import { USER_STATUSES } from 'constants/users';
import { useUserFormStateContext } from 'providers/UserForm';
import { FC, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IStatusProps } from './type';

const Status: FC<IStatusProps> = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { control } = useUserFormStateContext();

  const status = useWatch({
    name: 'project.status',
    control
  });

  const statusOptions = useMemo(() => {
    if (!status) {
      return Object.values(USER_STATUSES).map(item => item.value);
    }

    switch (status) {
      case USER_STATUSES[2].value:
        return [status, USER_STATUSES[3].value];
      case USER_STATUSES[1].value:
        return [status, USER_STATUSES[3].value];
      case USER_STATUSES[3].value:
        return [status, USER_STATUSES[2].value];
      default:
        return [status];
    }
  }, [status]);

  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {value && (
              <Select
                options={statusOptions.map(item => ({
                  label:
                    USER_STATUSES[item as keyof typeof USER_STATUSES]?.title,
                  value: item
                }))}
                hideSearch
                value={value}
                onChange={onChange}
                badge={{
                  title: '',
                  color:
                    USER_STATUSES[value as keyof typeof USER_STATUSES]?.color
                }}
                placeholder={'Status'}
                itemDisable={[value]}
                label={t('users:status')}
                cornerRadius="smooth"
              />
            )}
          </>
        );
      }}
      name="project.status"
      rules={{
        required: {
          value: true,
          message: t('messages:field_required')
        }
      }}
      control={control}
    />
  );
};

export default Status;
