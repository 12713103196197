import { FC } from 'react';
import Aside from '@shared_medialab/ui_components/build/ui/Aside';
import {
  Button,
  Col,
  Collapse,
  Loading,
  Row
} from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_AUTO_BOOKING_RULE_MINI,
  GET_AUTO_BOOKING_RULES_ORGANIZATION
} from 'gql/autobooking/queries';
import { toast } from 'react-toastify';
import { DELETE_AUTO_BOOKING_STATUS } from 'gql/autobooking/mutations';
import client from 'apolloClient';
import { AutoBookingRule } from 'gql/autobooking/types/AutoBookingRule';
import { useNavigate } from 'react-router-dom';
import * as permissionConstants from 'constants/permissions';
import { PACKAGE_LABELS } from 'constants/autobooking';

import {
  AsidePageWrapper,
  ButtonWrapper,
  StyledAside,
  StyledContent,
  StyledCountries,
  StyledCountry,
  StyledSubTitle,
  StyledTitle
} from './styled';
import { ConfirmModalTypes } from 'components/shared/ConfirmModal/types';
import Private from 'components/shared/Private';

const PackagesSidebar: FC = () => {
  // translations
  const { t } = useTranslation('autobooking');

  const { autoBooking } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // navigation
  const navigate = useNavigate();

  const [deleteAutoBookingRule] = useMutation(DELETE_AUTO_BOOKING_STATUS, {
    onCompleted() {
      client.refetchQueries({ include: [GET_AUTO_BOOKING_RULES_ORGANIZATION] });
      toggleModal(ModalKeys.autoBooking, false);
    },
    onError() {
      toast.error('Something went wrong');
    }
  });

  const { data, loading } = useQuery<AutoBookingRule>(
    GET_AUTO_BOOKING_RULE_MINI,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: autoBooking.params?.id
      },
      skip: !autoBooking?.params?.id
    }
  );

  const autoBookingRuleData = data?.autobookingRule?.data;
  const ruleCountries = data?.autobookingRule?.data.countries;
  const allowDeledeteAutoBookingRule = data?.autobookingRule?.data.type;

  const countries = () => {
    return ruleCountries?.map((elem, index) => (
      <div key={index}>
        <StyledCountry span={11}>{elem && elem.country}</StyledCountry>
      </div>
    ));
  };

  const onSubmitDelete = (id: string) => {
    deleteAutoBookingRule({
      variables: {
        id
      }
    });
  };

  const onClose = () => {
    toggleModal(ModalKeys.autoBooking, false);
  };

  return (
    <Aside
      position="right"
      icon="none"
      leftIcon="none"
      rightIcon="none"
      isOpen={autoBooking.visible}
      onChange={val => {
        toggleModal(ModalKeys.autoBooking, !val);
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <AsidePageWrapper>
          <StyledAside>
            <StyledTitle>
              <p>{autoBookingRuleData?.name}</p>
              <Button
                color="default"
                appearance="minimal"
                flexibility="content-size"
                size="big"
                icon="clear"
                onClick={onClose}
              />
            </StyledTitle>
            <StyledContent>
              {ruleCountries && !!ruleCountries.length && (
                <StyledSubTitle>{t('booking_countries')}</StyledSubTitle>
              )}
              <StyledCountries gutter={[8, 0]}>{countries()}</StyledCountries>
              {autoBookingRuleData?.sports?.length && (
                <StyledSubTitle>{t('booking_content')}</StyledSubTitle>
              )}
              <Collapse
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data={autoBookingRuleData?.sports}
                keys={['regions', 'leagues']}
                valueKey="creationId"
                maxHeight=""
              />
            </StyledContent>
          </StyledAside>
          <ButtonWrapper>
            <Row gutter={[4, 4]} align="center" justify="space-between">
              <Private
                routeId={permissionConstants.BUYER_ROUTES_IDS.autoBooking}
                routeKey={permissionConstants.DELETE}
              >
                <Col span={6}>
                  <Button
                    color="dangerNew"
                    cornerRadius="smooth"
                    appearance="default"
                    flexibility="full-width"
                    size="big"
                    disabled={
                      allowDeledeteAutoBookingRule === PACKAGE_LABELS.auto
                    }
                    onClick={() => {
                      toggleModal(ModalKeys.confirm, true, {
                        title: t('delete_question'),
                        message: t('delete_text'),
                        confirmText: t('yes'),
                        type: ConfirmModalTypes.dangerNew,
                        onConfirm: () => {
                          onSubmitDelete(String(autoBookingRuleData?.id));
                        }
                      });
                    }}
                  >
                    {t('delete')}
                  </Button>
                </Col>
              </Private>
              <Private
                routeId={permissionConstants.BUYER_ROUTES_IDS.autoBooking}
                routeKey={permissionConstants.EDIT}
              >
                <Col span={6}>
                  <Button
                    color="primaryNew"
                    cornerRadius="smooth"
                    appearance="default"
                    flexibility="full-width"
                    size="big"
                    onClick={() => {
                      toggleModal(ModalKeys.confirm, true, {
                        title: t('update_question'),
                        message: t('update_text'),
                        confirmText: t('yes'),
                        type: ConfirmModalTypes.dangerNew,
                        onConfirm: () => {
                          onClose();
                          navigate(
                            `/autobooking/details/${String(
                              autoBookingRuleData?.id
                            )}`
                          );
                        }
                      });
                    }}
                  >
                    {t('edit')}
                  </Button>
                </Col>
              </Private>
            </Row>
          </ButtonWrapper>
        </AsidePageWrapper>
      )}
    </Aside>
  );
};

export default PackagesSidebar;
