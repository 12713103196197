import {
  Icon,
  highlightedData,
  useClickOutside
} from '@shared_medialab/ui_components';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

import {
  StyledButton,
  StyledButtonSubMenu,
  StyledContent,
  StyledHeader,
  StyledIconRotate
} from './styled';
import { ISubMenuProps, SubMenuRefType } from './type';

const SubMenu: ForwardRefRenderFunction<SubMenuRefType, ISubMenuProps> = (
  { data, placeholder, searchedLetters },
  ref
) => {
  // states
  const [isOpened, setOpened] = useState(false);
  // refs
  const [containerRef, hasClickedOutside] = useClickOutside();

  useEffect(() => {
    if (hasClickedOutside) {
      setOpened(false);
    }
  }, [hasClickedOutside]);

  useImperativeHandle(ref, () => ({
    toggle: (value: boolean) => {
      setOpened(value);
    }
  }));

  return (
    <StyledButtonSubMenu ref={containerRef}>
      <StyledHeader onClick={() => setOpened(!isOpened)}>
        {placeholder}
        <StyledIconRotate isOpen={isOpened} show={true}>
          <Icon type="arrow-down" size={18} />
        </StyledIconRotate>
      </StyledHeader>

      <StyledContent isOpened={isOpened} itemsCount={data.length}>
        {data.map(item => (
          <StyledButton
            key={`project-select-button-${item.id}`}
            onClick={item.onClick}
          >
            <span>{highlightedData(item.name, searchedLetters)}</span>
          </StyledButton>
        ))}
      </StyledContent>
    </StyledButtonSubMenu>
  );
};

export default forwardRef(SubMenu);
