import { useQuery } from '@apollo/client';
import { Select } from '@shared_medialab/ui_components';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RolesForFilters } from 'gql/permissions/types/RolesForFilters';
import { GET_PARENT_ROLES, GET_ROLES } from 'gql/permissions/queries';
import { selectUsersFilters } from 'store/slice/filters/users/selectors';
import { setUserFilterItem } from 'store/slice/filters/users';
import { ParentRoles } from 'gql/permissions/types/ParentRoles';

import usePreviousNonNullish from 'hooks/usePreviousNonNullish';
import { IRolesSelectProps } from './type';
import { UserEnum } from 'pages/UserManagement/constants';

const RolesSelect: FC<IRolesSelectProps> = ({
  onChange,
  value,
  type = 'default',
  className = ''
}) => {
  // translations
  const { t } = useTranslation('users');
  // redux
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersFilters());
  // states
  const [roleSearch, setRoleSearch] = useState('');

  // graphql
  const { data: parentRoles } = useQuery<ParentRoles>(GET_PARENT_ROLES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const parentId = useMemo(
    () =>
      parentRoles?.roles?.data.find(item => item.name === UserEnum.buyer)?.id,
    [parentRoles?.roles?.data]
  );

  const { data: rolesData } = useQuery<RolesForFilters>(GET_ROLES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !parentId,
    variables: {
      query: {
        pagination: {
          page: 0,
          limit: 9999
        },
        name: roleSearch,
        parentId
      }
    }
  });

  const data = usePreviousNonNullish(rolesData);

  const roles = useMemo(() => data?.allRoles?.data.results || [], [data]);

  if (type === 'filter') {
    return (
      <Select
        className={className}
        mode="multiple"
        label={t('role')}
        placeholder={t('select_role')}
        options={
          roles?.map(item => ({
            label: item.name,
            value: item.id
          })) || []
        }
        onChange={value => {
          dispatch(
            setUserFilterItem({
              name: 'role',
              value
            })
          );
        }}
        onClose={() => {
          if (!users.role?.length) setRoleSearch('');
        }}
        onSearch={e => setRoleSearch(e.target.value)}
        searchValue={roleSearch}
        value={users.role}
        cornerRadius="smooth"
      />
    );
  }

  return (
    <Select
      mode="single"
      label={t('role')}
      placeholder={t('select_role')}
      options={
        roles?.map(item => ({
          label: item.name,
          value: item.id
        })) || []
      }
      onChange={onChange}
      onSearch={e => setRoleSearch(e.target.value)}
      searchValue={roleSearch}
      value={value}
      cornerRadius="smooth"
    />
  );
};

export default RolesSelect;
