import { ReactNode } from 'react';
import { MatchesListFragment } from 'gql/events/types/MatchesListFragment';

import { ConfirmModalTypes } from 'components/shared/ConfirmModal/types';

export const ActionTypes = {
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  TOGGLE_MODAL: 'TOGGLE_MODAL'
} as const;

export enum ModalKeys {
  confirm = 'confirm',
  shoppingCart = 'shoppingCart',
  autoBooking = 'autoBooking',
  settings = 'settings',
  userDetails = 'userDetails',
  bookingCountries = 'bookingCountries',
  unBook = 'unBookMatches'
}

export enum SidebarKeys {
  match = 'match',
  filters = 'filters',
  selectedPackages = 'selectedPackages',
  usersFilters = 'usersFilters',
  reportsFilters = 'reportsFilters'
}

export type DefaultSidebarParams = {
  visible: boolean;
};

export type SidebarTypeItems<T = void> = {
  visible: boolean;
  params?: T;
};

export type SelectedPackagesParams = {
  ids: string[];
};

export type SidebarParams = SelectedPackagesParams;

export type SideBarType = {
  [SidebarKeys.match]: DefaultSidebarParams;
  [SidebarKeys.filters]: DefaultSidebarParams;
  [SidebarKeys.selectedPackages]: SidebarTypeItems<SelectedPackagesParams>;
  [SidebarKeys.usersFilters]: DefaultSidebarParams;
  [SidebarKeys.reportsFilters]: DefaultSidebarParams;
};

export type ModalTypeItems<T = void> = {
  visible: boolean;
  params?: T;
};

export type UserParams = {
  userId: string;
};

type ConfirmParams = {
  onConfirm?: () => void;
  title?: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  type?: ConfirmModalTypes;
  reason?: boolean;
  onConfirmWithReason?: (value: string) => void;
  titlePosition?: boolean;
  placeholder?: string;
  titleColor?: string;
  loading?: boolean;
};
type SelectedBookingCard = {
  id: string;
};
type BookingCountriesParams = {
  id: string;
  item?: MatchesListFragment;
};

type UnBookParams = {
  id?: string;
  booked?: string[];
};

export type ModalParams =
  | ConfirmParams
  | SelectedBookingCard
  | UserParams
  | UnBookParams
  | BookingCountriesParams;

export type ModalType = {
  [ModalKeys.confirm]: ModalTypeItems<ConfirmParams>;
  [ModalKeys.shoppingCart]: ModalTypeItems;
  [ModalKeys.autoBooking]: ModalTypeItems<SelectedBookingCard>;
  [ModalKeys.settings]: ModalTypeItems;
  [ModalKeys.userDetails]: ModalTypeItems<UserParams>;
  [ModalKeys.bookingCountries]: ModalTypeItems<BookingCountriesParams>;
  [ModalKeys.unBook]: ModalTypeItems<UnBookParams>;
};

type ToggleSidebar = {
  type: typeof ActionTypes.TOGGLE_SIDEBAR;
  data: { key: keyof SideBarType; value: boolean; params?: SidebarParams };
};

type ToggleModal = {
  type: typeof ActionTypes.TOGGLE_MODAL;
  data: { key: keyof ModalType; value: boolean; params?: ModalParams };
};

export type ModalState = ModalType;

export type SidebarState = SideBarType;

export type State = {
  modal: ModalState;
  sidebar: SidebarState;
};

export type Action = ToggleSidebar | ToggleModal;

export type DispatchContext = {
  toggleSidebar: (
    key: keyof SideBarType,
    value: boolean,
    params?: SidebarParams
  ) => void;
  toggleModal: (
    key: keyof ModalType,
    value: boolean,
    params?: ModalParams
  ) => void;
};

export type Dispatch = (action: Action) => void;

export interface IUIProps {
  children: ReactNode;
}
