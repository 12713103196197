import { gql } from '@apollo/client';

import {
  AUTO_BOOKING_MINI_FRAGMENT,
  AUTO_BOOKING_FRAGMENT,
  AUTO_BOOKING_LIST_FRAGMENT,
  PARENT_RULE_FRAGMENT
} from './fragments';

export const GET_AUTO_BOOKING_RULES_ORGANIZATION = gql`
  query AutoBookingRulesOrganization($id: ID!, $query: AutoBookingQuery!) {
    autobookingRulesOrganization(id: $id, query: $query) {
      data {
        results {
          ...AutoBookingListFragment
          type
          frozenByParent
          contentGroupId
        }
        allowEditAutoBookingRule
        total
      }
    }
  }
  ${AUTO_BOOKING_LIST_FRAGMENT}
`;

export const GET_AUTO_BOOKING_RULE_MINI = gql`
  query AutoBookingRuleSidebarMini($id: ID!) {
    autobookingRule(id: $id) {
      data {
        ...AutoBookingMiniFragment
        allowEditAutoBookingRule
        type
        countries {
          id
          country
          autoBookingId
          createdAt
          updatedAt
        }
      }
    }
  }
  ${AUTO_BOOKING_MINI_FRAGMENT}
`;

export const GET_AUTO_BOOKING_RULE = gql`
  query AutoBookingRule($id: ID!) {
    autobookingRule(id: $id) {
      data {
        ...AutoBookingFragment
        parentRule {
          ...ParentRuleFragment
        }
      }
    }
  }
  ${AUTO_BOOKING_FRAGMENT}
  ${PARENT_RULE_FRAGMENT}
`;
