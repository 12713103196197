import { gql } from '@apollo/client';

export const GET_BUYER_EVENTS_REPORTS = gql`
  query BuyerMatchReports($query: BuyerReportQueryInput!, $partnerId: String!) {
    buyerMatchReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          id
          dimension_event
          name
          started_at
          stream_started_at
          total_sessions
          total_unique_views
          average_view_bandwidth_consumed {
            formattedValue
            value
          }
          average_view_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
          total_views_bandwidth_consumed {
            formattedValue
            value
          }
          total_views_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
        }
        total
      }
    }
  }
`;
//Overview
export const GET_BUYER_SESSIONS_REPORTS = gql`
  query BuyerSessionsReports(
    $query: BuyerReportQueryInput!
    $partnerId: String!
  ) {
    buyerSessionsReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          total_sessions
          total_unique_views
          average_view_bandwidth_consumed {
            formattedValue
            value
          }
          average_view_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
          total_views_bandwidth_consumed {
            formattedValue
            value
          }
          total_views_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
        }
      }
    }
  }
`;
//Unique View
export const GET_BUYER_VIEW_REPORTS = gql`
  query BuyerViewReports($query: BuyerReportQueryInput!, $partnerId: String!) {
    buyerViewReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          id
          range
          total_unique_views
        }
        total
      }
    }
  }
`;
//Popularity per Sport
export const GET_BUYER_SPORTS_REPORTS = gql`
  query BuyerSportsReports(
    $query: BuyerReportQueryInput!
    $partnerId: String!
  ) {
    buyerSportsReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          id
          dimension_sport
          sport_id
          total_sessions
          total_unique_views
          average_view_bandwidth_consumed {
            formattedValue
            value
          }
          average_view_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
          total_views_bandwidth_consumed {
            formattedValue
            value
          }
          total_views_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
        }
        total
      }
    }
  }
`;
//Popularity platform
export const GET_BUYER_PLATFORM_REPORTS = gql`
  query BuyerOsReports($query: BuyerReportQueryInput!, $partnerId: String!) {
    buyerOsReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          id
          dimension_device
          total_sessions
          total_unique_views
          average_view_bandwidth_consumed {
            formattedValue
            value
          }
          average_view_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
          total_views_bandwidth_consumed {
            formattedValue
            value
          }
          total_views_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
        }
        total
      }
    }
  }
`;
export const GET_BUYER_LOCATION_REPORTS = gql`
  query BuyerLocationReports(
    $query: BuyerReportQueryInput!
    $partnerId: String!
  ) {
    buyerLocationReports(query: $query, partnerId: $partnerId) {
      data {
        results {
          id
          total_sessions
          dimension_country
          total_unique_views
          average_view_bandwidth_consumed {
            formattedValue
            value
          }
          average_view_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
          total_views_bandwidth_consumed {
            formattedValue
            value
          }
          total_views_duration {
            formattedValue
            items {
              value
              type
            }
            value
          }
        }
        total
      }
    }
  }
`;
