import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { GET_AUTO_BOOKING_RULE } from 'gql/autobooking/queries';
import { AutoBookingRule } from 'gql/autobooking/types/AutoBookingRule';
import { useDayjs } from 'hooks';
import { createContext, useContext, FC, useMemo, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CollapseDataType } from '@shared_medialab/ui_components/build/base/Collapse/types';
import { LeagueSportIdsInput } from 'types/graphql-global';
import { useProjectStateContext } from 'providers/Projects';
import dayjs from 'dayjs';

import {
  FieldsValue,
  State,
  IAutoBookingProps,
  AutoBookingPriorityType
} from './types';
import { UserEnum } from 'pages/UserManagement/constants';

const AutoBookingStateContext = createContext<
  UseFormReturn<FieldsValue> | undefined
>(undefined);

const AutoBookingButtonStateContext = createContext<boolean>(true);

const AutoBookingSequenceContext = createContext<AutoBookingPriorityType>({
  countriesIsDisable: true,
  timeSectionIsDisable: true,
  contentIsDisable: true,
  sportIsDisable: true
});

const AutoBookingProvider: FC<IAutoBookingProps> = ({ children }) => {
  const { selectedProject } = useProjectStateContext();
  // hooks
  const { timezone } = useDayjs();
  // Navigation
  const { id } = useParams();
  // graphql
  const [getUserData, { data: meData }] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const { data } = useQuery<AutoBookingRule>(GET_AUTO_BOOKING_RULE, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id
    }
  });

  // form
  const form = useForm<FieldsValue>({
    defaultValues: {
      name: '',
      countries: meData?.me?.data.settings.countries || [],
      indefinite: true,
      timezone: timezone || '',
      days: {
        all: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      },
      isAutoBookingNewLeagues: false,
      startDate: new Date(),
      endDate: new Date(),
      startTime: '00:00',
      endTime: '23:59',
      sport: '',
      regions: [],
      search: '',
      packages: null,
      selectedSports: {},
      selectedSportsCheck: {},
      selectedSportsCount: {},
      selectedRegionsOnSport: [],
      selectedRegionsOnLeagues: [],
      completelyRegions: [],
      isAll: false,
      allowEditAutoBookingRule: true,
      parentRule: {}
    }
  });
  //TODO: comments related to content-group(ChoosePackage)
  // context
  // const { selectedPackagesSports, sportIds } = useChoosePackageStateContext();

  const autoBookData = useMemo(
    () => data?.autobookingRule?.data,
    [data?.autobookingRule?.data]
  );

  // const initialChecked = useMemo(() => {
  //   if (Object.keys(selectedPackagesSports).length) {
  //     return selectedPackagesSports;
  //-----
  // const initialChecked = useMemo(() => {
  //   if (Object.keys(selectedPackagesSports).length) {
  //     return selectedPackagesSports;
  //   }

  //   const checkedData = autoBookData?.sports;
  //   if (!checkedData?.length) return {};

  //   const values: Record<string, string[]> = {};

  //   const getIds = (children: CollapseDataType[], parentId: string) => {
  //     children.forEach(item => {
  //       const nested = Object.values(item).find(value =>
  //         Array.isArray(value)
  //       ) as CollapseDataType[];

  //       if (!parentId && item.creationId) {
  //         values[item.creationId] = [];
  //       }

  //       if (nested) {
  //         return getIds(nested, parentId || item.creationId || '');
  //       }

  //       values[parentId].push(item.creationId || '');
  //     });
  //   };

  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   getIds(checkedData, '');

  //   return values;
  // }, [autoBookData?.sports, selectedPackagesSports]);

  useEffect(() => {
    if (autoBookData) {
      const checkedData = autoBookData.sports && autoBookData.sports;
      const values: Record<string, string[]> = {};

      const getIds = (children: CollapseDataType[], parentId: string) => {
        children.forEach(item => {
          const nested = Object.values(item).find(value =>
            Array.isArray(value)
          ) as CollapseDataType[];

          if (!parentId && item.creationId) {
            values[item.creationId] = [];
          }

          if (nested) {
            return getIds(nested, parentId || item.creationId || '');
          }

          values[parentId].push(item.creationId || '');
        });
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getIds(checkedData, '');

      const selectedValuesCheck = checkedData?.map(elem => {
        const values: Record<string, string[]> = {};

        const getIds = (children: CollapseDataType[], parentId: string) => {
          children.forEach(item => {
            const nested = Object.values(item).find(value =>
              Array.isArray(value)
            ) as CollapseDataType[];

            if (!parentId && item.creationId) {
              values[item.creationId] = [];
            }

            if (nested) {
              return getIds(nested, parentId || item.creationId || '');
            }

            values[parentId].push(item.creationId || '');
          });
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getIds(elem.regions, '');

        return values;
      });

      const selectedSportsCountGet = Object.entries(values).reduce<
        Record<string, number[]>
      >((acc, current) => {
        const [key, value] = current;

        return {
          ...acc,
          [key]: [value.length]
        };
      }, {} as Record<string, number[]>);

      const selectedSportsCheckGet: { [key: string]: string[] } = {};

      selectedValuesCheck?.forEach(obj => {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            if (!selectedSportsCheckGet[key]) {
              selectedSportsCheckGet[key] = [];
            }

            selectedSportsCheckGet[key] = selectedSportsCheckGet[key].concat(
              obj[key]
            );
          }
        }
      });

      const selectedSportsGet: Record<string, LeagueSportIdsInput> = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          selectedSportsGet[key] = {
            sportId: key,
            leagueIds: values[key].slice()
          };
        }
      }

      // const days: Record<string, boolean> = Object.entries(
      //   autoBookData?.hours?.hours || {}
      // ).reduce((acc, item) => {
      //   const [key, val] = item;

      //   if (key === '__typename') return acc;

      //   return {
      //     ...acc,
      //     [key]: val.enabled
      //   };
      // }, {});

      // const isAllSelected = Object.values(days).every(val => val);

      // days.all = isAllSelected;

      const countries = autoBookData.countries?.map(item => item.id) || [''];

      const sports =
        // sportIds.length
        //   ? sportIds
        //   :
        autoBookData.sports?.map(item => item.creationId) || [''];

      const times = autoBookData?.hours?.hours?.friday?.hours.map(item => {
        return {
          start: item.start,
          end: item.end
        };
      });

      return form.reset({
        name: autoBookData?.name,
        countries: countries,
        indefinite: autoBookData?.indefinite,
        timezone: autoBookData?.timezone || '',
        // days,
        isAutoBookingNewLeagues: autoBookData?.isAutoBookingNewLeagues || false,
        startDate: dayjs(autoBookData?.startDate).toDate(),
        endDate: dayjs(autoBookData?.endDate).toDate(),
        startTime: times?.[0].start,
        endTime: '23:59',
        // endTime: times?.[0].end || '',
        sport: sports[0],
        regions: [],
        packages: null,
        selectedSports: selectedSportsGet,
        selectedSportsCheck: selectedSportsCheckGet,
        selectedSportsCount: selectedSportsCountGet,
        selectedRegionsOnLeagues: [],
        selectedRegionsOnSport: [],
        completelyRegions: [],
        isAll: false,
        allowEditAutoBookingRule:
          autoBookData?.allowEditAutoBookingRule || false,
        //get only that filds which is editable , talk with Gev
        parentRule: {
          name: autoBookData?.parentRule?.name,
          countries: autoBookData.parentRule?.countries?.map(
            item => item.id
          ) || [''],
          indefinite: autoBookData.parentRule?.indefinite,
          timezone: autoBookData.parentRule?.timezone || '',
          // days,
          startDate: new Date(autoBookData.parentRule?.startDate || ''),
          endDate: new Date(autoBookData.parentRule?.endDate || ''),
          startTime: times?.[0].start,
          endTime: times?.[0].end || '',
          sport: sports[0],
          sports: autoBookData.parentRule?.sports || [],
          regions: [],
          packages: null,
          selectedSports: selectedSportsGet,
          selectedSportsCheck: selectedSportsCheckGet,
          selectedSportsCount: selectedSportsCountGet,
          selectedRegionsOnLeagues: [],
          selectedRegionsOnSport: [],
          completelyRegions: [],
          leaguesCount: autoBookData?.parentRule?.leaguesCount,
          type: autoBookData?.parentRule?.type || 0
        }
      });
    } else {
      // form.setValue('selectedSports', initialChecked);
      // form.setValue('sport', sportIds[0]);
    }
  }, [
    autoBookData,
    form,
    id
    // initialChecked,
  ]);

  const formValues = form.watch();

  const isSubmitDisabled = useMemo(() => {
    if (Object.keys(form.formState.errors).length) return true;

    return Object.entries(formValues).some(field => {
      const [name, value] = field;

      switch (name) {
        case 'name':
          // case 'timezone':
          // case 'startTime':
          // case 'endTime':
          return !value;
        case 'countries':
          return !(value as [])?.length;
        // case 'days':
        //   return !value || !Object.values(value).some(item => item);

        case 'selectedSportsCheck': {
          const values = (value && Object.values(value)) || [];

          if (!values.length) return true;

          return values.some((item: string[]) => !(item as string[]).length);
        }

        default:
          return false;
      }
    });
  }, [form.formState.errors, formValues]);

  const sequence = useMemo(() => {
    // const daysIsDisabled =
    //   formValues.days && Object.values(formValues.days).some(item => item);

    const countriesIsDisable =
      !!formValues.name && !form.formState.errors.name?.message;

    const timeSectionIsDisable =
      countriesIsDisable && !!formValues.countries?.length;

    const contentIsDisable = timeSectionIsDisable;

    return {
      countriesIsDisable: !countriesIsDisable,
      timeSectionIsDisable: !timeSectionIsDisable,
      contentIsDisable: !contentIsDisable,
      sportIsDisable: !contentIsDisable
    };
  }, [form.formState.errors, formValues]);

  return (
    <AutoBookingStateContext.Provider value={form}>
      <AutoBookingButtonStateContext.Provider value={isSubmitDisabled}>
        <AutoBookingSequenceContext.Provider value={sequence}>
          {children}
        </AutoBookingSequenceContext.Provider>
      </AutoBookingButtonStateContext.Provider>
    </AutoBookingStateContext.Provider>
  );
};

const useAutoBookingStateContext = (): State => {
  const context = useContext(AutoBookingStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAutoBookingStateContext must be used within a AutoBookingStateContext'
    );
  }

  return context;
};

const useAutoBookingButtonStateContext = (): boolean => {
  const context = useContext(AutoBookingButtonStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAutoBookingButtonState must be used within a AutoBookingButtonState'
    );
  }

  return context;
};

const useAutoBookingSequenceContext = (): AutoBookingPriorityType => {
  const context = useContext(AutoBookingSequenceContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAutoBookingSequenceContext must be used within a useAutoBookingSequenceContext'
    );
  }

  return context;
};

export default AutoBookingProvider;
export {
  useAutoBookingStateContext,
  useAutoBookingButtonStateContext,
  useAutoBookingSequenceContext
};
