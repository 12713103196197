import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

import { IModalFooter } from './type';

export const ItemsContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spaces.xl} 0;
    gap: ${theme.spaces.xl};
    display: flex;
    flex-direction: column;
  `}
`;

export const TotalPrice = styled.div`
  p {
    font-weight: 500;
    opacity: 0.6;
  }
  h6 {
    font-weight: 700;
  }
  ${({ theme }) => css`
    p {
      font-size: ${theme.sizes.base};
    }
    h6 {
      font-size: ${theme.sizes.lg};
      padding-top: ${theme.spaces.sm};
    }
  `}
`;
export const ModalContent = styled.div`
  flex: 1 0 auto;
  max-height: calc(100vh - 142px);
  overflow-y: auto;
  position: relative;
  background-color: var(--Background__8);
  border-bottom: 1px solid var(--Background__9);
  ${({ theme }) => css`
    margin: ${theme.sizes.base} 0;
    padding: 0 ${theme.sizes.xxl};
  `}
`;

export const ModalFooter = styled.div<IModalFooter>`
  flex: 1 0 58px;
  max-height: 50px;
  display: flex;
  align-items: center;
  background-color: var(--Background__8);
  ${({ theme, justify = 'flex-end' }) => css`
    justify-content: ${justify};
    gap: ${theme.sizes.base};
    padding: 0 ${theme.sizes.xxl};
  `}
  button {
    width: 100px;
  }
`;
