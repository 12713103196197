import { ReverseMap } from '@shared_medialab/ui_components/build/utils/types';

export const getObjectValueByKeys = (
  key: string,
  obj: Record<string, unknown>
) => {
  const keys = key.split('.');

  // here we really need any :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return keys.reduce((acc: any, val) => {
    // this can work only with last index
    if (val.includes('index:') && Array.isArray(acc)) {
      const [, key] = val.split(':');

      return acc.map(item => item[key]);
    }

    if (acc[val]) {
      return acc[val];
    }
  }, obj);
};

export const parseJson = (json: unknown) => {
  try {
    const obj = JSON.parse(json as string);

    return obj || {};
  } catch (_) {
    return json || {};
  }
};

export const reverseObjectKeys = <T extends Record<string, string | number>>(
  obj: T
) => {
  return Object.entries(obj).reduce((acc, current) => {
    const [key, value] = current;

    return {
      ...acc,
      [value]: key
    };
  }, {} as ReverseMap<T>);
};
