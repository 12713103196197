import { useLazyQuery } from '@apollo/client';
import config from 'config';
import { GET_USER_DATA, GET_USER_ROLE_BY_ORGANIZATION } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import {
  useCallback,
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useProjectStateContext } from 'providers/Projects';
import { UserRole } from 'gql/auth/types/UserRole';
import { connectSocket } from 'core/socket';
import { io } from 'socket.io-client';
import { v4 as uuid } from 'uuid';
import { PERMISSION_ROUTE_PATHS } from 'constants/permissions';

import { IAuthProviderProps, PermissionsState, State } from './types';
import { UserEnum } from 'pages/UserManagement/constants';

const AuthStateContext = createContext<State | undefined>(undefined);
const PermissionsStateContext = createContext<PermissionsState>({});

const AuthDispatchContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

let isSocketConnected = false;

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  // context
  const { selectedProject } = useProjectStateContext();
  // states
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const queryToken =
    new URLSearchParams(window.location.search).get('token') || '';

  const storageToken = localStorage.getItem('token') || '';

  const [token, setToken] = useState(queryToken || storageToken);

  const [redirectPath, setRedirectPath] = useState('');
  // navigation
  const [, setSearch] = useSearchParams();
  // gql
  const [getUserRole, { data: roleData }] = useLazyQuery<UserRole>(
    GET_USER_ROLE_BY_ORGANIZATION
  );

  const [getUserData, { data }] = useLazyQuery<UserMe>(GET_USER_DATA, {
    onError() {
      if (config.env !== 'development') {
        localStorage.clear();
        setIsLoggedIn(false);
      }
    }
  });

  useEffect(() => {
    const actualToken = queryToken || storageToken;

    if (actualToken) {
      if (!storageToken) {
        localStorage.setItem('token', actualToken);
      }

      setToken(actualToken);
    }
  }, [queryToken, storageToken]);

  useEffect(() => {
    if (data?.me?.data) {
      const organizationId =
        localStorage.getItem('projectId') || selectedProject?.id;

      getUserRole({
        variables: {
          query: {
            ...(organizationId ? { organizationId } : {}),
            groupName: UserEnum.buyer
          }
        }
      });
    }
  }, [data?.me?.data, getUserRole, selectedProject?.id]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const projectId = search.get('project');

    if (token && token !== storageToken) {
      const sessionId = uuid();
      localStorage.setItem('sessionId', sessionId);
    }

    if (token && selectedProject?.id) {
      setIsLoggedIn(!!token);

      localStorage.setItem('token', token);

      if (queryToken) {
        search.delete('token');
      }

      if (projectId) {
        search.delete('project');
      }

      setSearch(search);
      const organizationId =
        projectId || localStorage.getItem('projectId') || selectedProject?.id;

      getUserData({
        variables: {
          ...(organizationId ? { organizationId } : {}),
          groupName: UserEnum.buyer
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }).finally(() => {
        if (organizationId) {
          localStorage.setItem('projectId', organizationId);
        }

        const lastRedirect = localStorage.getItem('lastRedirect');

        if (lastRedirect) {
          localStorage.removeItem('lastRedirect');
        }
      });
    }
  }, [
    getUserData,
    queryToken,
    selectedProject?.id,
    setSearch,
    storageToken,
    token
  ]);

  useEffect(() => {
    if (!isSocketConnected && token) {
      isSocketConnected = !isSocketConnected;
      connectSocket(io, token)
        .then(isConnected => {
          isSocketConnected = !!isConnected;
        })
        .catch(error => console.error(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoggedIn && config.sso_url) {
      window.location.href = config.sso_url;
    }
  }, [isLoggedIn]);

  const permissions: Record<string, number> = useMemo(
    () =>
      roleData?.userRole?.data?.permissions?.reduce(
        (acc, val) => ({
          ...acc,
          [val.name]: Number(val.value)
        }),
        {}
      ) || {},
    [roleData?.userRole?.data?.permissions]
  );

  useEffect(() => {
    const permissionValue = Object.keys(PERMISSION_ROUTE_PATHS).find(key => {
      const value = PERMISSION_ROUTE_PATHS[key];
      const permission = Object.keys(permissions).find(
        item => item === value.toString()
      );

      return !!permission;
    });

    if (permissionValue) {
      setRedirectPath(permissionValue);
    }
  }, [permissions]);

  const lastRedirect = localStorage.getItem('lastRedirect');

  const currentPath = window.location.pathname;

  const isPermittedCurrentPath =
    permissions &&
    PERMISSION_ROUTE_PATHS[currentPath] &&
    Object.keys(permissions).includes(
      PERMISSION_ROUTE_PATHS[currentPath].toString()
    );

  if (redirectPath && lastRedirect !== redirectPath) {
    localStorage.setItem('lastRedirect', redirectPath);

    if (!isPermittedCurrentPath) {
      return <Navigate to={redirectPath} />;
    }
  } else if (!isPermittedCurrentPath) {
    localStorage.removeItem('lastRedirect');
  }

  return (
    <AuthDispatchContext.Provider value={setIsLoggedIn}>
      <AuthStateContext.Provider
        value={{ isLoggedIn, user: data?.me?.data || null }}
      >
        <PermissionsStateContext.Provider value={permissions}>
          {children}
        </PermissionsStateContext.Provider>
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

const useAuthStateContext = (): State => {
  const context = useContext(AuthStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAuthStateContext must be used within a AuthStateContext'
    );
  }

  return context;
};

const usePermissionByIdStateContext = (id: number): number => {
  const context = useContext(PermissionsStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'usePermissionByIdStateContext must be used within a PermissionsStateContext'
    );
  }

  return context[`${id}`];
};

const usePermissionsStateContext = (): PermissionsState => {
  const context = useContext(PermissionsStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'usePermissionsStateContext must be used within a PermissionsStateContext'
    );
  }

  return context;
};

const useAuthDispatchContext = (): {
  logOut: () => void;
} => {
  const setIsLoggedIn = useContext(AuthDispatchContext);

  if (typeof setIsLoggedIn === 'undefined') {
    throw new Error(
      'useAuthDispatchContext must be used within a useAuthDispatchContext'
    );
  }

  const logOut = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('selectedProject');
    localStorage.removeItem('lastRedirect');
    localStorage.removeItem('projectId');
    setIsLoggedIn(false);
  }, [setIsLoggedIn]);

  return { logOut };
};

export default AuthProvider;
export {
  useAuthStateContext,
  usePermissionByIdStateContext,
  usePermissionsStateContext,
  useAuthDispatchContext
};
