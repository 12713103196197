import { FC, memo, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Select } from '@shared_medialab/ui_components';
import { GET_SPORTS_BY_ATTACHED_LEAGUE } from 'gql/sports/queries';
import { GET_SPORTS_FOR_PACKAGE } from 'gql/contentPackages/queries';

import usePreviousNonNullish from 'hooks/usePreviousNonNullish';
import { ISportSelectProps } from './types';

const SportSelect: FC<ISportSelectProps> = ({
  placeholder,
  label,
  value,
  regionIds,
  isLeague,
  onChange
}) => {
  // states
  const [search, setSearch] = useState('');
  // graphql

  const [getSports, { data: sportsData }] = useLazyQuery<any>(
    isLeague ? GET_SPORTS_FOR_PACKAGE : GET_SPORTS_BY_ATTACHED_LEAGUE,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const data = usePreviousNonNullish(sportsData);

  const sports = isLeague
    ? data?.sports?.data.results || []
    : data?.sportsByAttachedLeagues?.data || [];

  // TODO: organizationId ERROR: organizationId must be  with ? in back

  useEffect(() => {
    getSports({
      variables: {
        query: {
          name: search
        }
      },
      context: {
        debounceKey: 'sports',
        debounceTimeout: 700
      }
    });
  }, [search, regionIds, getSports]);

  return (
    <Select
      placeholder={placeholder}
      label={label}
      options={sports.map((item: { name: string; creationId: string }) => ({
        label: item.name,
        value: item.creationId
      }))}
      onChange={e => onChange(e as string[])}
      onSearch={e => setSearch(e.target.value)}
      searchValue={search}
      value={value}
      mode="multiple"
      cornerRadius="smooth"
      onClose={() => {
        if (!value.length) setSearch('');
      }}
    />
  );
};

export default memo(SportSelect);
