import dayjs, { Dayjs, OpUnitType } from 'dayjs';

// TODO: move to translations
const monthNames = {
  MMM: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  MMMM: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Jun',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

type TimeFormat = {
  hours?: 'h' | 'hh';
  minutes?: 'm' | 'mm';
};

type DateFormat = TimeFormat & {
  months?: keyof typeof monthNames;
};

export const getNextMonth = (date = new Date()) => {
  return new Date(date.setMonth(date.getMonth() + 1));
};

export const getTime = (date: Date, format: TimeFormat): string => {
  const { hours: h, minutes: m } = format || {};
  const result: string[] = [];

  switch (h) {
    case 'h':
      result.push(String(date.getHours()));
      break;

    case 'hh': {
      const hours = date.getHours();

      result.push(`${hours > 9 ? hours : `0${hours}`}`);
      break;
    }
  }

  switch (m) {
    case 'm':
      result.push(String(date.getMinutes()));
      break;

    case 'mm': {
      const minutes = date.getMinutes();

      result.push(`${minutes > 9 ? minutes : `0${minutes}`}`);
      break;
    }
  }

  return result.join(':');
};

export const formatDate = (date: Date, format?: DateFormat): string => {
  const { months: M = 'MMM', hours: h, minutes: m } = format || {};

  const year = date.getFullYear();
  const month = date.getMonth();
  const dayNumber = date.getDate();

  const day = dayNumber > 9 ? dayNumber : `0${dayNumber}`;

  const result = [
    day,
    monthNames[M][month],
    year,
    getTime(date, { hours: h, minutes: m })
  ];

  return result.join(' ');
};

export const checkDateDifference = (date1: Date, date2: Date) => {
  const isTheSameYear = date1.getFullYear() === date2.getFullYear();

  if (!isTheSameYear) return false;

  const isTheSameMonth = date1.getMonth() === date2.getMonth();

  if (!isTheSameMonth) return false;

  const isTheSameDay = date1.getDate() === date2.getDate();

  if (!isTheSameDay) return false;

  const hourDiff = Math.abs(date1.getHours() - date2.getHours());

  if (hourDiff > 1) return false;

  return true;
};

export const getTimeZoneNames = (search = '') => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const timezones: string[] = Intl.supportedValuesOf('timeZone').filter(item =>
    item.toLowerCase().includes(search.toLowerCase())
  );

  return timezones;
};

//!TODO delete this if don't use old datepickers
export const getStaticRanges =
  (unit: OpUnitType, date = new Date()) =>
  () => ({
    startDate: dayjs(date).startOf(unit).toDate(),
    endDate: dayjs(date).endOf(unit).toDate()
  });

export const getStaticRangesArray = (
  unit: OpUnitType,
  date = new Date()
): [Dayjs, Dayjs] => [dayjs(date).startOf(unit), dayjs(date).endOf(unit)];
