import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Typography } from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { MatchTypesEnum } from 'constants/match';
import { useStatusState } from 'hooks';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';

import { IMatchesGroupTitleProps } from './types';
import { RouteParams as EventsRouterParams } from 'pages/Events/components/Content/types';

const { Text } = Typography;

const MatchesGroupTitle: FC<IMatchesGroupTitleProps> = ({
  date,
  count,
  checked,
  action
}) => {
  // translations
  const { t } = useTranslation('events');
  //navigation
  const { type } = useParams() as EventsRouterParams;
  const onlyListShown = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  return (
    <div className="flex-display flex-justify-space-between p--16">
      <Text level={13}>{date}</Text>
      {type !== MatchTypesEnum.history && (
        <div className="flex-display flex-align-items-center">
          <Checkbox
            onChange={action}
            checked={checked}
            disabled={onlyListShown}
          />
          <Text level={13}>{t('select_items', { count })}</Text>
        </div>
      )}
    </div>
  );
};

export default MatchesGroupTitle;
