import styled from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import { css } from 'styled-components';

import { IDateWrapperProps, IIconProps, ISwitchIconsProps } from './types';

export const StyledChartHeader = styled.div`
  position: relative;
  ${({ theme }) => css`
    background-color: var(--primary-bg-1);
    padding: ${theme.sizes.xs} ${theme.sizes.base};
    border-bottom: 1px solid ${rgba(theme.colors.gray[5], 0.1)};
  `}
`;

export const StyledSwitch = styled.div<ISwitchIconsProps>`
  height: 30px;
  width: ${({ isType }) => (isType === 'threePage' ? '95px' : '65px')};
  cursor: pointer;
  ${({ theme }) => css`
    background-color: var(--primary-bg-6);
    border-radius: ${theme.spaces.lg};
    padding: ${theme.spaces.xs};
  `}
  &:after {
    transition: 0.4s;
    content: '';
    position: absolute;
    top: 2px;
    ${({ movement }) => css`
      right: ${movement === 'table' && '4px'} ${movement === 'chart' && '35px'}
        ${movement === 'three' && '65px'};
    `}
    width: 34px;
    height: 34px;
    z-index: 0;
    ${({ theme }) => css`
      border-radius: ${theme.spaces.lg};
      background-color: ${rgba(theme.colors.orange[5], 1)};
    `}
  }
`;

export const StyledSwitchIcon = styled.div<IIconProps>`
  position: absolute;
  ${({ position }) => css`
    right: ${position === 'left' && '7px'} ${position === 'three' && '70px'}
      ${position === 'right' && '40px'};
    top: ${position === 'left' && '7px'} ${position === 'right' && '7px'}
      ${position === 'three' && '6px'};
  `}
  z-index: 2;
`;

export const StyledDateWrapper = styled.div<IDateWrapperProps>`
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  width: 260px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  ${({ hover }) => css`
    border: ${hover && '1px solid var(--primary-1)'};
  `}
`;
