import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

import { IStyledButton } from './types';

const buttonActiveStyles = css`
  background: var(--primary-6);
  border: 1px solid var(--primaryNew);
`;

export const StyledButton = styled.button<IStyledButton>`
  cursor: pointer;
  outline: none;
  //border: 1px solid var(--light-bg);
  border-radius: 4px;
  width: 34px;
  height: 34px;
  //background: rgba(255, 255, 255, 0.1);
  background: var(--steel-blue);
  transition: all 0.3s linear;
  i {
    color: var(--white);
  }
  &:hover {
    ${buttonActiveStyles}
  }
  ${({ active }: IStyledButton) => active && buttonActiveStyles}
`;

export const StyledPopover = styled.div`
  width: 270px;
  background-color: var(--Background__7);
`;

export const TooltipEllipses = styled.div<{ width?: number; left?: number }>`
  position: absolute;
  background-color: var(--lavender);
  color: var(--black);
  ${({ width, left }) => css`
    width: ${width ? `${width}px` : '150%'};
    left: ${left ? `${left}px` : '0'};
  `}
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 99999;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  visibility: hidden;
  overflow: hidden;
  .user_email:hover &,
  .hover_tooltip:hover & {
    visibility: visible;
  }
`;

export const Marquee = styled.div`
  overflow: hidden;
  white-space: nowrap;
  animation: scroll 10s linear infinite alternate;
  @keyframes scroll {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const MenuContent = styled.ul`
  list-style: none;
  background-color: var(--Background__7);
  li {
    background-color: var(--Background__7);
    transition: background-color 0.3s;
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--Background__8);
      transition: width 0.3s;
    }
    &:hover {
      span {
        color: var(--vivid-cerulean);
        svg > path {
          fill: var(--vivid-cerulean);
        }
      }
      background-color: var(--Background__5);
    }
  }
`;
