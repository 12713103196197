import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@shared_medialab/ui_components';

import { IErrorPreviewProps } from './types';
import './index.css';

const { Text } = Typography;

const ErrorPreview: FC<IErrorPreviewProps> = ({ massage }) => {
  // translations
  const { t } = useTranslation(['events']);

  return (
    <div className="error-def-text flex-display flex-direction-column flex-justify-center flex-align-items-center ">
      {massage?.length ? (
        <Text color="var(--text-color)" className="text-center error-text">
          {massage}
        </Text>
      ) : (
        <Text color="var(--text-color)" className="text-center error-text">
          {t('events:stream_demo1')}
        </Text>
      )}
    </div>
  );
};

export default memo(ErrorPreview);
