import styled from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import { css } from 'styled-components';

export const StyledPartnersInfo = styled.div`
  width: 100%;
  ${({ theme }) => css`
    padding: ${theme.spaces.lg};
    background-color: ${rgba(theme.colors.white, 1)};
  `}
`;
