import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Icon, Typography } from '@shared_medialab/ui_components';
import { ModalKeys } from 'providers/UI/types';
import { useEventsSelectedStateContext } from 'providers/Events';
import { useUIDispatchContext } from 'providers/UI';
import { useStatusState } from 'hooks';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';

import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';
import { StyledButton } from './styled';

const { Text } = Typography;

const ShoppingCard: FC = () => {
  const selected = useEventsSelectedStateContext();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { toggleModal } = useUIDispatchContext();
  //graphql
  const { results: upcoming } = useEventsUpcomingCache();

  const updatedUpcomingIds = useMemo(
    () => upcoming.map(elem => elem.id),
    [upcoming]
  );

  const updatedCount = useMemo(
    () => selected?.list.filter(elem => updatedUpcomingIds.includes(elem)),
    [selected?.list, updatedUpcomingIds]
  );

  const reportAndHistory = useStatusState({
    product: [ProductStatuses.inactive],
    bme: [
      BmeStatuses.live,
      BmeStatuses.trial,
      BmeStatuses.terminated,
      BmeStatuses.lost,
      BmeStatuses.in_active
    ],
    project: [ProjectStatuses.terminated]
  });

  const onlyList = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  const onButtonClick = useCallback(() => {
    if (!reportAndHistory && !onlyList && updatedCount.length) {
      setIsOpenModal(true);
      toggleModal(ModalKeys.shoppingCart, true);
    } else {
      toggleModal(ModalKeys.shoppingCart, false);
    }

    setIsOpenModal(false);
  }, [onlyList, reportAndHistory, toggleModal, updatedCount.length]);

  useEffect(() => {
    if (reportAndHistory || onlyList) {
      toggleModal(ModalKeys.shoppingCart, false);
    }
  }, [onlyList, reportAndHistory, toggleModal]);

  return (
    <StyledButton
      className={`flex-display flex-justify-center flex-align-items-center relative-position ${
        selected?.list.length ? 'pointer-cursor' : ''
      } p--12`}
      onClick={onButtonClick}
      active={isOpenModal}
      countBadgeVisible={!!selected?.list.length}
    >
      <Icon type="c-shopping-cart" size={18} color="var(--white)" />
      <Text color="var(--white)" className="ml--4">{`Cart (${
        reportAndHistory || onlyList ? 0 : updatedCount.length
      })`}</Text>
    </StyledButton>
  );
};

export default memo(ShoppingCard);
