import { FC } from 'react';
import { Typography } from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MATCH_STATUSES_VALUES, MatchTypesEnum } from 'constants/match';

import Checkbox from './components/Checkbox';
import { RouteParams } from '../Content/types';

const { Text } = Typography;

const FilterEventsSidebarCheckbox: FC = () => {
  const { type } = useParams() as RouteParams;
  const { t } = useTranslation('events');

  return (
    <div className="flex-display flex-direction-column">
      <Text level={13} color="var(--text)" className="mb--16">
        {t('stream_status')}
      </Text>
      {type === MatchTypesEnum.history ? (
        <div className="flex-display flex-align-items-center">
          <Checkbox
            status={MATCH_STATUSES_VALUES.finished}
            className="mr--32"
          />
          <Checkbox status={MATCH_STATUSES_VALUES.canceled} />
        </div>
      ) : (
        <div className="flex-display flex-align-items-center">
          <Checkbox
            status={MATCH_STATUSES_VALUES.not_started}
            className="mr--32"
          />
          <Checkbox status={MATCH_STATUSES_VALUES.started} />
        </div>
      )}
    </div>
  );
};

export default FilterEventsSidebarCheckbox;
