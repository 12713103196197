import { FC } from 'react';
import AppRouter from 'AppRouter';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'react-toastify/dist/ReactToastify.css';
import '@shared_medialab/ui_components/build/index.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const App: FC = () => {
  return (
    <>
      <AppRouter />
      <ToastContainer hideProgressBar />
    </>
  );
};

export default App;
