import { FC, useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';

import { StyledSkeleton } from './styled';

const data = new Array(30).fill(1);

const MatchSkeleton: FC = () => {
  // refs
  const parentRef = useRef<HTMLDivElement | null>(null);
  // states
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(parentRef.current?.clientWidth || 800);

    const onResize = () => {
      setWidth(parentRef.current?.clientWidth || 800);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div ref={parentRef} className="mt--24">
      {data.map((_, index) => (
        <StyledSkeleton key={`match-skeleton-${index}`}>
          <ContentLoader
            speed={1}
            width={width}
            height={32}
            viewBox={`0 0 ${width} 32`}
            backgroundColor="#ffffff"
            foregroundColor="#ecebeb"
          >
            <rect
              x={width * 0.03}
              y="11"
              rx="5"
              ry="5"
              width={width * 0.01}
              height="10"
            />
            <rect
              x={width * 0.05}
              y="11"
              rx="5"
              ry="5"
              width={width * 0.05}
              height="10"
            />
            <rect
              x={width * 0.2}
              y="11"
              rx="10"
              ry="10"
              width={width * 0.2}
              height="10"
            />
            <rect
              x={width * 0.5}
              y="11"
              rx="5"
              ry="5"
              width={width * 0.05}
              height="10"
            />
            <rect
              x={width * 0.65}
              y="11"
              rx="10"
              ry="10"
              width={width * 0.2}
              height="10"
            />
          </ContentLoader>
        </StyledSkeleton>
      ))}
    </div>
  );
};

export default MatchSkeleton;
