import { createContext, useReducer, useContext, FC, useCallback } from 'react';

import {
  State,
  Dispatch,
  DispatchContext,
  ActionTypes,
  SideBarType,
  ModalState,
  SidebarState,
  ModalType,
  ModalParams,
  IUIProps,
  SidebarKeys,
  SidebarParams,
  ModalKeys
} from './types';
import reducer from './reducers';

const initialState: State = {
  sidebar: {
    [SidebarKeys.match]: { visible: true },
    [SidebarKeys.filters]: { visible: true },
    [SidebarKeys.selectedPackages]: { visible: true },
    [SidebarKeys.usersFilters]: { visible: true },
    [SidebarKeys.reportsFilters]: { visible: true }
  },
  modal: {
    confirm: { visible: false },
    shoppingCart: { visible: false },
    [ModalKeys.settings]: { visible: false },
    [ModalKeys.autoBooking]: { visible: false },
    [ModalKeys.userDetails]: { visible: false },
    [ModalKeys.bookingCountries]: { visible: false },
    [ModalKeys.unBook]: { visible: false }
  }
};

const SidebarStateContext = createContext<SidebarState | undefined>(undefined);
const ModalStateContext = createContext<ModalState | undefined>(undefined);
const UIDispatchContext = createContext<Dispatch | undefined>(undefined);

const UIProvider: FC<IUIProps> = ({ children }) => {
  const [{ sidebar, modal }, dispatch] = useReducer(reducer, {
    ...initialState
  });

  return (
    <SidebarStateContext.Provider value={sidebar}>
      <ModalStateContext.Provider value={modal}>
        <UIDispatchContext.Provider value={dispatch}>
          {children}
        </UIDispatchContext.Provider>
      </ModalStateContext.Provider>
    </SidebarStateContext.Provider>
  );
};

const useSidebarStateContext = (): SidebarState => {
  const context = useContext(SidebarStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useSidebarStateContext must be used within a SidebarStateContext'
    );
  }

  return context;
};

const useModalStateContext = (): ModalState => {
  const context = useContext(ModalStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useModalStateContext must be used within a ModalStateContext'
    );
  }

  return context;
};

const useUIDispatchContext = (): DispatchContext => {
  const dispatch = useContext(UIDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useUIDispatchContext must be used within a useUIDispatchContext'
    );
  }

  const toggleSidebar = useCallback(
    (key: keyof SideBarType, value: boolean, params?: SidebarParams) => {
      dispatch({
        type: ActionTypes.TOGGLE_SIDEBAR,
        data: { key, value, params }
      });
    },
    [dispatch]
  );

  const toggleModal = useCallback(
    (key: keyof ModalType, value: boolean, params?: ModalParams) => {
      dispatch({
        type: ActionTypes.TOGGLE_MODAL,
        data: { key, value, params }
      });
    },
    [dispatch]
  );

  return { toggleSidebar, toggleModal };
};

export default UIProvider;
export { useUIDispatchContext, useSidebarStateContext, useModalStateContext };
