import { Badge } from '@shared_medialab/ui_components';
import { ColumnType } from '@shared_medialab/ui_components/build/base/Table/types';
import { USER_STATUSES } from 'constants/users';
import { BuyerUsers_buyerUsers_data_results as ColumnsDataType } from 'gql/users/types/BuyerUsers';

import { reverseObjectKeys } from 'utils/object';
// redux

export const USERS_COLUMNS: ColumnType<ColumnsDataType>[] = [
  {
    name: 'id',
    label: 'name',
    render: record => `${record.firstName} ${record.lastName}`
  },
  {
    name: 'role',
    label: 'role',
    render: record => {
      const tole = record.role;

      if (!tole) {
        return null;
      }

      return tole.name;
    }
  },
  {
    name: 'status',
    label: 'status',
    render: record => {
      const key = record?.role?.status || record?.status;

      if (!key) {
        return '-';
      }

      const status = USER_STATUSES[key as keyof typeof USER_STATUSES];

      return <Badge title={status.title} color={status.color || '#D2D6EA'} />;
    }
  }
];

export const ROLES_VALUES = {
  moderator: '1',
  provider: '2',
  buyer: '3'
} as const;

export enum UserEnum {
  provider = 'provider',
  buyer = 'buyer',
  admin = 'admin'
}

export const USERS_FILTERS = 'USERS_FILTERS' as const;

export type projectNames = {
  '2': string;
  '3': string;
};
export const PROJECTS_NAMES = {
  '2': 'providersProjects',
  '3': 'buyersProjects'
} as const;

export enum ProjectsRoleEnum {
  edit = 'edit',
  addToProject = 'addToProject'
}

export const ROLES_LABELS = reverseObjectKeys(ROLES_VALUES);
