export const COUNTRIES_COLORS = {
  Afghanistan: '#F49EB4',
  Albania: '#F9BFCE',
  Algeria: '#FDC5BB',
  'American Samoa': '#F5CEC7',
  AndorrA: '#A77CD3',
  Angola: '#C9AEDF',
  Anguilla: '#C37CD3',
  'Antigua and Barbuda': '#DBAEDF',
  Argentina: '#7A8AFC',
  Armenia: '#9DA8FB',
  Aruba: '#DFE3F9',
  Australia: '#ACCAE5',
  Austria: '#78C6F3',
  Azerbaijan: '#B3DCF4',
  Bahamas: '#A7D7D7',
  Bahrain: '#CDE8EF',
  Bangladesh: '#A1D5A9',
  Barbados: '#BDEBC4',
  Belarus: '#D7DB69',
  Belgium: '#D3D4AC',
  Belize: '#F4CB60',
  Benin: '#FFDE8B',
  Bermuda: '#F48460',
  Bhutan: '#F4AF60',
  Bolivia: '#F7AA93',
  'Bosnia and Herzegovina': '#FFC98B',
  Botswana: '#F49EB4',
  Brazil: '#F9BFCE',
  Bulgaria: '#FDC5BB',
  'Burkina Faso': '#F5CEC7',
  Burundi: '#A77CD3',
  Cambodia: '#C9AEDF',
  Cameroon: '#C37CD3',
  Canada: '#DBAEDF',
  'Cape Verde': '#7A8AFC',
  'Cayman Islands': '#9DA8FB',
  'Central African Republic': '#DFE3F9',
  Chad: '#ACCAE5',
  Chile: '#78C6F3',
  China: '#B3DCF4',
  Colombia: '#A7D7D7',
  Comoros: '#CDE8EF',
  Congo: '#A1D5A9',
  'Cook Islands': '#BDEBC4',
  'Costa Rica': '#D7DB69',
  "Cote D'Ivoire": '#D3D4AC',
  Croatia: '#F4CB60',
  Cuba: '#FFDE8B',
  Cyprus: '#F48460',
  'Czech Republic': '#F4AF60',
  Denmark: '#F7AA93',
  Djibouti: '#FFC98B',
  Dominica: '#F49EB4',
  'Dominican Republic': '#F9BFCE',
  Ecuador: '#FDC5BB',
  Egypt: '#F5CEC7',
  'El Salvador': '#A77CD3',
  'Equatorial Guinea': '#C9AEDF',
  Eritrea: '#C37CD3',
  Estonia: '#DBAEDF',
  Ethiopia: '#7A8AFC',
  'Faroe Islands': '#9DA8FB',
  Fiji: '#DFE3F9',
  Finland: '#ACCAE5',
  France: '#78C6F3',
  'French Guiana': '#B3DCF4',
  'French Polynesia': '#A7D7D7',
  Gabon: '#CDE8EF',
  Gambia: '#A1D5A9',
  Georgia: '#BDEBC4',
  Germany: '#D7DB69',
  Ghana: '#D3D4AC',
  Gibraltar: '#F4CB60',
  Greece: '#FFDE8B',
  Greenland: '#F48460',
  Grenada: '#F4AF60',
  Guadeloupe: '#F7AA93',
  Guam: '#FFC98B',
  Guatemala: '#F49EB4',
  Guernsey: '#F9BFCE',
  Guinea: '#FDC5BB',
  'Guinea-Bissau': '#F5CEC7',
  Guyana: '#A77CD3',
  Haiti: '#C9AEDF',
  Honduras: '#C37CD3',
  'Hong Kong': '#DBAEDF',
  Hungary: '#7A8AFC',
  Iceland: '#9DA8FB',
  India: '#DFE3F9',
  Indonesia: '#ACCAE5',
  Iraq: '#78C6F3',
  Ireland: '#B3DCF4',
  'Isle of Man': '#A7D7D7',
  Israel: '#CDE8EF',
  Italy: '#A1D5A9',
  Jamaica: '#BDEBC4',
  Japan: '#D7DB69',
  Jersey: '#D3D4AC',
  Jordan: '#F4CB60',
  Kazakhstan: '#FFDE8B',
  Kenya: '#F48460',
  Kiribati: '#F4AF60',
  Kuwait: '#F7AA93',
  Kyrgyzstan: '#FFC98B',
  Latvia: '#F49EB4',
  Lebanon: '#F9BFCE',
  Lesotho: '#FDC5BB',
  Liberia: '#F5CEC7',
  Liechtenstein: '#A77CD3',
  Lithuania: '#C9AEDF',
  Luxembourg: '#C37CD3',
  Madagascar: '#DBAEDF',
  Malawi: '#7A8AFC',
  Malaysia: '#9DA8FB',
  Maldives: '#DFE3F9',
  Mali: '#ACCAE5',
  Malta: '#78C6F3',
  'Marshall Islands': '#B3DCF4',
  Martinique: '#A7D7D7',
  Mauritania: '#CDE8EF',
  Mauritius: '#A1D5A9',
  Mayotte: '#BDEBC4',
  Mexico: '#D7DB69',
  Monaco: '#D3D4AC',
  Mongolia: '#F4CB60',
  Montserrat: '#FFDE8B',
  Morocco: '#F48460',
  Mozambique: '#F4AF60',
  Myanmar: '#F7AA93',
  Namibia: '#FFC98B',
  Nauru: '#F49EB4',
  Nepal: '#F9BFCE',
  Netherlands: '#FDC5BB',
  'New Caledonia': '#F5CEC7',
  'New Zealand': '#A77CD3',
  Nicaragua: '#C9AEDF',
  Niger: '#C37CD3',
  Nigeria: '#DBAEDF',
  Niue: '#7A8AFC',
  'Northern Mariana Islands': '#9DA8FB',
  Norway: '#DFE3F9',
  Oman: '#ACCAE5',
  Pakistan: '#78C6F3',
  Palau: '#B3DCF4',
  Panama: '#A7D7D7',
  'Papua New Guinea': '#CDE8EF',
  Paraguay: '#A1D5A9',
  Peru: '#BDEBC4',
  Philippines: '#D7DB69',
  Poland: '#D3D4AC',
  Portugal: '#F4CB60',
  'Puerto Rico': '#FFDE8B',
  Qatar: '#F48460',
  Reunion: '#F4AF60',
  Romania: '#F7AA93',
  'Saint Kitts and Nevis': '#FFC98B',
  'Saint Lucia': '#F49EB4',
  'Saint Pierre and Miquelon': '#F9BFCE',
  'Saint Vincent and the Grenadines': '#FDC5BB',
  Samoa: '#F5CEC7',
  'San Marino': '#A77CD3',
  'Sao Tome and Principe': '#C9AEDF',
  'Saudi Arabia': '#C37CD3',
  Senegal: '#DBAEDF',
  Seychelles: '#7A8AFC',
  'Sierra Leone': '#9DA8FB',
  Singapore: '#DFE3F9',
  Slovakia: '#ACCAE5',
  Slovenia: '#78C6F3',
  'Solomon Islands': '#B3DCF4',
  Somalia: '#A7D7D7',
  'South Africa': '#CDE8EF',
  Spain: '#A1D5A9',
  'Sri Lanka': '#BDEBC4',
  Sudan: '#D7DB69',
  Suriname: '#D3D4AC',
  Sweden: '#F4CB60',
  Switzerland: '#FFDE8B',
  'Taiwan, Province of China': '#F48460',
  Tajikistan: '#F4AF60',
  Thailand: '#F7AA93',
  'Timor-Leste': '#FFC98B',
  Togo: '#F49EB4',
  Tokelau: '#F9BFCE',
  Tonga: '#FDC5BB',
  'Trinidad and Tobago': '#F5CEC7',
  Tunisia: '#A77CD3',
  Turkey: '#C9AEDF',
  Turkmenistan: '#C37CD3',
  'Turks and Caicos Islands': '#DBAEDF',
  Tuvalu: '#7A8AFC',
  Uganda: '#9DA8FB',
  Ukraine: '#DFE3F9',
  'United Arab Emirates': '#ACCAE5',
  'United Kingdom': '#78C6F3',
  'United States': '#B3DCF4',
  Uruguay: '#A7D7D7',
  Uzbekistan: '#CDE8EF',
  Vanuatu: '#A1D5A9',
  Venezuela: '#BDEBC4',
  'Wallis and Futuna': '#D7DB69',
  'Western Sahara': '#D3D4AC',
  Yemen: '#F4CB60',
  Zambia: '#FFDE8B',
  Zimbabwe: '#F48460',
  Brunei: '#F4AF60',
  'Republic of the Congo': '#F7AA93',
  'Falkland Islands': '#FFC98B',
  Iran: '#F49EB4',
  'North Korea': '#F9BFCE',
  'South Korea': '#FDC5BB',
  Laos: '#F5CEC7',
  Libya: '#A77CD3',
  'North Macedonia': '#C9AEDF',
  Micronesia: '#C37CD3',
  Moldova: '#DBAEDF',
  Russia: '#7A8AFC',
  Rwanda: '#9DA8FB',
  Serbia: '#DFE3F9',
  Montenegro: '#ACCAE5',
  Tanzania: '#78C6F3',
  Vietnam: '#B3DCF4',
  'British Virgin Islands': '#A7D7D7',
  'United States Virgin Islands': '#CDE8EF'
} as const;
export const SPORTS_COLORS = {
  Football: '#FF4500',
  Vaquejada: '#FF6347',
  'X1 Football': '#FF7F50',
  Slap: '#FFA07A',
  Climbing: '#FF8C00',
  Headis: '#FFA500',
  'Three-Point Contest': '#FFD700',
  Cornhole: '#FFFF00',
  'Virtual Formula 1': '#ADFF2F',
  'Air Hockey': '#7FFF00',
  Yukigassen: '#7CFC00',
  'Teamfight Tactics': '#32CD32',
  Go: '#00FF00',
  'VR-Boxing': '#008000',
  Jenga: '#006400',
  Greyhounds: '#9ACD32',
  'SIS Horse Racing': '#6B8E23',
  'PMU Horse Racing': '#556B2F',
  LiveBetOnBaccarat: '#8FBC8F',
  Sambo: '#2E8B57',
  LiveBetOnPoker: '#3CB371',
  BetOnBlackJack: '#20B2AA',
  BetOnPoker: '#00FF7F',
  'Mixed Football': '#00FA9A',
  Taekwondo: '#00CED1',
  'Modern Pentathlon': '#AFEEEE',
  Judo: '#7FFFD4',
  Fencing: '#40E0D0',
  Equestrian: '#48D1CC',
  Canoeing: '#00BFFF',
  Lottery: '#1E90FF',
  Olympics: '#6495ED',
  'Electronic sports': '#4682B4',
  'Inline Hockey': '#4169E1',
  'Greco-Roman Wrestling': '#0000FF',
  'Freestyle Wrestling': '#0000CD',
  'Compound Archery': '#00008B',
  Archery: '#000080',
  Sailing: '#191970',
  Rowing: '#7B68EE',
  Lacrosse: '#6A5ACD',
  'E-Volleyball': '#483D8B',
  'E-Ice hockey': '#E6E6FA',
  'UFC 2': '#D8BFD8',
  'Street Fighter': '#DDA0DD',
  'Tekken 7': '#EE82EE',
  'Mortal Kombat 11': '#DA70D6',
  'E-Tennis': '#FF00FF',
  'E-Basketball': '#BA55D3',
  Poker: '#9370DB',
  Triathlon: '#8A2BE2',
  'Nordic Combined': '#9400D3',
  'Ski Jumping': '#9932CC',
  Skateboarding: '#8B008B',
  'Extreme sport': '#800080',
  Snowboarding: '#4B0082',
  'Alpine Skiing': '#6A5ACD',
  'Auto Racing': '#483D8B',
  'Figure Skating': '#7B68EE',
  Luge: '#9370DB',
  'Freestyle Skiing': '#BA55D3',
  'Short Track Speed Skating': '#FF00FF',
  'Cross-Country Skiing': '#FF1493',
  'What? Where? When?': '#C71585',
  Eurovision: '#DB7093',
  'The Oscars': '#FF69B4',
  Entertainment: '#FFC0CB',
  'Special bets': '#FFB6C1',
  'TV Shows and Movies': '#FFA07A',
  Politics: '#FA8072',
  Speedway: '#E9967A',
  Trotting: '#F08080',
  'Future Horse Racing': '#CD5C5C',
  'Horse Racing Specials': '#DC143C',
  'PA Greyhounds': '#B22222',
  'Horse Racing': '#FF0000',
  'Apex Legends': '#8B0000',
  'Inspired Trotting': '#800000',
  'Inspired Greyhound Racing': '#8B4513',
  'Inspired Cycling': '#A52A2A',
  'Inspired Speedway': '#696969',
  'Inspired Motor Racing': '#808080',
  'Inspired Horse Racing': '#778899',
  'Inspired Rush 2 Football': '#2F4F4F',
  'Virtual Drag Racing': '#708090',
  'Virtual Tennis': '#778899',
  'Virtual Greyhounds': '#B0C4DE',
  'Virtual Cycling': '#ADD8E6',
  'Virtual Marble Racing': '#B0E0E6',
  'Virtual Horse Racing': '#87CEEB',
  'Virtual Car Racing': '#87CEFA',
  'Virtual Sports': '#4682B4',
  'Speed Skating': '#6495ED',
  Biathlon: '#4169E1',
  Draughts: '#0000FF',
  Bodybuilding: '#1E90FF',
  Surfing: '#87CEEB',
  Athletics: '#00BFFF',
  Diving: '#5F9EA0',
  Rally: '#4682B4',
  Shooting: '#AFEEEE',
  'Artistic Swimming': '#00CED1',
  'Marathon Swimming': '#20B2AA',
  Swimming: '#008080',
  NASCAR: '#008B8B',
  'Formula 1': '#00FFFF',
  Motorbikes: '#00CED1',
  Gymnastics: '#40E0D0',
  'Virtual Football League': '#7FFFD4',
  'Virtual Football': '#66CDAA',
  'The Penalty Kicks': '#00FF7F',
  'Virtual Football Pro': '#3CB371',
  Weightlifting: '#2E8B57',
  'Rainbow Six': '#8FBC8F',
  Valorant: '#98FB98',
  'Rocket League': '#32CD32',
  'Gears of War': '#00FF00',
  'Street Fighter V': '#7CFC00',
  'NBA 2K': '#7FFF00',
  FIFA: '#ADFF2F',
  Halo: '#00FF7F',
  Gwent: '#3CB371',
  'Heroes of Newerth': '#20B2AA',
  Vainglory: '#00FFFF',
  CrossFire: '#AFEEEE',
  'Arena of Valor': '#7FFFD4',
  'Age of Empires': '#66CDAA',
  'Brawl Stars': '#00FF7F',
  'Warcraft III': '#2E8B57',
  Artifact: '#8FBC8F',
  'World of Tanks': '#98FB98',
  'World of Warcraft': '#32CD32',
  Smite: '#00CD66',
  Overwatch: '#6B8E23',
  'Overwatch 2': '#556B2F',
  'StarCraft 2': '#20B2AA',
  StarCraft: '#4682B4',
  Hearthstone: '#00BFFF',
  Quake: '#1E90FF',
  'Heroes of the Storm': '#6495ED',
  'Mobile Legends': '#4169E1',
  'Call of Duty': '#0000FF',
  'King of Glory': '#00008B',
  'Counter-Strike: GO': '#0000CD',
  'PUBG Mobile': '#00008B',
  'Free Fire': '#000080',
  Fortnite: '#191970',
  PUBG: '#483D8B',
  'Counter-Strike 2': '#6A5ACD',
  'League of Legends: Wild Rift': '#7B68EE',
  'Clash of Clans': '#9370DB',
  'League of Legends': '#BA55D3',
  'Clash Royale': '#8A2BE2',
  'Dota 2': '#9400D3',
  Bobsleigh: '#9932CC',
  'Indoor Hockey': '#8B008B',
  Skeleton: '#800080',
  'Rink Hockey': '#4B0082',
  WWE: '#6A5ACD',
  Kabaddi: '#9370DB',
  Pool: '#BA55D3',
  Pesapallo: '#8A2BE2',
  Netball: '#9400D3',
  'Ultimate Frisbee': '#9932CC',
  Pelota: '#8B008B',
  Squash: '#800080',
  Sumo: '#4B0082',
  Hurling: '#6A5ACD',
  Camogie: '#9370DB',
  'Gaelic football': '#BA55D3',
  'International Rules': '#8A2BE2',
  Petanque: '#9400D3',
  Bowls: '#9932CC',
  Bowling: '#8B008B',
  'Rugby Union': '#800080',
  'Rugby League': '#4B0082',
  'Rugby Sevens': '#6A5ACD',
  'Beach Handball': '#9370DB',
  Bandy: '#BA55D3',
  'Beach Football': '#8A2BE2',
  'Field hockey': '#9400D3',
  '100-Ball Cricket': '#9932CC',
  DecimalCricket: '#8B008B',
  Curling: '#800080',
  'Disc Golf': '#4B0082',
  Golf: '#6A5ACD',
  'Ball Hockey': '#9370DB',
  'American Football': '#BA55D3',
  PistolH2H: '#8A2BE2',
  'Pistol Shooting': '#9400D3',
  Teqball: '#9932CC',
  'Archery Shooting': '#8B008B',
  'Basketball Shots': '#800080',
  Softball: '#4B0082',
  Floorball: '#6A5ACD',
  Snooker: '#9370DB',
  Darts: '#BA55D3',
  Karate: '#8A2BE2',
  'Aussie Rules': '#9400D3',
  Boxing: '#9932CC',
  'Sepak Takraw': '#8B008B',
  MMA: '#800080',
  Cycling: '#4B0082',
  Chess: '#6A5ACD',
  'Water Polo': '#9370DB',
  'Table Tennis': '#F49EB4',
  'Beach Volleyball': '#8A2BE2',
  Badminton: '#9400D3',
  Baseball: '#9932CC',
  Cricket: '#8B008B',
  Futsal: '#800080',
  Handball: '#4B0082',
  'E-Football': '#6A5ACD',
  '3x3 Basketball': '#9370DB',
  'Ice Hockey': '#BA55D3',
  Volleyball: '#8A2BE2',
  Tennis: '#FDC5BB',
  Basketball: '#9932CC'
} as const;

export const DEVICES_COLORS = {
  Linux: '#FDC5BB',
  'Windows NT 10.0': '#F49EB4',
  'Windows NT 6.1': '#F9BFCE',
  'Mac OS X 10.14': '#A77CD3',
  'Mac OS X 10.15': '#F5CEC7',
  'iPhone OS 14.7': '#C9AEDF',
  'Windows NT 6.3': '#C37CD3'
} as const;
