import styled from '@shared_medialab/ui_components';

export const StyledFilters = styled.div`
  height: 100%;
  .tab-content {
    display: flex;
    flex-direction: column;
    & > div {
      flex: auto;
      display: flex;
      flex-direction: column;
      & > div {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;
