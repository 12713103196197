import styled from 'styled-components';

export const StyledSavePreset = styled.div`
  display: flex;
  align-items: flex-end;
  .input-holder {
    .input-element-back {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
  button {
    height: 42px;
    min-width: 130px;
    &.btn.cr-smooth {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .preset-input-error {
    .input-element-back {
      border-color: var(--danger);
    }
    input {
      ::placeholder {
        color: var(--danger);
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: var(--danger);
      }

      ::-ms-input-placeholder {
        color: var(--danger);
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: var(--danger);
  min-height: 12px;
`;
