import { ConfirmModalTypes } from './types';

export const CONFIRM_BUTTON_MODES = {
  [ConfirmModalTypes.confirm]: 'confirm',
  [ConfirmModalTypes.danger]: 'danger',
  [ConfirmModalTypes.warning]: 'primary',
  [ConfirmModalTypes.primaryNew]: 'primaryNew',
  [ConfirmModalTypes.dangerNew]: 'dangerNew',
  [ConfirmModalTypes.contrastNew]: 'contrastNew'
} as const;
