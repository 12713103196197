import styled from '@shared_medialab/ui_components';

import { IStyledInfoLabelProps } from './types';

export const StyledInfoLabel = styled.div<IStyledInfoLabelProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ tooltip }) => (tooltip ? '13px' : '0px')};
  .input-tooltip {
    span {
      background-color: var(--light-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 12px;
      transition: all 0.3s;
      svg path {
        transition: all 0.3s;
        fill: rgba(0, 0, 0, 0.7);
      }
    }
    &:hover {
      span {
        background-color: var(--primary-1);
        svg path {
          fill: #ffffff;
        }
      }
    }
  }
`;
