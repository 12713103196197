import { DateRangeType } from '@shared_medialab/ui_components/build/ui/DatePickerInput/types';
import { ReactNode } from 'react';

export interface IFiltersProviderProps {
  children: ReactNode;
}
export const ActionTypes = {
  SET_FILTER_ITEM: 'SET_FILTER_ITEM',
  RESET_FILTERS: 'RESET_FILTERS'
} as const;

// all modules we have

export enum FilterModules {
  events_list = 'events_list'
}

// modules filter types

export type EventsListFilters = {
  name: string;
  dates: DateRangeType;
  sportIds: string[];
  regionIds: string[];
  leagueIds: string[];
  status: string[];
  // auto_booking: string[];
  // booking_type: string;
};

export type State = {
  [FilterModules.events_list]: EventsListFilters;
};

type FilterDataModule = keyof State;

export type FilterData = {
  module: FilterDataModule;
  name: string;
  data: unknown;
};

// action types

type SetFilters = {
  type: typeof ActionTypes.SET_FILTER_ITEM;
  data: FilterData;
};

type ResetFilters = {
  type: typeof ActionTypes.RESET_FILTERS;
  data: FilterModules;
};

export type Action = SetFilters | ResetFilters;

export type DispatchContext = {
  setFilterItem: (data: FilterData) => void;
  resetFilters: (data: FilterModules) => void;
};

export type Dispatch = (action: Action) => void;
