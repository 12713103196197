import styled from '@shared_medialab/ui_components';

export const StyledLayout = styled.div`
  --header-size: 53px;
  --page-header-size: 53px;
  --page-footer-size: 65px;
  --gap: 10px;
  --main-height: calc(100vh - (var(--header-size) + var(--gap) + var(--gap)));
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--primary-bg-5);
  header {
    position: relative;
    z-index: 20;
  }
  main {
    height: var(--main-height);
    position: relative;
    z-index: 0;
    flex: 1 1 auto;
    min-width: 55%;
    max-width: 100%;
    padding-inline: 10px;
    .fit-gaps & {
      border-radius: 0;
    }
  }
  aside {
    height: var(--main-height);
    &.opened {
      width: 38rem;
      @media (max-width: 1700px) {
        width: 32.2rem;
      }
      @media (max-width: 1400px) {
        width: 29rem;
        min-width: 29rem;
      }
      .aside-wrapper {
        min-width: 27rem;
        width: auto;
      }
    }
  }
  .aside-content {
    width: 38rem;
    @media (max-width: 1700px) {
      width: 32.2rem;
    }
    @media (max-width: 1400px) {
      width: 29rem;
      min-width: 27rem;
    }
  }
`;
