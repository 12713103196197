import { gql } from '@apollo/client';

export const CONTENT_PACKAGES_FRAGMENT = gql`
  fragment ContentPackagesFragment on ContentPackage {
    id
    name
    sportsCount
    leaguesCount
    sports {
      id
      name
      creationId
      regions {
        id
        name
        creationId
        leagues {
          id
          name
          creationId
        }
      }
    }
  }
`;
