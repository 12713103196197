import { useRef, useEffect } from 'react';

const usePreviousNonNullish = <T>(value: T): T => {
  const ref = useRef<T>(value);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  }, [value]);

  return value ?? ref.current;
};

export default usePreviousNonNullish;
