import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MATCH_STATUSES_VALUES } from 'constants/match';
import { Tag } from '@shared_medialab/ui_components';

import { IStatusLabelProps } from './types';

const HistoryStatusLabel: FC<IStatusLabelProps> = ({ status }) => {
  // translations
  const { t } = useTranslation('events');

  switch (status) {
    case MATCH_STATUSES_VALUES.canceled:
    case MATCH_STATUSES_VALUES.finished:
      return (
        <Tag
          type={
            status === MATCH_STATUSES_VALUES.finished ? 'primary' : 'warning'
          }
        >
          <p>{t(`statuses.${status}`)}</p>
        </Tag>
      );
  }

  return null;
};

export default HistoryStatusLabel;
