import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CART } from 'gql/cart/queries';
import {
  Cart,
  Cart_cart_data_matches_availableCountries as CountryPricesType
} from 'gql/cart/types/Cart';
import { useAuthStateContext } from 'providers/Auth';
import {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
  useRef,
  useEffect
} from 'react';
import { MatchTypesEnum } from 'constants/match';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { CREATE_CART, DELETE_CART } from 'gql/cart/mutations';
import client from 'apolloClient';
import { useProjectStateContext } from 'providers/Projects';

import {
  State,
  Dispatch,
  DispatchContext,
  ActionTypes,
  SelectedStateType,
  EventsPagingType,
  EventTypes,
  IEventsProps,
  BookedEventsFilters,
  ListEventsFilters,
  FiltersStateType,
  SelectedCountriesStateType,
  CartMatches,
  CartLoadingStateType
} from './types';
import reducer from './reducers';
import { UserEnum } from 'pages/UserManagement/constants';

const initialState: State = {
  cartLoading: false,
  selected: {
    list: [],
    booked: [],
    history: []
  },
  paging: {
    loading: true,
    page: 0,
    continuePaging: true
  },
  filters: {
    list: {
      dates: '',
      leagues: undefined,
      providers: undefined,
      regions: undefined,
      search: '',
      sports: undefined,
      status: []
    },
    booked: {
      dates: '',
      leagues: undefined,
      providers: undefined,
      regions: undefined,
      search: '',
      sports: undefined,
      status: []
    },
    history: {
      dates: '',
      leagues: undefined,
      providers: undefined,
      regions: undefined,
      search: '',
      sports: undefined,
      status: []
    }
  },
  cartCountries: {}
};

// states
const EventsPagingStateContext = createContext<EventsPagingType | undefined>(
  undefined
);

const EventsCartLoadingStateContext = createContext<
  CartLoadingStateType | undefined
>(undefined);

const EventsSelectedStateContext = createContext<SelectedStateType | undefined>(
  undefined
);

const EventsFiltersStateContext = createContext<FiltersStateType | undefined>(
  undefined
);

const SelectedCountriesStateContext = createContext<
  SelectedCountriesStateType | undefined
>(undefined);

// dispatches
const EventsDispatchContext = createContext<
  | {
      dispatch: Dispatch;
      onSelectItem: DispatchContext['onSelectItem'];
      onBulkSelect: DispatchContext['onBulkSelect'];
      unselectItem: DispatchContext['unselectItem'];
      unselectItems: DispatchContext['unselectItems'];
      unselectAll: DispatchContext['unselectAll'];
      initMatches: DispatchContext['initMatches'];
    }
  | undefined
>(undefined);

const EventsProvider: FC<IEventsProps> = ({ children }) => {
  // const { shoppingCart } = useModalStateContext();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  // context
  const { selectedProject } = useProjectStateContext();

  const { isLoggedIn } = useAuthStateContext();
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);

  // graphql
  const [getCartData, { refetch }] = useLazyQuery<Cart>(GET_CART);

  useEffect(() => {
    if (isLoggedIn && selectedProject?.id) {
      getCartData({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: {
          organizationId: selectedProject.id
        },
        onCompleted(data) {
          const matches = data.cart?.data.matches?.map(item => {
            const countries = item.availableCountries?.filter(country => {
              return item.countries?.includes(country.id as string);
            });

            return {
              id: item.match.id,
              countries: countries || []
            };
          });

          if (matches?.length) {
            dispatch({
              type: ActionTypes.INIT_MATCHES,
              data: { matches, type: MatchTypesEnum.list }
            });
          }
        }
      });
    }
  }, [getCartData, refetch, isLoggedIn, selectedProject?.id]);

  const refetchCart = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      refetch();
    }, 300);
  };

  const [createCart] = useMutation(CREATE_CART);

  const [deleteCart] = useMutation(DELETE_CART, {
    onCompleted() {
      refetchCart();
    }
  });

  // graphql
  const [getUserData, { data: meData }] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const onSelectItem = useCallback(
    (id: string, type: EventTypes) => {
      const isSelected = state.selected[type].includes(id);

      if (isSelected) {
        type === MatchTypesEnum.list &&
          deleteCart({
            variables: {
              ...(selectedProject?.id
                ? { organizationId: selectedProject.id }
                : {}),
              input: {
                matchIds: [id]
              }
            }
          });

        return dispatch({ type: ActionTypes.UN_SELECT, data: { id, type } });
      }

      const countries = meData?.me?.data.settings?.countries || [];

      type === MatchTypesEnum.list &&
        createCart({
          variables: {
            ...(selectedProject?.id
              ? { organizationId: selectedProject.id }
              : {}),
            input: {
              matchIds: [id],
              countries
            }
          }
        });

      dispatch({ type: ActionTypes.ON_SELECT, data: { id, type } });
    },
    [
      createCart,
      deleteCart,
      meData?.me?.data.settings?.countries,
      selectedProject?.id,
      state.selected
    ]
  );

  const onBulkSelect = useCallback(
    (IDs: string[], type: EventTypes) => {
      const isSelected = IDs.every(e => state.selected[type].includes(e));

      if (isSelected) {
        const idsToRemove: string[] = [];
        const data = state.selected[type].filter(id => {
          if (IDs.includes(id)) {
            idsToRemove.push(id);

            return false;
          }

          return true;
        });

        type === MatchTypesEnum.list &&
          deleteCart({
            variables: {
              ...(selectedProject?.id
                ? { organizationId: selectedProject.id }
                : {}),
              input: {
                matchIds: idsToRemove
              }
            }
          });

        return dispatch({
          type: ActionTypes.ON_SELECT_BULK,
          data: { IDs: data, type }
        });
      }

      const idsToAdd = IDs.filter(item => !state.selected[type].includes(item));
      const countries = meData?.me?.data.settings?.countries || [];

      type === MatchTypesEnum.list &&
        createCart({
          variables: {
            ...(selectedProject?.id
              ? { organizationId: selectedProject.id }
              : {}),
            input: {
              matchIds: idsToAdd,
              countries
            }
          }
        });

      dispatch({
        type: ActionTypes.ON_SELECT_BULK,
        data: { IDs: [...state.selected[type], ...idsToAdd], type }
      });
    },
    [
      createCart,
      deleteCart,
      meData?.me?.data.settings?.countries,
      selectedProject?.id,
      state.selected
    ]
  );

  const unselectItem = useCallback(
    (id: string, type: EventTypes) => {
      client.cache.modify({
        fields: {
          cart(existingItems) {
            return {
              ...existingItems,
              data: {
                ...existingItems.data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                matches: existingItems.data.matches.filter((item: any) => {
                  return `Match:${id}` !== item.match.__ref;
                })
              }
            };
          }
        }
      });

      type === MatchTypesEnum.list &&
        deleteCart({
          variables: {
            ...(selectedProject?.id
              ? { organizationId: selectedProject.id }
              : {}),
            input: {
              matchIds: [id]
            }
          }
        });

      dispatch({ type: ActionTypes.UN_SELECT, data: { id, type } });
    },
    [deleteCart, selectedProject?.id]
  );

  const unselectItems = useCallback(
    (ids: string[], type: EventTypes) => {
      type === MatchTypesEnum.list &&
        deleteCart({
          variables: {
            ...(selectedProject?.id
              ? { organizationId: selectedProject.id }
              : {}),
            input: {
              matchIds: ids
            }
          }
        });

      dispatch({ type: ActionTypes.UN_SELECT_ITEMS, data: { ids, type } });
    },
    [deleteCart, selectedProject?.id]
  );

  const unselectAll = useCallback(
    (type: EventTypes) => {
      type === MatchTypesEnum.list &&
        deleteCart({
          variables: {
            ...(selectedProject?.id
              ? { organizationId: selectedProject.id }
              : {}),
            input: {
              matchIds: state.selected[type]
            }
          }
        });

      dispatch({ type: ActionTypes.UN_SELECT_ALL, data: type });
    },
    [deleteCart, selectedProject?.id, state.selected]
  );

  const initMatches = useCallback(
    (matches: CartMatches[], type: EventTypes) => {
      dispatch({
        type: ActionTypes.INIT_MATCHES,
        data: { matches, type }
      });
    },
    []
  );

  return (
    <EventsDispatchContext.Provider
      value={{
        dispatch,
        onSelectItem,
        onBulkSelect,
        unselectItem,
        unselectItems,
        unselectAll,
        initMatches
      }}
    >
      <EventsSelectedStateContext.Provider value={state.selected}>
        <EventsPagingStateContext.Provider value={state.paging}>
          <EventsFiltersStateContext.Provider value={state.filters}>
            <SelectedCountriesStateContext.Provider value={state.cartCountries}>
              <EventsCartLoadingStateContext.Provider value={state.cartLoading}>
                {children}
              </EventsCartLoadingStateContext.Provider>
            </SelectedCountriesStateContext.Provider>
          </EventsFiltersStateContext.Provider>
        </EventsPagingStateContext.Provider>
      </EventsSelectedStateContext.Provider>
    </EventsDispatchContext.Provider>
  );
};

const useEventsPagingStateContext = (): EventsPagingType => {
  const context = useContext(EventsPagingStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventsSelectedStateContext must be used within a useEventsSelectedStateContext'
    );
  }

  return context;
};

const useEventsCartLoadingStateContext = (): CartLoadingStateType => {
  const context = useContext(EventsCartLoadingStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventsCartLoadingStateContext must be used within a useEventsCartLoadingStateContext'
    );
  }

  return context;
};

const useEventsSelectedStateContext = (): SelectedStateType => {
  const context = useContext(EventsSelectedStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventsSelectedStateContext must be used within a useEventsSelectedStateContext'
    );
  }

  return context;
};

const useEventsFiltersStateContext = (): FiltersStateType => {
  const context = useContext(EventsFiltersStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventsFiltersStateContext must be used within a useEventsFiltersStateContext'
    );
  }

  return context;
};

const useSelectedCountriesStateContext = (): SelectedCountriesStateType => {
  const context = useContext(SelectedCountriesStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useSelectedCountriesStateContext must be used within a useSelectedCountriesStateContext'
    );
  }

  return context;
};

const useEventsDispatchContext = (): DispatchContext => {
  // context
  const context = useContext(EventsDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventsDispatchContext must be used within a useEventsDispatchContext'
    );
  }

  const {
    onSelectItem,
    onBulkSelect,
    unselectItem,
    unselectItems,
    unselectAll,
    initMatches,
    dispatch
  } = context;

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch({ type: ActionTypes.SET_LOADING, data: loading });
    },
    [dispatch]
  );

  const setCartLoading = useCallback(
    (loading: boolean) => {
      dispatch({ type: ActionTypes.SET_CART_LOADING, data: loading });
    },
    [dispatch]
  );

  const setFilters = useCallback(
    (data: BookedEventsFilters | ListEventsFilters) => {
      dispatch({ type: ActionTypes.SET_FILTERS, data });
    },
    [dispatch]
  );

  const setContinuePaging = useCallback(
    (data: boolean) => {
      dispatch({ type: ActionTypes.SET_CONTINUE_PAGING, data });
    },
    [dispatch]
  );

  const setCartItemCountries = useCallback(
    (matchId: string, data: CountryPricesType) => {
      dispatch({
        type: ActionTypes.SELECT_MATCH_COUNTRIES,
        data: { matchId, data }
      });
    },
    [dispatch]
  );

  const applyCartItemCountriesToAll = useCallback(
    (matchId: string) => {
      dispatch({
        type: ActionTypes.APPLY_MATCH_COUNTRIES_TO_ALL,
        data: matchId
      });
    },
    [dispatch]
  );

  const selectMultipleCountries = useCallback(
    (matchId: string, data: CountryPricesType[]) => {
      dispatch({
        type: ActionTypes.SELECT_ALL_COUNTRIES,
        data: { matchId, data }
      });
    },
    [dispatch]
  );

  return {
    setLoading,
    setCartLoading,
    onSelectItem,
    unselectItem,
    unselectItems,
    unselectAll,
    initMatches,
    onBulkSelect,
    setFilters,
    setContinuePaging,
    setCartItemCountries,
    applyCartItemCountriesToAll,
    selectMultipleCountries
  };
};

export default EventsProvider;
export {
  useEventsDispatchContext,
  useEventsCartLoadingStateContext,
  useEventsSelectedStateContext,
  useEventsPagingStateContext,
  useEventsFiltersStateContext,
  useSelectedCountriesStateContext
};
