import { gql } from '@apollo/client';

export const UNBOOK_MATCHES = gql`
  mutation UnBookMatches($input: MatchIdsInput!) {
    unBookMatches(input: $input) {
      message
    }
  }
`;

export const REBOOK_MATCHES = gql`
  mutation RebookMatches($input: MatchIdsInput!) {
    rebookMatches(input: $input) {
      message
    }
  }
`;

export const BOOK_MATCHES_QUICK = gql`
  mutation BookMatchesQuick($input: QuickMatchesInput!) {
    bookMatchesQuick(input: $input) {
      message
    }
  }
`;

export const UN_BOOK_MATCHES_BY_COUNTRY = gql`
  mutation UnBookMatchByCountry($input: UnBookMatchByCountryInput!) {
    unBookMatchByCountry(input: $input) {
      data
    }
  }
`;

export const UN_BOOK_MATCH = gql`
  mutation UnBookMatch($input: UnBookMatchInput!) {
    unBookMatch(input: $input) {
      data
    }
  }
`;
