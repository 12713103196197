export const PACKAGE_TYPE = {
  1: 'manual',
  2: 'auto'
} as const;

export const PACKAGE_LABELS = {
  manual: 1,
  auto: 2
} as const;

export type PackageType = keyof typeof PACKAGE_TYPE;
