import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@shared_medialab/ui_components';
import { MatchTypesEnum } from 'constants/match';
import { useTranslation } from 'react-i18next';
import './index.css';

import { RouteParams } from '../Content/types';

const { Text } = Typography;

const Total: FC<{ data: number | null }> = ({ data }) => {
  const { type } = useParams() as RouteParams;
  const { t } = useTranslation('common');

  return data ? (
    <div
      className={
        type === MatchTypesEnum.booked
          ? 'absolute-position booked-total'
          : 'mr--56'
      }
    >
      <Text bold className="mr--8" level={13}>{`${t('total')}:`}</Text>
      <Text level={13}>{data}</Text>
    </div>
  ) : (
    <></>
  );
};

export default Total;
