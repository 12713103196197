import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses,
  Statuses
} from 'providers/Projects/types';
import { UserProjects_userProjects_data_buyer as buyerType } from 'gql/organizations/types/UserProjects';
import { useProjectStateContext } from 'providers/Projects';

const useStatusState = (states: Statuses) => {
  const { selectedProject } = useProjectStateContext();

  const selectedBuyer = selectedProject as buyerType;
  const product =
    selectedBuyer?.status &&
    states.product?.includes(selectedBuyer.status as ProductStatuses);

  const project =
    selectedBuyer?.projectStatus &&
    states.project?.includes(selectedBuyer.projectStatus as ProjectStatuses);

  const bme =
    selectedProject?.status &&
    states.bme?.includes(selectedBuyer.bmeStatus as BmeStatuses);

  const isIncluding = states.bme ? project && product && bme : product;

  if (!isIncluding) {
    return false;
  }

  return true;
};

export default useStatusState;
