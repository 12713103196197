import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

import { IStyledPreset } from './types';

export const StyledWrapper = styled.div`
  &&& {
    flex: auto;
  }
`;

export const IconButton = styled.button`
  background: var(--primary-bg-3);
  border: 1px solid var(--primary-bg-5);
  border-radius: 6px;
  cursor: pointer;
`;

export const StyledPreset = styled.div<IStyledPreset>`
  border: 1px solid rgba(160, 169, 239, 0.45);
  border-radius: 6px;
  background: var(--primary-bg-2);
  margin-bottom: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
  h6 {
    border-radius: 6px 6px 0 0;
    padding: 6px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    font-size: 1.6rem;
    background-color: var(--primary-bg-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  p {
    padding: 6px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 300;
    font-size: 1.6rem;
  }
  ${IconButton} {
    opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover {
    border-color: var(--primary-1);
    h6 {
      color: rgba(0, 0, 0, 0.7);
      background-color: var(--primary-bg-1);
      border-bottom-color: var(--primary-bg-5);
    }
    ${IconButton} {
      opacity: 1;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      &&& {
        border-color: var(--primary-1);
        h6 {
          color: #ffffff;
          background-color: var(--primary-1);
          border-bottom: 1px solid transparent;
        }
      }
    `}
  ${({ isDeleting }) =>
    isDeleting &&
    css`
      &&& {
        border-color: var(--danger);
        h6 {
          color: #ffffff;
          background-color: var(--danger);
          border-bottom: 1px solid transparent;
        }
      }
    `}
`;
