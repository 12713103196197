import { Tooltip } from '@shared_medialab/ui_components';

import { IButtonProps } from '../../types';
import { StyledButton } from './styled';

const Button = ({ statusText, btnText, mode = '' }: IButtonProps) => {
  return (
    <Tooltip
      title={statusText}
      styles={{
        textColor: 'var(--text-color)'
      }}
    >
      <StyledButton
        className="relative-position flex-display flex-justify-center flex-align-items-center p--8"
        mode={mode}
      >
        {btnText}
      </StyledButton>
    </Tooltip>
  );
};

export default Button;
