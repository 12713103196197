import client from 'apolloClient';
import { GET_CART } from 'gql/cart/queries';

export const deleteShoppingCart = async () => {
  await client.refetchQueries({ include: [GET_CART] });
};

export default {
  deleteShoppingCart
};
