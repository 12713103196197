import { RootState } from 'store';

import { ReportsFiltersState } from './types';

export const selectReportsPagination = () => (state: RootState) =>
  state.filters.reports.pagination;

export const selectReportsFilters = () => (state: RootState) =>
  state.filters.reports.filters;

export const selectReportsFilterItem =
  (name: keyof ReportsFiltersState['filters']) => (state: RootState) =>
    state.filters.reports.filters[name];

export const selectIsReportsResetEnabled =
  () =>
  ({ filters }: RootState) => {
    const { countryNames, sport, region, league, platform } =
      filters.reports.filters;

    return (
      !!countryNames.length ||
      !!sport.length ||
      !!region.length ||
      !!league.length ||
      !!platform.length
    );
  };
