import { gql } from '@apollo/client';
import { COUNTRIES_PRICES_FRAGMENT } from 'gql/events/fragments';

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    matches {
      availableCountries {
        country
        id
        price
        booked
        matchId
      }
      isOutOfPackage
      match {
        id
        name
        team1
        team2
        region
        startTime
        sport
        status
        league {
          id
          name
        }
        prices {
          ...CountriesPricesFragment
        }
      }
      countries
    }
  }
  ${COUNTRIES_PRICES_FRAGMENT}
`;
