import { gql } from '@apollo/client';

import { ROLE_FRAGMENT } from './fragments';

export const GET_ROLES_FOR_FILTERS = gql`
  query RolesForFilters($query: GetPermissionsInput!) {
    allRoles(query: $query) {
      data {
        results {
          id
          name
        }
        total
      }
    }
  }
`;

export const GET_ROLES = gql`
  query AllRoles($query: GetPermissionsInput!) {
    allRoles(query: $query) {
      data {
        results {
          ...RoleFragment
        }
        total
      }
    }
  }
  ${ROLE_FRAGMENT}
`;

export const GET_PARENT_ROLES = gql`
  query ParentRoles {
    roles {
      data {
        id
        name
      }
    }
  }
`;
