import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

export const StyledButton = styled.button<{ mode?: string }>`
  border-radius: 6px;
  min-width: 105px;
  height: 34px;
  border: none;
  background-color: var(--slate-blue);
  transition: all 0.3s;
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }

  ${({ mode = '' }) => {
    switch (mode) {
      case 'success':
        return css`
          color: var(--success);
          background-color: var(--slate-blue);
          &::before {
            background-color: var(--success);
          }
          &:hover {
            color: var(--spring-meadow);
            &::before {
              background-color: var(--spring-meadow);
            }
            font-weight: bold;
          }
        `;
      case 'activation':
        return css`
          color: var(--white);
          &::before {
            background-color: var(--white);
          }
          &:hover {
            color: var(--white);
            &::before {
              background-color: var(--white);
            }
            font-weight: bold;
          }
        `;
      case 'warning':
        return css`
          color: var(--Orange__6);
          &::before {
            background-color: var(--Orange__6);
          }
          &:hover {
            color: var(--amber-glow);
            &::before {
              background-color: var(--amber-glow);
            }
            font-weight: bold;
          }
        `;
      case 'danger':
        return css`
          color: var(--crimson-blaze);
          &::before {
            background-color: var(--crimson-blaze);
          }
          &:hover {
            color: var(--danger-2);
            &::before {
              background-color: var(--danger-2);
            }
            font-weight: bold;
          }
        `;
      case 'integration':
        return css`
          color: var(--vivid-cerulean);
          &::before {
            background-color: var(--vivid-cerulean);
          }
          &:hover {
            color: var(--azure-dream);
            &::before {
              background-color: var(--azure-dream);
            }
            font-weight: bold;
          }
        `;
      default:
        return css`
          color: var(--sky-serenity);
          &::before {
            background-color: var(--sky-serenity);
          }
          &:hover {
            color: var(--lavender-mist);
            &::before {
              background-color: var(--lavender-mist);
            }
            font-weight: bold;
          }
        `;
    }
  }}
`;
