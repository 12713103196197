import { FC, ReactNode } from 'react';
import { Typography } from '@shared_medialab/ui_components';
import { theme } from '@shared_medialab/ui_components/build/styled/default';

const { Text } = Typography;

const MessageReason: FC<{ children: ReactNode; massageColor: boolean }> = ({
  children,
  massageColor
}) => {
  return (
    <Text
      color={massageColor ? theme.colors.red[2] : theme.colors.red[8]}
      className="text-center"
    >
      {children}
    </Text>
  );
};

export default MessageReason;
