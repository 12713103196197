import { FC, memo, useEffect, useMemo } from 'react';
import {
  useEventsDispatchContext,
  useEventsSelectedStateContext
} from 'providers/Events';
import { useDayjs, usePermission, useStatusState } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useUIDispatchContext } from 'providers/UI';
import {
  MATCH_STATUS_IDS,
  MATCH_STATUSES_VALUES,
  MatchTypesEnum
} from 'constants/match';
import {
  Button,
  highlightedData,
  SportIcon,
  Tag,
  Tooltip,
  utils
} from '@shared_medialab/ui_components';
import { useParams } from 'react-router-dom';
import { ModalKeys } from 'providers/UI/types';
import client from 'apolloClient';
import {
  MATCHES_BOOKED_FRAGMENT,
  MATCHES_LIST_FRAGMENT
} from 'gql/events/fragments';
import { MatchesListFragment } from 'gql/events/types/MatchesListFragment';
import * as permissionConstants from 'constants/permissions';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_MATCHES } from 'gql/events/queries';
import { toast } from 'react-toastify';
import { useProjectStateContext } from 'providers/Projects';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectEventsFilters,
  selectEventsItemPlayButton
} from 'store/slice/filters/events/selector';
import { setIsPlayDisabled } from 'store/slice/filters';
import { MatchesBookedFragment } from 'gql/events/types/MatchesBookedFragment';
import { BookedMatches_bookedMatches_data_results as Booked } from 'gql/events/types/BookedMatches';
import { BuyerListMatches_buyerListMatches_data_results as Upcoming } from 'gql/events/types/BuyerListMatches';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';

import {
  MatchItemCol,
  MatchTimeContainer,
  StyledContainer,
  StyledMatchItem
} from './styled';
import { IMatchItemProps } from './types';
import { RouteParams as EventsRouterParams } from 'pages/Events/components/Content/types';
import VideoPreview from 'components/shared/VideoPreview';
import HistoryStatusLabel from '../HistoryStatusLabel';
import Private from 'components/shared/Private';
import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';
import useEventsBookedCache from 'hooks/useEventsBookedCache';
import Wrapper from './components/MatchItemWrapper';

const MatchItem: FC<IMatchItemProps> = ({ id }) => {
  // context
  const { onSelectItem } = useEventsDispatchContext();
  const selected = useEventsSelectedStateContext();
  const { selectedProject } = useProjectStateContext();
  const { toggleModal } = useUIDispatchContext();
  // navigation
  const { type } = useParams() as EventsRouterParams;
  // redux
  const dispatch = useAppDispatch();
  const eventsFilters = useAppSelector(selectEventsFilters(type));

  const playButton = useAppSelector(selectEventsItemPlayButton(type));
  // translations
  const { t } = useTranslation(['events', 'common']);
  // hooks
  const { dayjs, timeFormat } = useDayjs();

  //For re-render when cache update
  const { results: list } = useEventsUpcomingCache();
  const { results: booked } = useEventsBookedCache();

  const item = useMemo(
    () =>
      client.readFragment<MatchesListFragment | MatchesBookedFragment>({
        id: type === 'booked' ? `BookedMatch:${id}` : `Match:${id}`,
        fragment:
          type === 'booked' ? MATCHES_BOOKED_FRAGMENT : MATCHES_LIST_FRAGMENT,
        fragmentName:
          type === 'booked' ? 'MatchesBookedFragment' : 'MatchesListFragment'
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, type, list, booked]
  );

  const { checkUserPermission } = usePermission();
  const hasBookPermission = useMemo(() => {
    return checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.list,
      permissionConstants.MANUAL_BOOK
    );
  }, [checkUserPermission]);

  const isSelected = selected[type].includes(id);

  const [getAvailableMatches] = useLazyQuery(GET_AVAILABLE_MATCHES);

  const onCheckAvailableMatch = async () => {
    return getAvailableMatches({
      variables: {
        query: {
          matchIds: [id],
          organizationId:
            localStorage.getItem('projectId') || selectedProject?.id
        }
      }
    })
      .then(res => {
        if (res?.data?.getAvailableMatches.data) {
          return onSelectItem(id, type);
        }

        return toast.error(`${t('common:package_frozen')}`);
      })
      .catch(() => toast.error(`${t('common:something_went_wrong')}`));
  };

  const onlyListShown = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  const handleSelectMatch = async () => {
    if (
      (type === MatchTypesEnum.list && item?.isOutOfPackage) ||
      !hasBookPermission
    )
      return;

    if (!onlyListShown) {
      switch (type) {
        case MatchTypesEnum.list:
          await onCheckAvailableMatch();
          break;
        case MatchTypesEnum.booked:
          if (item?.status === MATCH_STATUS_IDS.live) return null;
          onSelectItem(id, type);
          break;
      }
    }
  };

  useEffect(() => {
    dispatch(setIsPlayDisabled({ type, value: true }));
  }, [dispatch, type]);

  if (!item) {
    return null;
  }

  return (
    <Wrapper item={item} type={type} hasBookPermission={hasBookPermission}>
      <StyledContainer className="flex-display">
        <StyledMatchItem
          className={`full-width ${
            type === MatchTypesEnum.history ||
            (type === MatchTypesEnum.booked &&
              item.status === MATCH_STATUS_IDS.live) ||
            onlyListShown
              ? 'default-cursor'
              : 'pointer-cursor'
          }`}
          active={
            item?.isOutOfPackage ||
            type === MatchTypesEnum.history ||
            onlyListShown
              ? false
              : isSelected
          }
        >
          <div
            onClick={handleSelectMatch}
            className="full-height flex-display flex-align-items-center flex-justify-center"
          >
            {type !== MatchTypesEnum.history && (
              <Private
                routeKey={
                  type === MatchTypesEnum.list
                    ? permissionConstants.DEMO_PREVIEW
                    : permissionConstants.VIEW_VIDEO
                }
                routeId={permissionConstants.BUYER_ROUTES_IDS[type]}
              >
                <MatchItemCol
                  onClick={e => e.preventDefault()}
                  width={1}
                  overflow="visible"
                  isMax
                  isFirst
                  isPlayButton
                >
                  <Tooltip
                    title={
                      type === MatchTypesEnum.list
                        ? 'Demo preview'
                        : 'Watch stream'
                    }
                    styles={{ position: 'right' }}
                    show={!item?.isOutOfPackage && hasBookPermission}
                  >
                    <VideoPreview
                      isSelected={isSelected}
                      matchId={id}
                      disabled={
                        playButton || item.status !== MATCH_STATUS_IDS.live
                      }
                    />
                  </Tooltip>
                </MatchItemCol>
              </Private>
            )}
            <MatchItemCol
              width={type === MatchTypesEnum.history ? 18 : 14}
              align="space-between"
              overflow="visible"
              style={{ gap: 6 }}
              isFirst={type === MatchTypesEnum.history}
              section={item.status === MATCH_STATUS_IDS.live ? 'live' : ''}
            >
              <Tooltip
                title={item.sport}
                styles={{ position: 'right' }}
                show={!item?.isOutOfPackage && hasBookPermission}
              >
                <SportIcon
                  name={item?.sport.toLowerCase()}
                  size={utils.rem(18)}
                  className="si-color ml--8"
                />
              </Tooltip>
              <p>{dayjs(item.startTime).format(timeFormat)}</p>
              {type === MatchTypesEnum.history && (
                <HistoryStatusLabel status={item.status} />
              )}
              <MatchTimeContainer status={item.status} type={type}>
                {item.status === MATCH_STATUS_IDS.live && (
                  <Tag type="danger">
                    <p>{t('events:live')}</p>
                  </Tag>
                )}
              </MatchTimeContainer>
            </MatchItemCol>
            <MatchItemCol width={18}>
              <p>{highlightedData(item.team1 as string, eventsFilters.name)}</p>
            </MatchItemCol>
            <MatchItemCol width={18}>
              <p>{highlightedData(item.team2 as string, eventsFilters.name)}</p>
            </MatchItemCol>
            <MatchItemCol width={8}>
              <p>{item.region}</p>
            </MatchItemCol>
            <MatchItemCol
              width={type === MatchTypesEnum.list ? 20 : 8}
              isLast={type === MatchTypesEnum.list}
            >
              <p>
                {
                  //!TODO removed conditions when back change list and history as booked
                  type === MatchTypesEnum.booked
                    ? (item as Booked).leagueName
                    : (item as Upcoming).league.name
                }
              </p>
            </MatchItemCol>
            {(type === MatchTypesEnum.booked ||
              type === MatchTypesEnum.history) && (
              <MatchItemCol
                width={
                  checkUserPermission(
                    type === MatchTypesEnum.history
                      ? permissionConstants.BUYER_ROUTES_IDS.history
                      : permissionConstants.BUYER_ROUTES_IDS.booked,
                    permissionConstants.BOOKING_DETAILS
                  )
                    ? 8
                    : 10
                }
              >
                <p>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item?.bookingType
                  }
                </p>
              </MatchItemCol>
            )}
          </div>
        </StyledMatchItem>
        {type !== MatchTypesEnum.list && (
          <Private
            routeKey={permissionConstants.BOOKING_DETAILS}
            routeId={
              type === MatchTypesEnum.history
                ? permissionConstants.BUYER_ROUTES_IDS.history
                : permissionConstants.BUYER_ROUTES_IDS.booked
            }
          >
            <Tooltip
              className="unbook-tooltip"
              title={
                type === MatchTypesEnum.booked
                  ? !item
                    ? t('events:unbooked')
                    : t('common:booking_details')
                  : ''
              }
              styles={{ position: 'left' }}
            >
              <Button
                className="ubnook-btn"
                tooltipPosition="top"
                cornerRadius="smooth"
                flexibility="content-size"
                icon="c-globus"
                disabled={item.status === MATCH_STATUSES_VALUES.canceled}
                onClick={() => {
                  //!TODO removed as upcoming type when back done!
                  toggleModal(ModalKeys.bookingCountries, true, {
                    id: id,
                    item: item as Upcoming
                  });
                }}
              />
            </Tooltip>
          </Private>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default memo(MatchItem);
