interface DateRangeType {
  startDate: string;
  endDate: string;
}
export interface ReportsFiltersState {
  filters: {
    dates: DateRangeType;
    countryNames: string[];
    sport: string[];
    region: string[];
    league: string[];
    platform: string[];
    overViewDate: { startDate: string; endDate: string };
    dailyViewsDate: { startDate: string; endDate: string };
    sportsDate: { startDate: string; endDate: string };
    platformsDate: { startDate: string; endDate: string };
    locationsDate: { startDate: string; endDate: string };
    eventsDate: { startDate: string; endDate: string };
  };
  pagination: Record<
    ReportsFiltersKeys,
    {
      page: number;
      total: number;
    }
  >;
}
type UpdateDate = {
  name:
    | 'date'
    | 'overViewDate'
    | 'dailyViewsDate'
    | 'sportsDate'
    | 'platformsDate'
    | 'locationsDate'
    | 'eventsDate';
  value: { startDate: string; endDate: string };
};

type DateType = {
  name: 'dates';
  value: DateRangeType;
};

type UpdateTraffic = {
  name: 'traffic';
  value: boolean;
};

type UpdateStringArrays = {
  name: 'dates' | 'countryNames' | 'sport' | 'region' | 'league' | 'platform';
  value: string[];
};
export type UpdateNames =
  | 'date'
  | 'overViewDate'
  | 'dailyViewsDate'
  | 'sportsDate'
  | 'platformsDate'
  | 'locationsDate'
  | 'eventsDate';

export type OnDatesChange = {
  value: { startDate: string; endDate: string };
};
export type OnChange =
  | UpdateStringArrays
  | UpdateTraffic
  | DateType
  | UpdateDate;

export type ReportsDataType = {
  key: string;
  value: unknown;
};

export enum ReportsFiltersKeys {
  buyers = 'buyers',
  contentGroup = 'contentGroup',
  useGroup = 'useGroup',
  events = 'events',
  overView = 'overView',
  sport = 'sport',
  uniqView = 'uniqView',
  osPlatform = 'osPlatform',
  location = 'location'
}

type OnChangeCommon = {
  key: ReportsFiltersKeys;
};
export type OnChangePage = OnChangeCommon & {
  page: number;
};
export type OnChangeTotal = OnChangeCommon & {
  total: number;
};
