import { FC } from 'react';
import { useStatusState } from 'hooks';

import { IStatusStateProps } from './types';
import NotFound from 'pages/NotFound';

const StatusState: FC<IStatusStateProps> = ({ states, component }) => {
  const isAllowed = useStatusState(states);

  if (isAllowed) return component;

  return <NotFound />;
};

export default StatusState;
