import {
  Button,
  Loading,
  Popup,
  Typography
} from '@shared_medialab/ui_components';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { MatchTypesEnum } from 'constants/match';
import { useEventsDispatchContext } from 'providers/Events';
import { UN_BOOK_MATCH } from 'gql/events/mutations';
import client from 'apolloClient';
import { GET_BOOKED_EVENTS } from 'gql/events/queries';
import { useProjectStateContext } from 'providers/Projects';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const UnBookModal = () => {
  const { unselectAll } = useEventsDispatchContext();
  const { toggleModal } = useUIDispatchContext();
  const { selectedProject } = useProjectStateContext();
  const { unBookMatches } = useModalStateContext();
  const { t } = useTranslation(['messages', 'events', 'common']);

  const [unBook, { loading }] = useMutation(UN_BOOK_MATCH, {
    onCompleted() {
      toast.success(`${t('unbooked')}`);
      client.refetchQueries({ include: [GET_BOOKED_EVENTS] });
    },
    onError(error) {
      toast.error(error.message);
    }
  });

  const onUnBookMatches = () => {
    unBook({
      variables: {
        input: {
          matchIds: unBookMatches?.params?.booked,
          organizationId: selectedProject?.id
        }
      }
    }).then(() => {
      unselectAll(MatchTypesEnum.booked);
      toggleModal(ModalKeys.unBook, false);
    });
  };

  return (
    <Popup
      isVisible={true}
      onClose={() => toggleModal(ModalKeys.unBook, false)}
      width={27}
      type="CRUD"
      alignItems="center"
    >
      {loading ? (
        <Loading />
      ) : (
        <div className="p--24">
          <Text
            level={11}
            className="flex-display bold flex-direction-column p--12 text-center mb--12"
          >
            {t('events:unbook_events')}
          </Text>
          <Text className="flex-display flex-direction-column p--12 text-center mb--12">
            {t('events:unbook_question')}
          </Text>
          <div className="flex-display ph--64">
            <Button
              color="contrastNew"
              cornerRadius="smooth"
              className=" mr--32"
              onClick={() => toggleModal(ModalKeys.unBook, false)}
              iconSize={18}
            >
              {t('common:cancel')}
            </Button>
            <Button
              cornerRadius="smooth"
              iconSize={18}
              onClick={() => onUnBookMatches()}
            >
              {t('common:ok')}
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default UnBookModal;
