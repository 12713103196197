import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { GET_USER_PROJECTS } from 'gql/organizations/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UserRole } from 'gql/auth/types/UserRole';
import { GET_USER_DATA, GET_USER_ROLE_BY_ORGANIZATION } from 'gql/auth/queries';
import { useProjectStateContext } from 'providers/Projects';
import { PERMISSION_ROUTE_PATHS } from 'constants/permissions';
import { UserMe } from 'gql/auth/types/UserMe';
import { Loading } from '@shared_medialab/ui_components';
import config from 'config';
import { UserProjects } from 'gql/organizations/types/UserProjects';
import { DELETE_CART_BY_ORGANIZATION } from 'gql/cart/mutations';
import client from 'apolloClient';
import { ProjectStatuses } from 'providers/Projects/types';
import { USER_PROJECT_FRAGMENT } from 'gql/organizations/fragments';
import { toast } from 'react-toastify';

import Header from '../Header';
import { StyledLayout } from './styled';
import AsideLeft from '../AsideLeft';
import AsideRight from '../AsideRight';
import PermissionDenied from '../PermissionDenied';
import { UserEnum } from 'pages/UserManagement/constants';
import StatusDenied from '../StatusDenied';
import { GENERAL_STATUS } from './constants';
import NoPermissionToAccess from '../NoPermissionToAccess';
import { parseJson } from 'utils/object';

const PrivateLayout: FC = () => {
  const [isPermitted, setIsPermitted] = useState(false);

  // states
  const queryToken =
    new URLSearchParams(window.location.search).get('token') || '';

  const storageToken = localStorage.getItem('token') || '';

  useEffect(() => {
    if (!(queryToken || storageToken) && config.sso_url) {
      window.location.href = config.sso_url;
    }
  }, [queryToken, storageToken]);
  // context
  const { selectedProject } = useProjectStateContext();

  // graphql
  const { data } = useQuery<UserProjects>(GET_USER_PROJECTS, {
    fetchPolicy: 'cache-only'
  });

  const [getUserRole, { data: roleData }] = useLazyQuery<UserRole>(
    GET_USER_ROLE_BY_ORGANIZATION
  );

  const [getUserData, { data: userData }] = useLazyQuery<UserMe>(GET_USER_DATA);

  const [deleteCartByOrganizationId] = useMutation(
    DELETE_CART_BY_ORGANIZATION,
    {
      onCompleted() {
        localStorage.clear();
        client.clearStore();

        if (config.sso_url) {
          window.location.href = config.sso_url;
        }
      }
    }
  );

  const { status: GeneralStatus, role: userRole } = userData?.me?.data || {};
  const organizationId = useMemo(
    () => localStorage.getItem('projectId') || selectedProject?.id,
    [selectedProject?.id]
  );

  const id = localStorage.getItem('projectId');

  useEffect(() => {
    if (GeneralStatus === GENERAL_STATUS.INACTIVE || id === '-1') {
      deleteCartByOrganizationId({
        fetchPolicy: 'network-only',
        variables: {
          organizationId
        }
      });
    }
  }, [GeneralStatus, deleteCartByOrganizationId, id, organizationId]);
  const buyer = useMemo(
    () => data?.userProjects?.data?.buyer || [],
    [data?.userProjects?.data?.buyer]
  );

  const admin = useMemo(
    () => data?.userProjects?.data?.admin || [],
    [data?.userProjects?.data?.admin]
  );

  const provider = useMemo(
    () => data?.userProjects?.data?.provider || [],
    [data?.userProjects?.data?.provider]
  );

  const onClickArrow = useCallback(
    async (id?: string, type?: string) => {
      try {
        const response = await fetch(
          `${config.gateway_url}/users/url?role=${type}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );

        if (!response.ok) {
          const error = await response.text();
          throw new Error(
            parseJson(error)?.message ||
              'Something went wrong, lease contact support'
          );
        }

        const result = await response.json();

        if (!result?.data?.url) {
          throw new Error('Something went wrong, lease contact support');
        }

        const token = localStorage.getItem('token');

        const projectUrl = id ? `&project=${id}` : ``;

        deleteCartByOrganizationId({
          fetchPolicy: 'network-only',
          variables: {
            organizationId
          },
          onCompleted() {
            window.location.href = `${result.data.url}?token=${token}${projectUrl}`;
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    [deleteCartByOrganizationId, organizationId]
  );

  const projectStatus = buyer?.filter(el => el.id === id)[0]?.projectStatus;
  const randomBuyerForRedirect =
    buyer.filter(el => el.id !== organizationId)[0]?.id ||
    provider.filter(el => el.id !== organizationId)[0]?.id ||
    admin?.[0]?.id ||
    '-1';

  useEffect(() => {
    if (projectStatus === ProjectStatuses.archived && buyer?.length <= 1) {
      deleteCartByOrganizationId({
        fetchPolicy: 'network-only',
        variables: {
          organizationId
        }
      });
    } else if (projectStatus === ProjectStatuses.archived) {
      const data = client.readFragment({
        id: `UserProject:${randomBuyerForRedirect}`,
        fragment: USER_PROJECT_FRAGMENT,
        fragmentName: 'UserProjectFragment'
      });

      localStorage.removeItem('projectId');
      localStorage.setItem('selectedProject', JSON.stringify(data));
      localStorage.setItem('projectId', randomBuyerForRedirect);
      onClickArrow(randomBuyerForRedirect, buyer.length ? 'buyer' : 'admin');
    }
  }, [
    GeneralStatus,
    buyer.length,
    deleteCartByOrganizationId,
    id,
    organizationId,
    buyer,
    data,
    randomBuyerForRedirect,
    projectStatus,
    onClickArrow
  ]);

  useEffect(() => {
    getUserData({
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, organizationId, selectedProject?.id]);

  useEffect(() => {
    if (userData?.me?.data && organizationId) {
      getUserRole({
        variables: {
          query: {
            ...(organizationId ? { organizationId } : {}),
            groupName: UserEnum.buyer
          }
        }
      });
    }
  }, [getUserRole, organizationId, selectedProject, userData?.me?.data]);

  const permissions: Record<string, number> = useMemo(() => {
    return (
      roleData?.userRole?.data?.permissions?.reduce(
        (acc, val) => ({
          ...acc,
          [val.name]: Number(val.value)
        }),
        {}
      ) || {}
    );
  }, [roleData?.userRole?.data?.permissions]);

  const redirectPath = useMemo(
    () =>
      Object.keys(PERMISSION_ROUTE_PATHS).find(key => {
        const value = PERMISSION_ROUTE_PATHS[key];
        const permission = Object.keys(permissions).find(
          item => item === value.toString()
        );

        return !!permission;
      }),
    [permissions]
  );

  useEffect(() => {
    setIsPermitted(!!(redirectPath && data?.userProjects?.data?.buyer?.length));
  }, [data?.userProjects?.data?.buyer?.length, permissions, redirectPath]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulating a delay to mimic an asynchronous operation
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000); // Simulating a 0.5-second delay

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <StyledLayout>
      <Header />
      <div className="flex-display pt--12">
        {isPermitted && <AsideLeft />}
        <main>
          <div className="full-height full-width flex-display flex-direction-column">
            {isPermitted ? (
              <Outlet />
            ) : !userRole?.permissions?.length ? (
              <NoPermissionToAccess />
            ) : GeneralStatus === GENERAL_STATUS.INACTIVE ? (
              <StatusDenied />
            ) : (
              <PermissionDenied />
            )}
          </div>
        </main>
        <AsideRight />
      </div>
    </StyledLayout>
  );
};

export default PrivateLayout;
