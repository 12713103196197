import { useLazyQuery } from '@apollo/client';
import dayjs, { ConfigType } from 'dayjs';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { useProjectStateContext } from 'providers/Projects';
import { useCallback, useEffect } from 'react';

import { UserEnum } from 'pages/UserManagement/constants';

const useDayjs = () => {
  const { selectedProject } = useProjectStateContext();

  // graphql
  const [getUserData, { data }] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const formatDate = useCallback(
    (date?: ConfigType) => {
      const timezone = data?.me?.data.settings.timezone;

      if (!timezone) {
        return dayjs(date);
      }

      return dayjs(date).tz(timezone);
    },
    [data?.me?.data.settings.timezone]
  );

  return {
    dayjs: formatDate,
    timezone: data?.me?.data.settings.timezone,
    timeFormat: data?.me?.data.settings.timeFormat === 12 ? 'h:mm a' : 'HH:mm',
    eventFormat: data?.me?.data.settings.timeFormat === 12 ? 'h:00 a' : 'HH:00'
  };
};

export default useDayjs;
