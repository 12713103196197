import { gql } from '@apollo/client';

export const USER_ROLE_FRAGMENT = gql`
  fragment UserRoleFragment on Role {
    id
    name
    parent {
      id
    }
    status
  }
`;

export const BUYER_USER_FRAGMENT = gql`
  fragment BuyerUserFragment on BuyerUser {
    email
    firstName
    id
    lastName
    phone
    status
    updatedAt
    role {
      ...UserRoleFragment
    }
  }
  ${USER_ROLE_FRAGMENT}
`;
