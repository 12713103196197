import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

export const VideoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  ${({ theme }) => css`
    padding: ${theme.spaces.lg};
    width: 370px;
    max-width: 100%;
    gap: ${theme.spaces.lg};
    background-color: ${theme.colors.white};
  `}
`;

export const VideoPreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  p {
    font-size: ${({ theme }) => theme.sizes.sm};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const VideoPreviewContainer = styled.div`
  background-color: #000000;
  border-radius: 8px;
`;
