import { useQuery } from '@apollo/client';
import { GET_SPORTS_BY_ATTACHED_LEAGUE } from 'gql/sports/queries';
import { useState, useEffect } from 'react';
import { SportsByAttachedLeagues } from 'gql/sports/types/SportsByAttachedLeagues';

import useInfiniteScroll from './useInfiniteScroll';
import usePreviousNonNullish from './usePreviousNonNullish';

const SPORT_PAGE_SIZE = 20;

const useSports = () => {
  // states
  const [sportSearch, setSportSearch] = useState('');
  // graphql
  const {
    data: sportsData,
    loading: sportsLoading,
    fetchMore: fetchMoreSports
  } = useQuery<SportsByAttachedLeagues>(GET_SPORTS_BY_ATTACHED_LEAGUE, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: {
      query: {
        name: sportSearch
      }
    },
    context: {
      debounceKey: 'sports',
      debounceTimeout: 700
    }
  });

  const data = usePreviousNonNullish(sportsData);

  // scroll
  const { onScroll: onSportsScroll, stopPaging: stopSportsPaging } =
    useInfiniteScroll({
      callback: page => {
        return fetchMoreSports({
          variables: {
            query: {
              pagination: {
                page,
                limit: SPORT_PAGE_SIZE
              }
            }
          }
        });
      }
    });

  const sports = data?.sportsByAttachedLeagues?.data || [];

  const sportsTotal = data?.sportsByAttachedLeagues?.data.length || 0;

  useEffect(() => {
    if (sports.length && sportsTotal && sports.length >= sportsTotal) {
      stopSportsPaging();
    }
  }, [sportsLoading, sports.length, sportsTotal, stopSportsPaging]);

  return {
    sports,
    sportSearch,
    onSportsScroll,
    onSportSearch: setSportSearch
  };
};

export default useSports;
