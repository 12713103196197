import { createRoot } from 'react-dom/client';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { defaultTheme } from '@shared_medialab/ui_components';
import { ApolloProvider } from '@apollo/client';
import client from 'apolloClient';
import { Provider } from 'react-redux';
import { store } from 'store';

import './index.css';
import './locales';

import App from './App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <StyledThemeProvider theme={defaultTheme}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </StyledThemeProvider>
  );
}
