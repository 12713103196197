import { gql } from '@apollo/client';

import { CART_FRAGMENT } from './fragments';

export const GET_CART = gql`
  query Cart($organizationId: String!) {
    cart(organizationId: $organizationId) {
      data {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`;
