import { gql } from '@apollo/client';

export const GET_ALL_COUNTRIES = gql`
  query AllCountries($query: AllCountryInput) {
    allCountries(query: $query) {
      data {
        id
        name
      }
    }
  }
`;
