export const PENDING = '1';
export const ACTIVE = '2';
export const INACTIVE = '3';

export const USER_STATUSES = {
  '1': {
    color: '#FAAD14',
    title: 'Pending',
    value: '1'
  },
  '2': {
    color: '#52C41A',
    title: 'Active',
    value: '2'
  },
  '3': {
    color: '#FF7875',
    title: 'Disabled',
    value: '3'
  }
} as const;
