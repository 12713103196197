import { FC } from 'react';
import { Icon, Typography } from '@shared_medialab/ui_components';
import { IconMoonTypes } from '@shared_medialab/ui_components/build/base/Icon/MoonIcon/types';
import './index.css';

import { IMenuItem } from './types';

const { Text } = Typography;

const MenuItem: FC<IMenuItem> = ({ onClick, iconType, text, className }) => (
  <li
    className={`flex-display flex-align-items-center p--12 relative-position pointer-cursor pv--16 pl--8 ${className} menu-item`}
    onClick={onClick}
  >
    <Icon
      size={15}
      type={iconType as IconMoonTypes}
      color="var(--text-color)"
      className="mr--8"
    />
    <Text color="var(--text-color)" level={13}>
      {text}
    </Text>
  </li>
);

export default MenuItem;
