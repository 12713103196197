import styled, { css } from 'styled-components';

import { ISportItem } from './types';

const activeStyles = css`
  background-color: var(--primary-1);
  border: 1px solid var(--primary-3);
  color: #ffffff;
  .si-color {
    color: #ffffff;
  }
`;

export const StyledSports = styled.ul<{ isDisabled: boolean }>`
  display: flex;
  ${({ isDisabled }) => css`
    opacity: ${isDisabled && 0.4};
    pointer-events: ${isDisabled && 'none'};
  `}
  flex-direction: column;
  gap: 6px;
`;

export const SportItem = styled.li<ISportItem>`
  padding: 8px 10px;
  background-color: var(--primary-bg-3);
  border: 1px solid var(--primary-bg-5);
  border-radius: 6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    ${activeStyles}
  }
  ${({ active }: ISportItem) => active && activeStyles};
`;
