import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Icon, Select } from '@shared_medialab/ui_components';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_COUNTRIES } from 'gql/countries/queries';
import { AllCountries } from 'gql/countries/types/AllCountries';
import { UPDATE_USER_SETTINGS } from 'gql/users/mutations';
import { GET_USER_DATA } from 'gql/auth/queries';
import { UserMe } from 'gql/auth/types/UserMe';
import { toast } from 'react-toastify';
import client from 'apolloClient';
import { useProjectStateContext } from 'providers/Projects';

import {
  PopupWrapper,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledPopup
} from './styled';
import { FormValues, ISettingsPopupProps } from './types';
import { getTimeZoneNames } from 'utils/dates';
import { UserEnum } from 'pages/UserManagement/constants';

const TIME_FORMATS = [
  { label: '12', value: '12' },
  { label: '24', value: '24' }
];

const SettingsPopup: FC<ISettingsPopupProps> = ({
  isVisible,
  onClose,
  showCloseButton = true
}) => {
  // context
  const { selectedProject } = useProjectStateContext();
  // states
  const [tzSearch, setTzSearch] = useState('');
  const [countriesSearch, setCountriesSearch] = useState('');
  // form
  const { control, reset, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      timezone: '',
      timeFormat: '24',
      countries: []
    }
  });

  // graphql
  const variables = useMemo(
    () => ({
      name: countriesSearch
    }),
    [countriesSearch]
  );

  const { data: countriesData } = useQuery<AllCountries>(GET_ALL_COUNTRIES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !isVisible,
    variables: {
      query: variables
    },
    context: {
      debounceKey: 'countries',
      debounceTimeout: 400
    }
  });

  const onClosePopup = useCallback(() => {
    setTzSearch('');
    onClose();
  }, [onClose]);

  const [getUserData] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    if (!isVisible) {
      const organizationId =
        localStorage.getItem('projectId') || selectedProject?.id;

      getUserData({
        fetchPolicy: 'cache-and-network',
        variables: {
          ...(organizationId ? { organizationId } : {}),
          groupName: UserEnum.buyer
        },
        onCompleted(data) {
          if (data.me?.data.settings) {
            const settings = data.me.data.settings;

            reset({ ...settings });
          }
        }
      });
    }
  }, [getUserData, isVisible, reset, selectedProject?.id]);

  const [update, { loading }] = useMutation(UPDATE_USER_SETTINGS, {
    onError(error) {
      toast.error(error.message);
    },
    onCompleted() {
      toast.success('Successfully saved');
      onClose();
      client.refetchQueries({
        include: [GET_USER_DATA]
      });
    }
  });

  const countries = useMemo(
    () => countriesData?.allCountries?.data || [],
    [countriesData?.allCountries?.data]
  );

  const tzData = getTimeZoneNames(tzSearch);

  const onSubmit = (value: FormValues) => {
    update({
      variables: {
        input: {
          settings: JSON.stringify(value)
        }
      }
    });
  };

  if (!isVisible) return null;

  return (
    <PopupWrapper>
      <StyledPopup>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledHeader>
            <h4>Settings</h4>
            {showCloseButton && (
              <button onClick={onClosePopup}>
                <Icon type="close" />
              </button>
            )}
          </StyledHeader>
          <StyledContent>
            <Controller
              name="timezone"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Time zone"
                    placeholder="Select"
                    options={tzData.map(item => ({
                      label: item,
                      value: item
                    }))}
                    onChange={onChange}
                    value={value}
                    error={!watch('timezone') ? ' ' : undefined}
                    searchValue={tzSearch}
                    onSearch={e => setTzSearch(e.target.value)}
                    cornerRadius="smooth"
                    tooltip="All times, such as events start/end time, date filters, etc., <br /> will be displayed in the selected time zone ."
                  />
                );
              }}
            />
            <Controller
              name="timeFormat"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Time format"
                    options={TIME_FORMATS}
                    onChange={v => onChange(v as '12' | '24')}
                    cornerRadius="smooth"
                    value={`${value}`}
                    hideSearch
                  />
                );
              }}
            />
            <Controller
              name="countries"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Default countries"
                    options={countries.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    onChange={onChange}
                    placeholder="Select country"
                    value={value}
                    searchValue={countriesSearch}
                    onSearch={e => setCountriesSearch(e.target.value)}
                    mode="multiple"
                    cornerRadius="smooth"
                    tooltip="Default countries are those that are pre-selected when  <br />  booking events manually and creating autobooking rules."
                  />
                );
              }}
            />
          </StyledContent>
          <StyledFooter>
            <Button
              disabled={!watch('timezone')}
              color="confirm"
              cornerRadius="smooth"
              flexibility="content-size"
              type="submit"
              loading={loading}
            >
              Confirm
            </Button>
          </StyledFooter>
        </form>
      </StyledPopup>
    </PopupWrapper>
  );
};

export default SettingsPopup;
