import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const PopupContent = styled.div`
  ${({ theme }) => css`
    text-align: center;
    padding: ${theme.sizes.xxl} ${theme.sizes.xs} 0 ${theme.spaces.xxl};
    color: ${rgba(theme.colors.gray[8], 0.5)};
  `}
`;
export const ContainerPagination = styled.div`
  width: 100%;
  min-height: 40px;
  outline: none;
  ${({ theme }) => css`
    background-color: ${theme.colors.light[0]};
    border-radius: ${theme.spaces.lg};
    padding: ${theme.spaces.xs};
  `}
`;
export const NoDataWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
