import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($input: UsersInput!) {
    createUser(input: $input) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateBuyerUser($id: ID!, $input: UpdateBuyerUsersInput!) {
    updateBuyerUser(id: $id, input: $input) {
      message
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateSettings($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation ReSendInvitationToUser($input: ReSendInvitationToUsersInput!) {
    reSendInvitationToUser(input: $input) {
      data {
        status
      }
    }
  }
`;
