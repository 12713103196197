/* eslint-disable @typescript-eslint/no-explicit-any */
import { Socket } from 'socket.io-client';
import config from 'config';

import handlers from './handler';

let socket: Socket;

export async function connectSocket(io: any, token: string) {
  socket = io(config.socket_url, {
    query: {
      token
    }
  });

  handlers.forEach(handler => {
    socket.on(handler.channel, handler.action);
  });

  return new Promise((resolve, reject) => {
    socket.on('connection', (response: { isConnected: boolean }) => {
      response.isConnected ? resolve(response.isConnected) : reject(response);
    });
  });
}

export function socketEmit(channel: string, message: any) {
  socket.emit(channel, message);
}
