type TrafficType = {
  id: number;
  name: string;
};
export const EVENTS_PAGE_SIZE = 15;
export const PAGE_SIZE = 5;
// TODO: from gql
export type TrafficInfoDataType = {
  id: number;
  name: string;
  date: string;
  initiator: TrafficType[];
  action: TrafficType[];
  type: string;
  message: string;
};
