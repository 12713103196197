import { gql } from '@apollo/client';

import { CART_FRAGMENT } from './fragments';

export const CREATE_CART = gql`
  mutation CreateCart($organizationId: String!, $input: CartInput!) {
    createCart(organizationId: $organizationId, input: $input) {
      message
    }
  }
`;

export const UPDATE_CART = gql`
  mutation UpdateCart(
    $id: ID!
    $organizationId: String!
    $input: updateCartInput!
  ) {
    updateCart(id: $id, organizationId: $organizationId, input: $input) {
      message
      data {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`;

export const DELETE_CART = gql`
  mutation DeleteCart($organizationId: String!, $input: CartInput!) {
    deleteCart(organizationId: $organizationId, input: $input) {
      message
    }
  }
`;

export const BOOK_MATCHES = gql`
  mutation BookMatches($input: BookMatchesInput!) {
    bookMatches(input: $input) {
      message
      data {
        matchIds
      }
    }
  }
`;

export const DELETE_CART_BY_ORGANIZATION = gql`
  mutation DeleteCartByOrganizationId($organizationId: String!) {
    deleteCartByOrganizationId(organizationId: $organizationId) {
      message
    }
  }
`;
