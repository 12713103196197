const config = {
  production: {
    graphql_url: process.env.REACT_APP_GRAPHQL_URL,
    gateway_url: process.env.REACT_APP_GATEWAY_URL,
    buyer_api_url: process.env.REACT_APP_BUYER_API_URL,
    report_api_url: process.env.REACT_APP_REPORT_API_URL,
    sso_url: process.env.REACT_APP_AUTH_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    env: process.env.NODE_ENV
  },
  development: {
    graphql_url: process.env.REACT_APP_GRAPHQL_URL,
    gateway_url: process.env.REACT_APP_GATEWAY_URL,
    buyer_api_url: process.env.REACT_APP_BUYER_API_URL,
    report_api_url: process.env.REACT_APP_REPORT_API_URL,
    sso_url: process.env.REACT_APP_AUTH_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    env: process.env.NODE_ENV
  },
  test: {
    graphql_url: process.env.REACT_APP_GRAPHQL_URL,
    gateway_url: process.env.REACT_APP_GATEWAY_URL,
    buyer_api_url: process.env.REACT_APP_BUYER_API_URL,
    report_api_url: process.env.REACT_APP_REPORT_API_URL,
    sso_url: process.env.REACT_APP_AUTH_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    env: process.env.NODE_ENV
  }
};

export default config['production'];
